import React, { useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import router from "../Router";

import {
  fetchStates,
  fetchListing,
  fetchBlogs,
  fetchCategory,
} from "../redux/Slice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

const Header = React.lazy(() => import("../common-component/Header"));
const Footer = React.lazy(() => import("../common-component/Footer"));
const NewHeader = React.lazy(() => import("../common-component/NewHeader"));
const Loader = React.lazy(() => import("../loader/Loader"));

const checkIndexedDB = async (): Promise<boolean> => {
  const dbNames = ["BlogDB", "categoryDB", "ProductDB", "StateDB"];
  for (const dbName of dbNames) {
    const dbRequest = indexedDB.open(dbName);
    return new Promise<boolean>((resolve) => {
      dbRequest.onsuccess = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        const isEmpty = db.objectStoreNames.length === 0;
        db.close();
        resolve(isEmpty);
      };
      dbRequest.onerror = () => resolve(true);
    });
  }
  return Promise.resolve(true);
};

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const currentPath = window.location.pathname;
  const dispatch = useAppDispatch();

  const {
    hasFetchedBlogs,
    hasFetchedCategory,
    hasFetchedListing,
    hasFetchedStates,
  } = useAppSelector((state) => state.detail);

  useEffect(() => {
    const loadApp = async () => {
      const start = Date.now();

      if (currentPath === "/") {
        if (!hasFetchedCategory) dispatch(fetchCategory());
        if (!hasFetchedStates) dispatch(fetchStates());
        if (!hasFetchedListing) dispatch(fetchListing());
        if (!hasFetchedBlogs) dispatch(fetchBlogs());
      } else {
        const isDBEmpty = await checkIndexedDB();
        if (isDBEmpty) {
          if (!hasFetchedCategory) dispatch(fetchCategory());
          if (!hasFetchedStates) dispatch(fetchStates());
          if (!hasFetchedListing) dispatch(fetchListing());
          if (!hasFetchedBlogs) dispatch(fetchBlogs());
        }
      }

      const elapsed = Date.now() - start;
      const remaining = 2000 - elapsed;
      setTimeout(() => setLoading(false), remaining > 0 ? remaining : 0);
    };

    loadApp();
  }, [
    currentPath,
    dispatch,
    hasFetchedBlogs,
    hasFetchedCategory,
    hasFetchedListing,
    hasFetchedStates,
  ]);

  const isAuthPage = ["/login", "/register"].includes(currentPath);
  const SelectedHeader = currentPath === "/" ? Header : NewHeader;

  return (
    <React.Suspense fallback={<Loader />}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {!isAuthPage && <SelectedHeader />}
          <RouterProvider router={router} />
          {!isAuthPage && <Footer />}
        </>
      )}
    </React.Suspense>
  );
};

export default App;
