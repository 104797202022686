import React from "react";
import Style from "../style/productbanner.module.scss";

const ProductBanner = () => {
  return (
    <div className={Style.banner}>
      <div className={Style.leftcontent}>
        <h2>Get Started Bipho Advantage</h2>
        <p>Find the right professional for your next project!</p>
        <div className={Style.bannerImage}>
          <span>
            <img
              src="https://i3.ypcdn.com/ypu/images/svgs/you-icons/you-icon-1.svg?496f2a9"
              alt="Icon"
              loading="lazy"
            />
          </span>
          <span>
            <img
              src="https://i4.ypcdn.com/ypu/images/svgs/you-icons/you-icon-2.svg?496f2a9"
              alt="Icon"
              loading="lazy"
            />
          </span>
          <span>
            <img
              src="https://i2.ypcdn.com/ypu/images/svgs/you-icons/you-icon-3.svg?496f2a9"
              alt="Icon"
              loading="lazy"
            />
          </span>
          <span>
            <img
              src="https://i3.ypcdn.com/ypu/images/svgs/you-icons/you-icon-4.svg?496f2a9"
              alt="Icon"
              loading="lazy"
            />
          </span>
        </div>
      </div>
      <div className={Style.rightcontent}>
        <button>Get Started</button>
      </div>
    </div>
  );
};

export default ProductBanner;
