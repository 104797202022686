import React, { useState } from "react";
import Style from "../../style/realstatefilterRight.module.scss";
import GetConnected from "./GetConnected";
import RelatorContactForm from "./Form/RelatorContactForm";

const RealStateFilterRight = ({ itemList }: any) => {
  const [show, setShow] = useState(false);
  const handleClick = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <div className={Style.container}>
      <>{show && <GetConnected handleClose={handleClose} />}</>
      <div className={Style.box}>
        <h2>Share your requirements</h2>
        <p>
          Please provide your preferred location, property type and budget. We
          will connect property owners/realtors instantly
        </p>
        <button onClick={handleClick}>Get Connected</button>
      </div>
      <div className={Style.banner}>
        <img
          src="https://cdn.vectorstock.com/i/500p/01/15/real-estate-sale-background-vector-4400115.jpg"
          alt=""
        />
      </div>
      <div className={Style.detail}>
        <h2>{itemList.name}</h2>
        <div className={Style.fullData}>
          <ul>
            <li>
              Properties <span>{itemList.properties}</span>
            </li>
            <li>
              Rate <span>{itemList.price}</span>
            </li>
            <li>
              Establishment Year <span>{itemList.built}</span>
            </li>
            <li>
              Sale Type <span>{itemList.price}</span>
            </li>
          </ul>
        </div>
      </div>
      <RelatorContactForm itemList={itemList} layout="rightside" />
      <div className={Style.banner}>
        <a href="/listing/realstate">
          <img
            src="https://images.rawpixel.com/image_png_800/cHJpdmF0ZS90ZW1wbGF0ZXMvZmlsZXMvY3JlYXRlX3Rvb2wvMjAyMy0wNy8wMWg1eTRjd2h0c3licDBtMnJtZmEzanRnOS1sa2RtcnVnNi5wbmc.png"
            alt=""
          />
        </a>
      </div>
      <div className={Style.propertyType}>
        <h2>Property types in {itemList.city} Area</h2>
        <ul>
          <li>
            <a href="#/">{itemList.type}</a>
          </li>
        </ul>
      </div>
      <div className={Style.banner}>
        <a href="/listing/realstate">
          <img
            src="https://www.bankrate.com/2023/07/20160747/Homes-Real-Estate-in-Texas.jpg"
            alt=""
          />
        </a>
      </div>
    </div>
  );
};

export default RealStateFilterRight;
