import React from "react";
import Style from "../../style/recent.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";

const Recent = () => {
  const data = [
    {
      id: "1",
      address: "20938 N Buffalo Run, Kildeer, IL 60047",
      price: "$875,000",
      Room: "5",
      bath: "8",
      area: "1.06 acre",
      category: "Single Family Homes",
      img: "https://photos.zillowstatic.com/fp/90d69f9027911d61b1794e59e19e9840-p_c.jpg",
    },
    {
      id: "1",
      address: "20938 N Buffalo Run, Kildeer, IL 60047",
      price: "$875,000",
      Room: "5",
      bath: "8",
      area: "1.06 acre",
      category: "Single Family Homes",
      img: "https://photos.zillowstatic.com/fp/f917136c4c20c846b4b388195610d2dc-p_c.jpg",
    },
    {
      id: "1",
      address: "20938 N Buffalo Run, Kildeer, IL 60047",
      price: "$875,000",
      Room: "5",
      bath: "8",
      area: "1.06 acre",
      category: "Lands/Lots",
      img: "https://photos.zillowstatic.com/fp/90d69f9027911d61b1794e59e19e9840-p_c.jpg",
    },
    {
      id: "1",
      address: "20938 N Buffalo Run, Kildeer, IL 60047",
      price: "$875,000",
      Room: "5",
      bath: "8",
      area: "1.06 acre",
      category: "Lands/Lots",
      img: "https://photos.zillowstatic.com/fp/f917136c4c20c846b4b388195610d2dc-p_c.jpg",
    },
    {
      id: "1",
      address: "20938 N Buffalo Run, Kildeer, IL 60047",
      price: "$875,000",
      Room: "5",
      bath: "8",
      area: "1.06 acre",
      category: "Single Family Homes",
      img: "https://photos.zillowstatic.com/fp/90d69f9027911d61b1794e59e19e9840-p_c.jpg",
    },
  ];
  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.title}>
          <h2>Recently Added Properties</h2>
        </div>
        <div className={Style.mainbox}>
          <Swiper
            modules={[Navigation]}
            spaceBetween={30}
            slidesPerView={1}
            loop={true}
            navigation
            breakpoints={{
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 4 },
            }}
          >
            {data &&
              data.map((item) => {
                return (
                  <SwiperSlide key={item.id}>
                    <div className={Style.content}>
                      <span>
                        <img src={item.img} alt="" />
                        <h2>{item.category}</h2>
                      </span>
                      <div className={Style.detail}>
                        <a href="#/">{item.address}</a>
                        <h3>{item.price}</h3>
                        <ul>
                          <li>
                            <i className="fa-solid fa-bed"></i>
                            {item.Room} rooms
                          </li>
                          <li>
                            <i className="fa-solid fa-bath"></i>
                            {item.bath} bath
                          </li>
                          <li>
                            <i className="fa-solid fa-house-chimney"></i>
                            {item.area}
                          </li>
                        </ul>
                        <div className={Style.button}>
                          <button>View More</button>
                          <button>Contact Owner</button>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Recent;
