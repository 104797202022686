import React, { useEffect, useState } from "react";
import Style from "../style/latestListing.module.scss";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { fetchListing } from "../redux/Slice";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { getProductsFromDB, saveProductsToDB } from "../indexDb/productDb";
import staticData from "../json/product.json";
import defaultData from "../utlits/default";

const LatestListing = React.memo(() => {
  const dispatch = useAppDispatch();
  const [latestListings, setLatestListings] = useState<any[]>(staticData);
  const [loading, setLoading] = useState(true);
  const data = useAppSelector((state) => state.detail);

  const getLatestItems = (items: any[]) => {
    const seenIds = new Set();
    const filteredItems = items.filter((item) => {
      const keysToCheck = [
        "title",
        "address",
        "phone",
        "category_name",
        "zip",
        "employee_count",
      ];
      const isValid = keysToCheck.some(
        (key) =>
          item[key] !== null && item[key] !== "" && item[key] !== undefined
      );

      return isValid;
    });
    return filteredItems
      .slice(-5)
      .reverse()
      .filter((item) => {
        if (!seenIds.has(item.id)) {
          seenIds.add(item.id);
          return true;
        }
        return false;
      });
  };

  useEffect(() => {
    const preloadData = async () => {
      try {
        const cachedData = await getProductsFromDB();
        if (cachedData.length === 0) {
          setLatestListings(staticData);
          dispatch(fetchListing());
        } else {
          setLatestListings(getLatestItems(cachedData));
          setLoading(false);
        }
      } catch (error) {
        setLatestListings(staticData);
      }
    };

    preloadData();
  }, [dispatch]);

  useEffect(() => {
    if (
      data.listingData?.listings &&
      Array.isArray(data.listingData.listings) &&
      data.listingData.listings.length > 0
    ) {
      const uniqueItems = getLatestItems(data.listingData.listings);
      setLatestListings(uniqueItems);
      saveProductsToDB(data.listingData.listings);
      setLoading(false);
    } else if (
      data.listingData?.listings === null ||
      !data.listingData?.listings?.length
    ) {
      setLatestListings([defaultData]);
      setLoading(false);
    }
  }, [data.listingData]);

  return (
    <div className={Style.container}>
      <div className={Style.title}>
        <h2>The Featured Listings</h2>
      </div>
      <div className={Style.wrapper}>
        {loading && latestListings.length === 0 ? (
          <p className={Style.loading}>Loading listings...</p>
        ) : latestListings.length > 0 ? (
          <Swiper
            modules={[Navigation, Autoplay]}
            spaceBetween={20}
            slidesPerView={3}
            navigation
            loop
            autoplay={{ delay: 4000, disableOnInteraction: false }}
            breakpoints={{
              360: { slidesPerView: 1 },
              769: { slidesPerView: 2 },
              993: { slidesPerView: 3 },
            }}
          >
            {latestListings.map((item: any) => (
              <SwiperSlide key={item.id || item.slug}>
                <div className={Style.Image}>
                  <a href={`/listing/page/${item.slug || "#"}`}>
                    <span>
                      <img
                        loading="lazy"
                        src={
                          item.image ||
                          "https://media.istockphoto.com/id/950986656/photo/business-finance-accounting-contract-advisor-investment-consulting-marketing-plan-for-the.jpg?s=612x612&w=0&k=20&c=U-y6cADCby4QwENFptPrVcK_MplesqZmnDxUMMkJZvM="
                        }
                        alt={item.title || "Default Image"}
                      />
                    </span>
                    <div className={Style.content}>
                      <div className={Style.ContentDeatil}>
                        <i className="fa-regular fa-heart"></i>
                        <h2>
                          <i className="fa-solid fa-lock-open"></i> Open Now
                        </h2>
                      </div>
                      <div className={Style.FullDetail}>
                        <div className={Style.contentData}>
                          <div className={Style.name}>
                            <h2>{item.title || "No Title"}</h2>
                            <h3>{item.address || "No Address"}</h3>
                          </div>
                          <div className={Style.ratingData}>
                            <h2>{item.employee_count || 0}</h2>
                            <div className={Style.ratingStar}>
                              <div className={Style.star}>
                                {Array.from({ length: 5 }).map((_, i) => (
                                  <i
                                    key={i}
                                    className={`fa-star ${
                                      i < item.employee_count
                                        ? "fa-solid"
                                        : "fa-regular"
                                    }`}
                                  />
                                ))}
                              </div>
                              <h3>{item.zip || 0} Reviews</h3>
                            </div>
                          </div>
                        </div>
                        <div className={Style.Category}>
                          <h2>
                            <i
                              className={
                                item.category_icon || "fa-solid fa-tag"
                              }
                            ></i>{" "}
                            {item.category_name || "No Category"}
                          </h2>
                          <h3>
                            <i className="fa-solid fa-phone"></i>{" "}
                            {item.phone || "No Contact"}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <p className={Style.noData}>No Listings Available.</p>
        )}
      </div>
    </div>
  );
});

export default LatestListing;
