import React from "react";
import Style from "../../style/notificationbanner.module.scss";

const NotificationBanner = ({ handleNotification }: any) => {
  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.Image}>
          <img
            src="https://img.freepik.com/premium-vector/real-estate-agent-work-investment-mortgage-house-loan-account-banking-online-service-agency-flat_118813-22875.jpg?w=360"
            alt=""
          />
        </div>
        <div className={Style.text}>
          <h2>
            Looking to Buy a New Home? Tell us about your basic requirement and
            get matching homes!
          </h2>
          <button onClick={handleNotification}>Get Notification</button>
        </div>
      </div>
    </div>
  );
};

export default NotificationBanner;
