import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Style from "../style/questionlanding.module.scss";
import AnswerBox from "./AnswerSubmit";

interface Answer {
  answer: string;
  answered_by: string;
  answered_at: string;
  likes: number;
  dislikes: number;
  comments_count: number;
}

interface Comment {
  comment: string;
  commented_by: string;
  commented_at: string;
}

interface QuestionData {
  id: number;
  subject: string;
  category: string;
  question: string;
  views: number;
  answers: Answer[];
  comments: Comment[];
  likes: number;
  dislikes: number;
  posted_by: string;
  posted_at: string;
  related_questions: string;
}

const QuestionLanding = () => {
  const { category, question } = useParams();
  const [answer, setAnswer] = useState(false);
  const [data, setData] = useState<QuestionData | null>(null);
  const [activeCommentBox, setActiveCommentBox] = useState<number | null>(null);
  const [commentInputs, setCommentInputs] = useState<{ [key: number]: string }>(
    {}
  );
  const [comments, setComments] = useState<{ [key: number]: string[] }>({});
  const [showBot, setShowBot] = useState(3);
  console.log(data, "data");
  console.log(category, question, "URL params");

  useEffect(() => {
    fetch("/data/questions.json")
      .then((res) => res.json())
      .then((value) => {
        console.log(value, "data loaded");
        const filteredData = value.find((item: QuestionData) => {
          const itemCategory = item.category
            .toLowerCase()
            .replace(/\s+/g, "-")
            .trim();
          const itemQuestion = item.question
            .toLowerCase()
            .replace(/\s+/g, "-")
            .replace(/\?/g, "")
            .trim();

          return itemCategory === category && itemQuestion === question;
        });

        setData(filteredData || null);
      });
  }, [category, question]);

  if (!data) {
    return <div className={Style.container}>Loading or No Data Found...</div>;
  }

  const bot = [
    {
      id: 1,
      img: "https://imgservice.answers.com/bots/1/professor-bot.jpg?1933198428&filter=icon",
      text: "I will give you the most educated answer.",
      name: "Professor",
    },
    {
      id: 1,
      img: "https://imgservice.answers.com/bots/1/professor-bot.jpg?1933198428&filter=icon",
      text: "I will give you the most educated answer.",
      name: "Professor",
    },
    {
      id: 1,
      img: "https://imgservice.answers.com/bots/1/professor-bot.jpg?1933198428&filter=icon",
      text: "I will give you the most educated answer.",
      name: "Professor",
    },
    {
      id: 1,
      img: "https://imgservice.answers.com/bots/1/professor-bot.jpg?1933198428&filter=icon",
      text: "I will give you the most educated answer.",
      name: "Professor",
    },
    {
      id: 1,
      img: "https://imgservice.answers.com/bots/1/professor-bot.jpg?1933198428&filter=icon",
      text: "I will give you the most educated answer.",
      name: "Professor",
    },
    {
      id: 1,
      img: "https://imgservice.answers.com/bots/1/professor-bot.jpg?1933198428&filter=icon",
      text: "I will give you the most educated answer.",
      name: "Professor",
    },
  ];

  const loadMoreBots = () => {
    setShowBot((prev) => prev + 3);
  };

  const handleInputChange = (index: number, value: string) => {
    setCommentInputs((prev) => ({ ...prev, [index]: value }));
  };

  const addComment = (index: number) => {
    if (!commentInputs[index]?.trim()) return;

    setComments((prev) => ({
      ...prev,
      [index]: [...(prev[index] || []), commentInputs[index]],
    }));

    setCommentInputs((prev) => ({ ...prev, [index]: "" }));
  };

  const handleAnswer = () => {
    setAnswer(true);
    document.documentElement.style.overflow = "hidden";
  };

  const onClose = () => {
    setAnswer(false);
    document.documentElement.style.overflow = "";
  };

  const formattedCategory = category
    ? category.charAt(0).toUpperCase().replaceAll("-", " ") +
      category.slice(1).toLowerCase().replaceAll("-", " ")
    : "";

  return (
    <div className={Style.container}>
      <>{answer && <AnswerBox onClose={onClose} />}</>
      <div className={Style.content}>
        <div className={Style.breadcrumb}>
          <a href="/question">Question</a> <span>&gt;</span>{" "}
          <a href="/question/subject">Subject</a> <span>&gt;</span>{" "}
          <a href={`/question/${category}`}>{formattedCategory}</a>
        </div>
        <div className={Style.title}>
          <h1>{data.question}</h1>
        </div>
        <div className={Style.userInfo}>
          <div className={Style.userDetails}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="#0678B1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm0 4c1.38 0 2.5 1.12 2.5 2.5S13.38 11 12 11s-2.5-1.12-2.5-2.5S10.62 6 12 6zm0 12c-2.5 0-4.71-1.28-6-3.22 0-2 4-3.08 6-3.08s6 1.08 6 3.08c-1.29 1.94-3.5 3.22-6 3.22z" />
            </svg>

            <p className={Style.userName}>
              <strong>{data.posted_by || "Anonymous"}</strong>
            </p>
          </div>
          <p className={Style.postedTime}>
            {new Date(data.posted_at).toLocaleDateString()}
          </p>
        </div>
        {[...data.answers]
          .sort((a, b) => b.likes - a.likes)
          .map((ans, index) => (
            <div key={index} className={Style.answerBox}>
              <p>{ans.answer}</p>
              <div className={Style.answerDetails}>
                <p>
                  <strong>Answered by:</strong> <span>{ans.answered_by} </span>∙{" "}
                  {new Date(ans.answered_at).toLocaleDateString()}
                </p>
                <p>
                  👍 {ans.likes} | 👎 {ans.dislikes} | 💬 {ans.comments_count}
                </p>
              </div>
            </div>
          ))}
      </div>
      <div className={Style.bot}>
        <div className={Style.title}>
          <h2>Still curious? Ask our experts.</h2>
          <h3>Chat with our AI personalities</h3>
        </div>
        <>
          <div className={Style.botDetail}>
            {bot.slice(0, showBot).map((item) => {
              return (
                <div className={Style.botData}>
                  <span>
                    <img src={item.img} alt="Bot" loading="lazy" />
                    <strong>{item.name}</strong>
                  </span>
                  <div className={Style.paragraph}>
                    <p>{item.text}</p>
                  </div>
                  <div className={Style.button}>
                    <button>Chat With {item.name}</button>
                  </div>
                </div>
              );
            })}
          </div>
          {showBot < bot.length && (
            <div className={Style.showButton}>
              <button onClick={loadMoreBots}>Show More</button>
            </div>
          )}
        </>
      </div>
      <div className={Style.MoreAnswer}>
        <div className={Style.title}>
          <h2>More answers</h2>
        </div>
        {data.answers.map((ans, index) => (
          <div key={index} className={Style.moreAnswerBox}>
            <p>{ans.answer}</p>
            <div className={Style.answerDetails}>
              <p>
                <strong>Answered by:</strong> <span>{ans.answered_by} </span>∙{" "}
                {new Date(ans.answered_at).toLocaleDateString()}
              </p>
              <p>
                👍 {ans.likes} | 👎 {ans.dislikes} | 💬 {ans.comments_count}
              </p>
            </div>

            <button
              onClick={() =>
                setActiveCommentBox(activeCommentBox === index ? null : index)
              }
              className={Style.commentButton}
            >
              {activeCommentBox === index ? "Hide Comment" : "Add Comment"}
            </button>

            {activeCommentBox === index && (
              <div className={Style.commentBox}>
                <input
                  type="text"
                  value={commentInputs[index] || ""}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  placeholder="Write a comment..."
                  className={Style.commentInput}
                />
                <button
                  onClick={() => addComment(index)}
                  className={Style.submitButton}
                >
                  Submit Comment
                </button>
              </div>
            )}
            <div className={Style.commentList}>
              {comments[index]?.map((comment, i) => (
                <p key={i} className={Style.comment}>
                  {comment}
                </p>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className={Style.addAnswer} onClick={handleAnswer}>
        <div className={Style.divide}>
          <h2>Add your answer:</h2>
          <div className={Style.coin}>
            <img
              src="https://math.answers.com/icons/coinIcon.svg"
              alt="Coin"
              loading="lazy"
            />
            <h2>Earn +20 pts</h2>
          </div>
        </div>
        <div className={Style.inputBox}>
          <span>
            Q.
            {(question?.replaceAll("-", " ") ?? "").charAt(0).toUpperCase() +
              (question?.replaceAll("-", " ") ?? "").slice(1)}
          </span>
          <div className={Style.type}>Write your answer...</div>
          <div className={Style.submitButton}>
            <button>Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionLanding;
