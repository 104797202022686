import { openDB } from "idb";

const openProductDB = async () => {
  return openDB("ProductDB", 2, {
    upgrade(db) {
      if (!db.objectStoreNames.contains("fullData")) {
        db.createObjectStore("fullData", { keyPath: "id" });
      }
    },
  });
};

export const saveProductsToDB = async (products: any[]) => {
  try {
    const db = await openProductDB();
    const tx = db.transaction("fullData", "readwrite");
    const store = tx.objectStore("fullData");

    await store.clear(); // Clear previous data
    await Promise.all(products.map((product) => store.put(product)));
    await tx.done;
    console.log("Data successfully saved to 'fullData' store.");
  } catch (error) {
    console.error("Error saving products to DB:", error);
  }
};

export const getProductsFromDB = async () => {
  try {
    const db = await openProductDB();
    const tx = db.transaction("fullData", "readonly");
    const store = tx.objectStore("fullData");

    const products = await store.getAll();
    await tx.done;
    return products;
  } catch (error) {
    console.error("Error fetching products from DB:", error);
    return [];
  }
};
