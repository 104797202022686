import React from "react";
import Style from "../style/marketingsolution.module.scss";

const services = [
  {
    title: "SEO Optimization",
    desc: "Boost your website ranking with advanced SEO strategies, including keyword research, on-page optimization, and technical SEO enhancements. Our team ensures your website stays updated with the latest search engine algorithms, driving organic traffic and improving visibility across search engines like Google and Bing.",
    image: "https://images.pexels.com/photos/6476589/pexels-photo-6476589.jpeg",
  },
  {
    title: "Content Marketing",
    desc: "Engage your audience with high-quality blog posts, infographics, and video content tailored to your brand's voice. Our content marketing strategies help establish your brand as an authority in your industry, build trust with customers, and increase organic traffic through compelling storytelling and SEO-optimized content.",
    image: "https://images.pexels.com/photos/3184338/pexels-photo-3184338.jpeg",
  },
  {
    title: "Social Media Marketing",
    desc: "Grow your brand by leveraging platforms like Facebook, Instagram, Twitter, and LinkedIn through engaging content and paid campaigns. We create data-driven social media strategies, track engagement metrics, and optimize performance to ensure maximum brand exposure, increased follower growth, and improved customer engagement.",
    image: "https://images.pexels.com/photos/1181396/pexels-photo-1181396.jpeg",
  },
  {
    title: "PPC Advertising",
    desc: "Maximize your return on investment with targeted pay-per-click (PPC) advertising campaigns on platforms like Google Ads and Facebook Ads. Our team conducts keyword research, audience targeting, and continuous ad optimization to ensure every ad dollar is spent efficiently, driving high-quality traffic and conversions.",
    image: "https://images.pexels.com/photos/6476808/pexels-photo-6476808.jpeg",
  },
  {
    title: "Email Marketing",
    desc: "Boost customer engagement with personalized email campaigns designed to nurture leads and increase conversions. We craft compelling email sequences with persuasive copywriting, automation workflows, and performance tracking to maximize open rates, click-through rates, and customer retention.",
    image: "https://images.pexels.com/photos/6850866/pexels-photo-6850866.jpeg",
  },
  {
    title: "Brand Strategy",
    desc: "Develop a strong brand identity with strategic positioning, messaging, and storytelling that resonates with your target audience. Our branding experts help you create a cohesive brand experience, including logo design, visual identity, tone of voice, and market differentiation strategies.",
    image: "https://images.pexels.com/photos/7688480/pexels-photo-7688480.jpeg",
  },
  {
    title: "Web Development",
    desc: "Create high-performing, responsive websites tailored to your business needs. Our development team specializes in front-end and back-end development, utilizing modern frameworks like React, Next.js, and Node.js to build scalable and user-friendly websites that drive engagement and conversions.",
    image:
      "https://images.pexels.com/photos/11035585/pexels-photo-11035585.jpeg",
  },
  {
    title: "Graphic Design",
    desc: "Enhance your brand's visual identity with stunning graphics, illustrations, and marketing materials. Our design team creates eye-catching logos, social media graphics, business presentations, and promotional content that align with your brand aesthetics and captivate your audience.",
    image: "https://images.pexels.com/photos/3228774/pexels-photo-3228774.jpeg",
  },
];

const MarketingSolutions = () => {
  return (
    <div className={Style.Container}>
      <div className={Style.wrapper}>
        <header className={Style.hero}>
          <h1>Marketing Solutions</h1>
          <p>Grow your business with expert marketing strategies.</p>
        </header>

        <section className={Style.services}>
          {services.map((service, index) => (
            <div key={index} className={Style.serviceCard}>
              <img
                src={service.image}
                alt={service.title}
                className={Style.serviceImage}
                loading="lazy"
              />
              <h2>{service.title}</h2>
              <p>{service.desc}</p>
            </div>
          ))}
        </section>
        <section className={Style.ChooseReason}>
          <div className={Style.Content}>
            <h2>Why Choose Our Marketing Solutions?</h2>
            <p>
              We combine cutting-edge strategies, advanced analytics, and
              creative execution to help your business grow faster and stand out
              in a competitive market. Whether you’re looking to increase brand
              awareness, boost conversions, or dominate search rankings, we have
              the right solutions tailored to your needs.
            </p>
            <ul>
              <li>
                <strong>✅ Customized Strategies:</strong> Every campaign is
                tailored to your business goals and audience.
              </li>
              <li>
                <strong>📊 Data-Driven Approach:</strong> We use real-time
                analytics to optimize every aspect of your marketing.
              </li>
              <li>
                <strong>💡 Engaging Content:</strong> From SEO-optimized blogs
                to high-converting ads, we create content that drives results.
              </li>
              <li>
                <strong>📈 Multi-Platform Growth:</strong> We ensure a strong
                brand presence on Google, Facebook, Instagram, and more.
              </li>
              <li>
                <strong>🤝 Expert Support:</strong> Our team provides 24/7
                support, insights, and strategy updates.
              </li>
            </ul>
          </div>
          <div className={Style.MarketingImage}>
            <img
              src="https://images.pexels.com/photos/3184287/pexels-photo-3184287.jpeg"
              alt="Marketing Team Strategy"
              loading="lazy"
            />
          </div>
        </section>
        <section className={Style.WorkDetail}>
          <div className={Style.workContent}>
            <h2>How We Work</h2>
            <p>
              Our streamlined approach ensures your business achieves maximum
              growth with minimal hassle. We focus on data-driven strategies,
              creativity, and innovation to deliver outstanding marketing
              results.
            </p>
            <div className={Style.steps}>
              <div className={Style.step}>
                <h3>📞 Step 1: Consultation</h3>
                <p>
                  We start with a deep understanding of your business, target
                  audience, and goals. Our team analyzes market trends to craft
                  a tailored strategy.
                </p>
              </div>
              <div className={Style.step}>
                <h3>📊 Step 2: Strategy & Planning</h3>
                <p>
                  Based on insights, we create a customized plan covering SEO,
                  social media, content marketing, and paid ads to ensure
                  measurable success.
                </p>
              </div>
              <div className={Style.step}>
                <h3>🎨 Step 3: Content Creation</h3>
                <p>
                  Our creative team develops high-quality visuals, engaging
                  content, and compelling ad copies to captivate your audience.
                </p>
              </div>
              <div className={Style.step}>
                <h3>🚀 Step 4: Execution & Optimization</h3>
                <p>
                  We launch your campaigns, monitor performance, and
                  continuously refine strategies using real-time data analytics
                  for the best results.
                </p>
              </div>
              <div className={Style.step}>
                <h3>📈 Step 5: Reporting & Growth</h3>
                <p>
                  We provide detailed reports, insights, and recommendations to
                  help scale your business and maximize return on investment
                  (ROI).
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className={Style.cta}>
          <h2>Get Started with Our Marketing Solutions</h2>
          <p>
            If you're ready to take your marketing efforts to the next level,
            Contact us today to schedule a consultation and discover how our
            marketing solutions can unlock your business’s full potential. Let’s
            work together to achieve your business goals and drive lasting
            success.
          </p>
          <button>
            <a href="/contact">Get Started</a>
          </button>
        </section>
      </div>
    </div>
  );
};

export default MarketingSolutions;
