export const openDB = (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("StateDB", 1);

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      if (!db.objectStoreNames.contains("states")) {
        db.createObjectStore("states", { keyPath: "id" });
      }
    };

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject("Error opening IndexedDB");
  });
};

export const saveStatesToDB = async (states: any[]): Promise<void> => {
  const db = await openDB();
  const tx = db.transaction("states", "readwrite");
  const store = tx.objectStore("states");

  states.forEach((state) => {
    store.put(state);
  });

  return new Promise<void>((resolve, reject) => {
    tx.oncomplete = () => resolve();
    tx.onerror = () => reject("Transaction failed");
  });
};

export const getStatesFromDB = async (): Promise<any[]> => {
  const db = await openDB();
  return new Promise<any[]>((resolve) => {
    const tx = db.transaction("states", "readonly");
    const store = tx.objectStore("states");
    const request = store.getAll();

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => resolve([]);
  });
};

export const syncStatesWithDB = async (apiStates: any[]) => {
  const localStates = await getStatesFromDB();

  const newStates = apiStates.filter(
    (apiState) =>
      !localStates.some((localState) => localState.id === apiState.id)
  );

  if (newStates.length > 0) {
    await saveStatesToDB(newStates);
  }

  return localStates.length > 0 ? localStates : apiStates;
};
