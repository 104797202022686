import React, { useEffect, useState } from "react";
import Style from "../style/productlisting.module.scss";
import { useAppDispatch } from "../redux/hooks";
import { fetchListing } from "../redux/Slice";
import Article from "../component/Article";
import Question from "../component/Question";
import SeachResultOverlay from "../component/SeachResultOverlay";
import ProductBanner from "./ProductBanner";
import ProductDivide from "./ProductDivide";
import ProductListingData from "./ProductListingData";
import ProductRightContent from "./ProductRightContent";
import { getProductsFromDB, saveProductsToDB } from "../indexDb/productDb";
import defaultData from "../json/product.json";

const ProductListing = () => {
  const dispatch = useAppDispatch();
  const [overlay, setOverlay] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const [localListings, setLocalListings] = useState<any[]>([]);

  const filterValidItems = (items: any[]) => {
    const keysToCheck = [
      "title",
      "address",
      "phone",
      "category_name",
      "zip",
      "employee_count",
    ];

    return items.filter((item) =>
      keysToCheck.some(
        (key) =>
          item[key] !== null && item[key] !== "" && item[key] !== undefined
      )
    );
  };

  useEffect(() => {
    const loadData = async () => {
      const localProducts = await getProductsFromDB();

      if (!localProducts || localProducts.length === 0) {
        try {
          const apiData = await dispatch(fetchListing()).unwrap();

          if (apiData?.listings?.length > 0) {
            const validItems = filterValidItems(apiData.listings);
            setLocalListings(validItems);
            await saveProductsToDB(validItems);
          } else {
            const validDefaultItems = filterValidItems(defaultData);
            setLocalListings(validDefaultItems);
          }
        } catch (error) {
          const validDefaultItems = filterValidItems(defaultData);
          setLocalListings(validDefaultItems);
        }
      } else {
        const validLocalItems = filterValidItems(localProducts);
        setLocalListings(validLocalItems);
      }
    };

    loadData();
  }, [dispatch]);

  const currentItems = localListings.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber: number) => {
    if (
      pageNumber >= 1 &&
      pageNumber <= Math.ceil(localListings.length / itemsPerPage)
    ) {
      setCurrentPage(pageNumber);
    }
  };

  const totalPages = Math.ceil(localListings.length / itemsPerPage);

  const renderPaginationButtons = () => {
    const totalPages = Math.ceil(localListings.length / itemsPerPage);
    let pages: any[] = [];
    pages.push(
      <button
        key={1}
        onClick={() => handlePageChange(1)}
        className={currentPage === 1 ? Style.active : ""}
      >
        1
      </button>
    );
    if (currentPage > 3) {
      pages.push(<span key="ellipsis-start">...</span>);
    }
    let startPage = Math.max(2, currentPage - 1);
    let endPage = Math.min(totalPages - 1, currentPage + 1);
    if (currentPage === 2) {
      startPage = 2;
      endPage = Math.min(4, totalPages - 1);
    } else if (currentPage === totalPages - 1) {
      startPage = Math.max(totalPages - 3, 2);
      endPage = totalPages - 1;
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={currentPage === i ? Style.active : ""}
        >
          {i}
        </button>
      );
    }
    if (currentPage < totalPages - 2) {
      pages.push(<span key="ellipsis-end">...</span>);
    }

    if (totalPages > 1) {
      pages.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className={currentPage === totalPages ? Style.active : ""}
        >
          {totalPages}
        </button>
      );
    }

    return pages;
  };

  return (
    <div className={Style.container}>
      {overlay && <SeachResultOverlay handleClick={() => setOverlay(false)} />}
      <div className={Style.wrapper}>
        <div className={Style.mainbox}>
          <div className={Style.breadcrumb}>
            <a href="/">Home</a>
            <i className="fa-solid fa-angle-right"></i>
            <span>All Item</span>
          </div>
          <div className={Style.title}>
            <h2>All Items</h2>
          </div>
          <div className={Style.Main}>
            <div className={Style.leftAside}>
              <ProductDivide />
              <ProductBanner />
              <div className={Style.listItem}>
                {currentItems &&
                  currentItems.map((item: any, index: number) => (
                    <ProductListingData
                      key={item.id || index}
                      itemList={item}
                      index={index}
                    />
                  ))}
              </div>
              <div className={Style.paginationButton}>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {renderPaginationButtons()}
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>

            <div className={Style.rightAside}>
              <ProductRightContent handleOverlay={() => setOverlay(true)} />
            </div>
          </div>
          <Article />
          <Question />
        </div>
      </div>
    </div>
  );
};

export default ProductListing;
