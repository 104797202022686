import React from "react";
import ImageBox from "../component/ImageBox";
import Detail from "../component/Detail";
import Claim from "../component/Claim";
import Bussiness from "../component/Bussiness";
import Content from "../component/Content";
import AppDetail from "../component/AppDetail";
import LatestListing from "../component/LatestListing";
import Article from "../component/Article";
import City from "../component/City";

const HomePage = () => {
  return (
    <>
      <ImageBox />
      <Content />
      <LatestListing />
      <City />
      <Claim />
      <Bussiness />
      <Detail />
      <Article />
      <AppDetail />
    </>
  );
};

export default HomePage;
