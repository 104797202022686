import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Style from "../style/rightside.module.scss";

const RightSidebar = () => {
  // Extract params from URL
  const { subject, category, question } = useParams<{
    subject?: string;
    category?: string;
    question?: string;
  }>();

  // State to store data and questions
  const [data, setData] = useState<any[]>([]);
  const [trendingQuestions, setTrendingQuestions] = useState<any[]>([]);
  const [relatedQuestions, setRelatedQuestions] = useState<any[]>([]);

  // Normalize function to convert strings to lowercase, remove spaces, and replace & with 'and'
  const normalize = (str: string) =>
    str.trim().toLowerCase().replace(/\s+/g, "-").replace(/&/g, "and");

  // Fetch data on component mount
  useEffect(() => {
    fetch("/data/questions.json")
      .then((res) => res.json())
      .then((value) => {
        setData(value);
      })
      .catch((error) => console.error("Error fetching questions:", error));
  }, []);

  // Filter related and trending questions based on URL params
  useEffect(() => {
    if (data.length === 0) return;

    console.log("Subject:", subject);
    console.log("Category:", category);
    console.log("Question:", question);

    if (category && question) {
      // Get trending questions with highest likes in the same category
      const filteredTrending = data
        .filter((q) => normalize(q.category) === normalize(category))
        .sort((a, b) => b.likes - a.likes)
        .slice(0, 10);

      // Get related questions within the same category but exclude the current question
      const filteredRelated = data
        .filter(
          (q) =>
            normalize(q.category) === normalize(category) &&
            normalize(q.question) !== normalize(question)
        )
        .sort((a, b) => b.comments_count - a.comments_count)
        .slice(0, 10);

      setTrendingQuestions(filteredTrending);
      setRelatedQuestions(filteredRelated);
    } else if (category) {
      // Show category-based trending questions if only category is provided
      const filteredTrending = data
        .filter((q) => normalize(q.category) === normalize(category))
        .sort((a, b) => b.likes - a.likes)
        .slice(0, 10);

      setTrendingQuestions(filteredTrending);
      setRelatedQuestions([]);
    } else {
      // Show global trending and related questions if no category or question provided
      const filteredTrending = [...data]
        .sort((a, b) => b.likes - a.likes)
        .slice(0, 10);

      const filteredRelated = [...data]
        .sort((a, b) => b.comments_count - a.comments_count)
        .slice(0, 10);

      setTrendingQuestions(filteredTrending);
      setRelatedQuestions(filteredRelated);
    }
  }, [data, category, question, subject]);

  return (
    <div className={Style.container}>
      <div className={Style.trending}>
        <h2>Trending Questions</h2>
        <ul>
          {trendingQuestions.length > 0 ? (
            trendingQuestions.map((q, index) => (
              <li key={index}>
                <a
                  href={`/question/${normalize(q.category)}/${normalize(
                    q.question
                  )}`}
                >
                  {q.question}
                </a>
              </li>
            ))
          ) : (
            <p>No trending questions found.</p>
          )}
        </ul>
      </div>
      <div className={Style.related}>
        <h2>Related Questions</h2>
        <ul>
          {relatedQuestions.length > 0 ? (
            relatedQuestions.map((q, index) => (
              <li key={index}>
                <a
                  href={`/question/${normalize(q.category)}/${normalize(
                    q.question
                  )}`}
                >
                  {q.question}
                </a>
              </li>
            ))
          ) : (
            <p>No related questions found.</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default RightSidebar;
