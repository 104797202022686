import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./mainComponent/App";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import SliceData from "./redux/SliceData";

const rootElement = document.getElementById("root");

if (rootElement) {
  ReactDOM.createRoot(rootElement).render(
    <Provider store={store}>
      <SliceData />
      <App />
    </Provider>
  );
}
