import React from "react";
import Style from "../style/aboutmission.module.scss";

const AboutMission = () => {
  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.Image}>
          <img
            src="https://tiimg.tistatic.com/new_website1/design2022/ti-about/about3.jpg"
            alt="Design"
            loading="lazy"
          />
        </div>
        <div className={Style.content}>
          <h2>Our Mission</h2>
          <p className={Style.paragraph}>
            Our mission is to empower local businesses by giving them the tools
            they need to establish a strong digital presence. We believe that
            every business, no matter how small, should have access to a
            platform that helps them reach a wider audience, gain customer
            trust, and stay competitive in the ever-evolving digital landscape.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutMission;
