import React, { useState } from "react";
import Style from "../../style/relatorsrightside.module.scss";
import GetConnected from "./GetConnected";

const RelatorRightSide = () => {
  const [show, setShow] = useState(false);
  const handleClick = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <div className={Style.container}>
      <>{show && <GetConnected handleClose={handleClose} />}</>
      <div className={Style.box}>
        <h2>Share your requirements</h2>
        <p>
          Please provide your preferred location, property type and budget. We
          will connect property owners/realtors instantly
        </p>
        <button onClick={handleClick}>Get Connected</button>
      </div>
      <div className={Style.banner}>
        <img
          src="https://d37v7af6a55f7e.cloudfront.net/images/product/X-Frame-Banners-678_09161911202406.jpg"
          alt=""
        />
      </div>
      <div className={Style.banner}>
        <img
          src="https://www.bankrate.com/2023/07/20160747/Homes-Real-Estate-in-Texas.jpg"
          alt=""
        />
      </div>
      <div className={Style.post}>
        <h2>Get connect with new buyers</h2>
        <p>
          Companies and Agents are increasing their business market with Bipko
        </p>
        <a href="#/">List Your property</a>
      </div>
      <div className={Style.banner}>
        <img
          src="https://cdn.vectorstock.com/i/500p/01/15/real-estate-sale-background-vector-4400115.jpg"
          alt=""
        />
      </div>
    </div>
  );
};

export default RelatorRightSide;
