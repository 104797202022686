import React from "react";
import Style from "../style/appdetail.module.scss";

const AppDetail = () => {
  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.Image}>
          <img
            src="https://www.tradeindia.com/images/default/mobile-app.png"
            alt="Mobile"
            loading="lazy"
          />
        </div>
        <div className={Style.content}>
          <h2>Looking for the Best Service Provider? Get the App!</h2>
          <ul>
            <li>
              Find Nearby Listings – Discover top-rated service providers near
              you.
            </li>
            <li>
              Easy Service Enquiry – Connect with businesses in just a few
              clicks.
            </li>
            <li>
              Listing Reviews & Ratings – Make informed decisions with customer
              feedback.
            </li>
            <li>
              Manage Your Listing, Enquiries & Reviews – Stay in control of your
              business profile.
            </li>
          </ul>
          <span>
            Get the App: We’ll send you a link to the email you provide. Open it
            on your smartphone to download the app!
          </span>
          <div className={Style.appImage}>
            <a href="#/">
              <img
                src="https://www.tradeindia.com/images/icons/google-play.svg"
                alt="Google"
                loading="lazy"
              />
            </a>
            <a href="#/">
              <img
                src="https://www.tradeindia.com/images/icons/app-store.svg"
                alt="App"
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppDetail;
