import React, { useEffect, useState } from "react";
import styles from "../style/leftSide.module.scss";

const LeftSidebar = () => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    fetch("/data/questions.json")
      .then((res) => res.json())
      .then((value) => {
        const uniqueSubjects = Array.from(
          new Set(value.map((item: any) => item.subject))
        ).map((subject) => {
          return value.find((item: any) => item.subject === subject);
        });
        setData(uniqueSubjects);
      });
  }, []);

  return (
    <aside className={styles.sidebar}>
      <a href="/question/subject" className={styles.title}>
        Subjects
      </a>
      <ul className={styles.subjectList}>
        {data.map((item: any, index: number) => (
          <li key={index} className={styles.subjectItem}>
            <a
              href={`/question/subject/${item.subject
                .toLowerCase()
                .replace(/ /g, "-")}`}
              className={styles.subjectLink}
            >
              {item.subject}
            </a>
          </li>
        ))}
      </ul>
    </aside>
  );
};

export default LeftSidebar;
