import React, { useState } from "react";
import Style from "../../style/relatorlanding.module.scss";
import RelatorContactForm from "./Form/RelatorContactForm";
import NotificationBanner from "./NotificationBanner";
import Article from "../../component/Article";
import Question from "../../component/Question";

const RelatorLanding = () => {
  const [mainRating, setMainRating] = useState(0);
  const [mainHover, setMainHover] = useState(0);

  const [qualityRating, setQualityRating] = useState(0);
  const [qualityHover, setQualityHover] = useState(0);

  const [valueRating, setValueRating] = useState(0);
  const [valueHover, setValueHover] = useState(0);

  const [satisfactionRating, setSatisfactionRating] = useState(0);
  const [satisfactionHover, setSatisfactionHover] = useState(0);
  const [files, setFiles] = useState<File[]>([]);
  const [Image, setImage] = useState(false);
  const [message, setMessage] = useState("");

  const data = [
    {
      id: 1,
      name: "John Doe",
      location: "New York, NY, USA",
      address: "123 Main Street, New York, NY 10001",
      service_areas: ["Manhattan", "Brooklyn", "Queens"],
      img: "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSpO4Z4ZYoBnCzzrTdRT18KNoNEkTgdASFJewjnseBYJWBbHqFZ",
      properties_listed: 25,
      price_range: "$200,000 - $5,000,000",
      experience: "5 - 10 years",
      description:
        "Real Estate Professional. Specialties:Buyer's Agent,Listing Agent,Relocation,Property Management,Landlord. Why select Binglin as your real estate agent? My friends and clients will tell you: He is kind and pleasant to work with. He loves his job and is hardworking. He is intelligent and knowledgeable in real estate. BTW, he has a PhD in environmental science. My answer is that I am a genuine person who cares about the happiness of others. I believe that real estate is much more than simply buying or selling a house. I am committed to your satisfaction and building trust in our relationship. I would love to help you reach your housing goals and enjoy the buying/selling process. Your friendship is my best reward.",
      properties: {
        for_sale: 15,
        for_rent: 10,
        property_types: ["Apartments", "Villas", "Condos", "Commercial"],
      },
      established_since: 2010,
      specialization: ["Luxury homes", "First-time home buyers"],
      languages_known: ["English", "Spanish"],
      services_offered: ["Buyer’s Agent", "Seller’s Agent"],
      contact: {
        phone: "+1 234 567 890",
        email: "johndoe@example.com",
        website: "https://johndoeproperties.com",
      },
    },
  ];

  const handleFileChange = (e: any) => {
    setFiles([...e.target.files]);
  };
  const renderStars = (
    currentValue: number,
    currentHover: number,
    setCurrentValue: (val: number) => void,
    setCurrentHover: (val: number) => void
  ) => (
    <div className={Style.starRating}>
      {[...Array(5)].map((_, i) => {
        const val = i + 1;
        return (
          <span
            key={i}
            className={`${Style.star} ${
              val <= (currentHover || currentValue) ? Style.filled : ""
            }`}
            onClick={() => setCurrentValue(val)}
            onMouseEnter={() => setCurrentHover(val)}
            onMouseLeave={() => setCurrentHover(0)}
          >
            ★
          </span>
        );
      })}
      <span className={Style.ratingLabel}>{currentValue} / 5</span>
    </div>
  );

  const renderStars1 = (
    currentValue: number,
    currentHover: number,
    setCurrentValue: (val: number) => void,
    setCurrentHover: (val: number) => void
  ) => (
    <div className={Style.starRating}>
      {[...Array(5)].map((_, i) => {
        const val = i + 1;
        return (
          <span
            key={i}
            className={`${Style.star} ${
              val <= (currentHover || currentValue) ? Style.filled : ""
            }`}
            onClick={() => setCurrentValue(val)}
            onMouseEnter={() => setCurrentHover(val)}
            onMouseLeave={() => setCurrentHover(0)}
          >
            ★
          </span>
        );
      })}
    </div>
  );

  const handleImage = () => {
    setImage(true);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (mainRating === 0) {
      setMessage("Please Enter Your Rating");
      return;
    }
  };
  return (
    <div className={Style.container}>
      <div className={Style.backgroundImage}>
        <div className={Style.wrapper}>
          {data.map((item) => {
            return (
              <div className={Style.agentFullDetail}>
                <div className={Style.agentDetail}>
                  <div className={Style.agentImage}>
                    <img src={item.img} alt={item.name} />
                  </div>
                  <div className={Style.agentDatacontent}>
                    <label>Agent</label>
                    <div className={Style.agentLocation}>
                      <h2>
                        {item.name} in <span>{item.location}</span>
                      </h2>
                      <p>The Best Real Estate Agent</p>
                    </div>
                    <div className={Style.agentBioData}>
                      <ul>
                        <li>
                          <i className="fa-solid fa-phone"></i>
                          {item.contact.phone}
                        </li>
                        <li>
                          <i className="fa-solid fa-location-dot"></i>
                          {item.address}
                        </li>
                        <li>
                          <i className="fas fa-envelope"></i>
                          {item.contact.email}
                        </li>
                        <li>
                          <i className="fas fa-ruler-combined"></i>Serving Area{" "}
                          {item.service_areas}
                        </li>
                        <li>
                          <i className="fas fa-ruler-combined"></i>{" "}
                          {item.service_areas}
                        </li>
                      </ul>
                    </div>
                    <div className={Style.agentscoreDetail}>
                      <h2>{item.experience}</h2>
                      <h3>{item.properties_listed}</h3>
                    </div>
                  </div>
                </div>
                <div className={Style.agentContact}>
                  <button> Contact Agent </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={Style.wrapper}>
        <div className={Style.leftAside}>
          <div className={Style.highLight}>
            <h2>Highlights</h2>
            {data.map((item) => {
              return (
                <>
                  {" "}
                  <div className={Style.properties}>
                    <ul>
                      <li>
                        No. of properties<span>{item.properties_listed}</span>
                      </li>
                      <li>
                        Price Range <span>{item.price_range}</span>
                      </li>
                    </ul>
                  </div>
                  <div className={Style.agentInfo}>
                    <div className={Style.leftInfo}>
                      <ul>
                        <li>
                          Established Since<span>{item.established_since}</span>
                        </li>
                        <li>
                          Service Areas
                          <span>{item.service_areas.join(", ")}</span>
                        </li>
                        <li>
                          Specialization
                          <span>{item.specialization.join(", ")}</span>
                        </li>
                        <li>
                          Languages known
                          <span>{item.languages_known.join(", ")}</span>
                        </li>
                        <li>
                          Services
                          <span>{item.services_offered.join(", ")}</span>
                        </li>
                      </ul>
                    </div>
                    <div className={Style.rightInfo}>
                      <h2>Social Contact</h2>
                      <ul>
                        <li>
                          <a href="#/">
                            <i className="fas fa-link"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#/">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#/">
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              );
            })}
            <div className={Style.agentButton}>
              <button>Contact Agent</button>
            </div>
          </div>
          <div className={Style.description}>
            <p>
              <span>About{data[0].name} - </span> {data[0].description}
            </p>
          </div>
          <div className={Style.serviceArea}>
            <h2>Service Areas</h2>
            <ul>
              <li>
                <a href="#/">{data[0].location}</a>
              </li>
            </ul>
          </div>
          <div className={Style.reviewForm}>
            <h2>Write a Review for {data[0].name}</h2>
            <div className={Style.ratingBox}>
              <div className={Style.formGroup}>
                <label>How would you rate this business?</label>
                {renderStars(
                  mainRating,
                  mainHover,
                  setMainRating,
                  setMainHover
                )}
              </div>
              <div className={Style.formGroup}>
                <label>Kindly elaborate</label>
                <div className={Style.formStar}>
                  <label>Quality of service:</label>
                  {renderStars1(
                    qualityRating,
                    qualityHover,
                    setQualityRating,
                    setQualityHover
                  )}
                </div>
                <div className={Style.formStar}>
                  <label>Value for money:</label>
                  {renderStars1(
                    valueRating,
                    valueHover,
                    setValueRating,
                    setValueHover
                  )}
                </div>

                <div className={Style.formStar}>
                  <label>Customer satisfaction:</label>
                  {renderStars1(
                    satisfactionRating,
                    satisfactionHover,
                    setSatisfactionRating,
                    setSatisfactionHover
                  )}
                </div>
              </div>
            </div>
            <div className={Style.formText}>
              <textarea placeholder="Briefly explain your requirement or request for any specific details" />
            </div>
            <div className={Style.ImageDetail}>
              <div className={Style.formImage}>
                <label>Add photos to this review:</label>
                <div className={Style.photoUpload} onClick={handleImage}>
                  <span className={Style.cameraIcon}>📷</span>
                  Add Photos
                </div>
              </div>
              {Image && (
                <>
                  <div className={Style.AddImage}>
                    <input
                      id="photo-upload"
                      type="file"
                      multiple
                      accept="image/*"
                      onChange={handleFileChange}
                      className={Style.hiddenInput}
                    />
                  </div>
                  {files.length > 0 && (
                    <ul className={Style.fileList}>
                      {files.map((file, index) => (
                        <li key={index}>{file.name}</li>
                      ))}
                    </ul>
                  )}
                </>
              )}
            </div>
            <div className={`${Style.formButton} ${Style.submit}`}>
              <button type="submit" onClick={handleSubmit}>
                Public Review
              </button>
            </div>
            <div className={Style.messsage}>{message}</div>
          </div>
          <RelatorContactForm itemList={data} layout="sidebar" />
        </div>
        <div className={Style.rightAside}>
          <RelatorContactForm itemList={data} layout="rightside" />
        </div>
      </div>
      <NotificationBanner />
      <Article />
      <Question />
    </div>
  );
};

export default RelatorLanding;
