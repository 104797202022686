const DB_NAME = "BlogDB";
const DB_VERSION = 2;
const STORE_NAME = "blogs";

let db: IDBDatabase;

/**
 * Opens the IndexedDB database and returns the connection.
 */
const openDB = (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request: IDBOpenDBRequest = indexedDB.open(DB_NAME, DB_VERSION);

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      console.log("[IndexedDB] Upgrading DB...");

      if (!db.objectStoreNames.contains(STORE_NAME)) {
        console.log(`[IndexedDB] Creating store: "${STORE_NAME}"`);
        db.createObjectStore(STORE_NAME, { keyPath: "id" });
      }
    };

    request.onsuccess = (event) => {
      db = (event.target as IDBOpenDBRequest).result;

      if (!db.objectStoreNames.contains(STORE_NAME)) {
        reject(`Object store "${STORE_NAME}" not found`);
        return;
      }

      console.log("[IndexedDB] DB opened successfully");
      resolve(db);
    };

    request.onerror = (event) => {
      reject(`Error opening DB: ${(event.target as IDBOpenDBRequest).error}`);
    };
  });
};

/**
 * Adds or updates blog entries in the IndexedDB store.
 * @param blogs Array of blog objects
 */
const addOrUpdateBlogs = async (blogs: any[]): Promise<boolean> => {
  const db: IDBDatabase = await openDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  const store = tx.objectStore(STORE_NAME);

  blogs.forEach((blog) => {
    store.put(blog);
  });

  return new Promise((resolve, reject) => {
    tx.oncomplete = () => {
      console.log("[IndexedDB] Blogs saved successfully.");
      resolve(true);
    };

    tx.onerror = (event) => {
      reject(`Transaction error: ${(event.target as IDBRequest).error}`);
    };
  });
};

/**
 * Retrieves all blogs from the IndexedDB store.
 */
const getBlogsFromDB = async (): Promise<any[]> => {
  const db: IDBDatabase = await openDB();

  return new Promise((resolve, reject) => {
    const tx = db.transaction(STORE_NAME, "readonly");
    const store = tx.objectStore(STORE_NAME);
    const request = store.getAll();

    request.onsuccess = (event) => {
      resolve((event.target as IDBRequest).result);
    };

    request.onerror = (event) => {
      reject(`Error fetching blogs: ${(event.target as IDBRequest).error}`);
    };
  });
};

export { addOrUpdateBlogs, getBlogsFromDB };
