import React, { useState } from "react";
import Style from "../style/productreview.module.scss";

export interface IType {
  renderStars: any;
}

const ProductReview: React.FC<IType> = ({ renderStars }) => {
  const [activeTab, setActiveTab] = useState("mostRecent");
  const reviews = [
    {
      id: 1,
      name: "Carolyne N.",
      date: "October 9, 2024",
      rating: 4,
      serviceRating: 4,
      review:
        "Recently ate at this restaurant and was very impressed! The food was exceptionally tasty - I especially enjoyed the sushi. Honestly, everything on the menu looked appetizing. The staff provided friendly service too and I'll definitely be returning soon!",
      helpful: 0,
    },
    {
      id: 2,
      name: "John D.",
      date: "September 22, 2024",
      rating: 5,
      serviceRating: 5,
      review:
        "Fantastic experience! The customer service was top-notch, and the ambiance was great. Highly recommended!",
      helpful: 2,
    },
    {
      id: 3,
      name: "Linda K.",
      date: "August 15, 2024",
      rating: 3,
      serviceRating: 3,
      review:
        "It was okay. The food was decent but nothing extraordinary. Might come back for another try.",
      helpful: 1,
    },
    {
      id: 4,
      name: "August K.",
      date: "February 15, 2024",
      rating: 4,
      serviceRating: 5,
      review:
        "It was okay. The food was decent but nothing extraordinary.But service is too good so I try to come back  another ",
      helpful: 1,
    },
  ];

  const sortedReviews = [...reviews].sort((a, b) => {
    if (activeTab === "highestRated") return b.rating - a.rating;
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });
  return (
    <div className={Style.reviewSection}>
      <h2>Reviews</h2>
      <div className={Style.tabs}>
        <button
          className={activeTab === "mostRecent" ? Style.active : ""}
          onClick={() => setActiveTab("mostRecent")}
        >
          Most Recent
        </button>
        <button
          className={activeTab === "highestRated" ? Style.active : ""}
          onClick={() => setActiveTab("highestRated")}
        >
          Highest Rated
        </button>
        <i></i>
      </div>

      <div className={Style.reviewList}>
        {sortedReviews.map((review) => (
          <div key={review.id} className={Style.reviewCard}>
            <div className={Style.reviewHeader}>
              <h3>{review.name}</h3>
              <p className={Style.reviewDate}>{review.date}</p>
            </div>

            <div className={Style.reviewRatings}>
              <div className={Style.stardata}>
                <p className={Style.reviewStars}>
                  <span>Overall</span> {renderStars(review.rating)}
                </p>
                <p className={Style.reviewStars}>
                  <span>Service</span> {renderStars(review.serviceRating)}
                </p>
              </div>
              <p className={Style.reviewText}>{review.review}</p>
            </div>

            <div className={Style.reviewActions}>
              <button className={Style.helpfulButton}>
                Helpful ({review.helpful})
              </button>
              <button className={Style.flagButton}>Flag</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductReview;
