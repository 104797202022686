import React from "react";
import Style from "../style/mainlayout.module.scss";
import LeftSidebar from "./LeftSide";
import RightSidebar from "./RightSide";

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className={Style.layout}>
      <div className={Style.wrapper}>
        <LeftSidebar />
        <main className={Style.mainContent}>{children}</main>
        <RightSidebar />
      </div>
    </div>
  );
};

export default MainLayout;
