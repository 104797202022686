import React, { useState } from "react";
import Style from "../../style/realstatefilter.module.scss";
import Article from "../../component/Article";
import Question from "../../component/Question";
import NotificationBanner from "./NotificationBanner";
import NotificationForm from "./Form/NotificationForm";
import RealStateFilterData from "./RealStateFilterData";
import RealStateFilterRight from "./RealStateFilterRight";

const RealStateFilter = () => {
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [notification, setNotification] = useState(false);

  const handleToggleAdvanced = () => {
    setShowAdvanced(!showAdvanced);
  };

  const data = [
    {
      id: 1,
      category: "Real Estate",
      city: "New York, NY",
      name: "30 Libby Avenue",
      address: "New York, NY",
      price: "$1,234",
      pin: "10292",
      type: "Condos",
      area: "2345 sqft",
      built: "2000",
      img: "https://homes.sulekha.com/nriproperty/common/images/other/default-listing-img.jpg",
      rate: "$1,234 per Sq Ft",
      room: "5",
      bath: "8",
      saleType: "Resale",
      text: "This stunning neo-gothic home offers a myriad of possibilities with a 30' wide lot, a width unheard of in today's market. Currently set up as an owners triplex with a meticulously landscaped garden, with rental income from 4 apartments and a street level office. T",
      agentImg:
        "https://usimg.sulekha.io/cdn/nriproperty/images/thumbnail/nriproperty_2022-01-23-08-55-36-412_40.jpeg",
      agentName: "Nick Anable And Seth Podell",
      properties: 4,
      amenities: [
        {
          name: "Parking",
          icon: "fa-solid fa-square-parking",
        },
        {
          name: "Security",
          icon: "fa-solid fa-shield-halved",
        },
        {
          name: "Swimming Pool",
          icon: "fa-solid fa-person-swimming",
        },
        {
          name: "Garden",
          icon: "fa-solid fa-seedling",
        },
        {
          name: "Play Area",
          icon: "fa-solid fa-child",
        },
        {
          name: "Gym",
          icon: "fa-solid fa-dumbbell",
        },
        {
          name: "Clubhouse",
          icon: "fa-solid fa-music",
        },
      ],
      parkingAvailability: "Available",
      flooringType: "Marble",
    },
    {
      id: 1,
      category: "Real Estate",
      city: "New York, NY",
      address: "New York, NY",
      name: "41 Hope Lane",
      built: "1980",
      pin: "10292",
      price: "$1,234",
      type: "Condos",
      area: "2345 sqft",
      img: "https://homes.sulekha.com/nriproperty/common/images/other/default-listing-img.jpg",
      rate: "$1,234 per Sq Ft",
      room: "5",
      bath: "8",
      saleType: "Resale",
      text: "This stunning neo-gothic home offers a myriad of possibilities with a 30' wide lot, a width unheard of in today's market. Currently set up as an owners triplex with a meticulously landscaped garden, with rental income from 4 apartments and a street level office. T",
      agentImg:
        "https://usimg.sulekha.io/cdn/nriproperty/images/thumbnail/nriproperty_2022-01-23-08-55-36-412_40.jpeg",
      agentName: "Nick Anable And Seth Podell",
      properties: 4,
      amenities: [
        {
          name: "Parking",
          icon: "fa-solid fa-square-parking",
        },
        {
          name: "Security",
          icon: "fa-solid fa-shield-halved",
        },
        {
          name: "Garden",
          icon: "fa-solid fa-seedling",
        },
        {
          name: "Play Area",
          icon: "fa-solid fa-child",
        },
        {
          name: "Clubhouse",
          icon: "fa-solid fa-music",
        },
      ],
      parkingAvailability: "Available",
      flooringType: "Tile",
    },
    {
      id: 1,
      category: "Real Estate",
      city: "New York, NY",
      address: "New York, NY",
      name: "36 Mineola Avenue",
      pin: "10292",
      price: "$1,234",
      built: "2015",
      type: "Condos",
      area: "2345 sqft",
      img: "https://homes.sulekha.com/nriproperty/common/images/other/default-listing-img.jpg",
      rate: "$1,234 per Sq Ft",
      room: "5",
      bath: "8",
      saleType: "Resale",
      text: "This stunning neo-gothic home offers a myriad of possibilities with a 30' wide lot, a width unheard of in today's market. Currently set up as an owners triplex with a meticulously landscaped garden, with rental income from 4 apartments and a street level office. T",
      agentImg:
        "https://usimg.sulekha.io/cdn/nriproperty/images/thumbnail/nriproperty_2022-01-23-08-55-36-412_40.jpeg",
      agentName: "Nick Anable And Seth Podell",
      properties: 4,
      phone: "909-245-6225 ",
      amenities: [
        {
          name: "Parking",
          icon: "fa-solid fa-square-parking",
        },
        {
          name: "Security",
          icon: "fa-solid fa-shield-halved",
        },
        {
          name: "Garden",
          icon: "fa-solid fa-seedling",
        },
        {
          name: "Play Area",
          icon: "fa-solid fa-child",
        },
      ],
      parkingAvailability: "Available",
      flooringType: "Wooden",
    },
    {
      id: 1,
      category: "Real Estate",
      city: "New York, NY",
      address: "New York, NY",
      name: "179 Sagamore Drive Unit 179",
      pin: "10292",
      price: "$1,234",
      built: "2018",
      type: "Condos",
      area: "2345 sqft",
      img: "https://homes.sulekha.com/nriproperty/common/images/other/default-listing-img.jpg",
      rate: "$1,234 per Sq Ft",
      room: "5",
      bath: "8",
      saleType: "Resale",
      text: "This stunning neo-gothic home offers a myriad of possibilities with a 30' wide lot, a width unheard of in today's market. Currently set up as an owners triplex with a meticulously landscaped garden, with rental income from 4 apartments and a street level office. T",
      agentImg:
        "https://usimg.sulekha.io/cdn/nriproperty/images/thumbnail/nriproperty_2022-01-23-08-55-36-412_40.jpeg",
      agentName: "Nick Anable And Seth Podell",
      properties: 4,
      amenities: [],
      parkingAvailability: "Not Available",
      flooringType: "",
    },
    {
      id: 1,
      category: "Real Estate",
      city: "New York, NY",
      address: "New York, NY",
      pin: "10292",
      built: "2020",
      name: "215 Blueberry Lane",
      price: "$1,234",
      type: "Condos",
      area: "2345 sqft",
      img: "https://homes.sulekha.com/nriproperty/common/images/other/default-listing-img.jpg",
      rate: "$1,234 per Sq Ft",
      room: "5",
      bath: "8",
      saleType: "Resale",
      text: "This stunning neo-gothic home offers a myriad of possibilities with a 30' wide lot, a width unheard of in today's market. Currently set up as an owners triplex with a meticulously landscaped garden, with rental income from 4 apartments and a street level office. T",
      agentImg:
        "https://usimg.sulekha.io/cdn/nriproperty/images/thumbnail/nriproperty_2022-01-23-08-55-36-412_40.jpeg",
      agentName: "Nick Anable And Seth Podell",
      properties: 4,
      amenities: [
        {
          name: "Parking",
          icon: "fa-solid fa-square-parking",
        },
        {
          name: "Security",
          icon: "fa-solid fa-shield-halved",
        },
        {
          name: "Swimming Pool",
          icon: "fa-solid fa-person-swimming",
        },
        {
          name: "Garden",
          icon: "fa-solid fa-seedling",
        },
        {
          name: "Play Area",
          icon: "fa-solid fa-child",
        },
        {
          name: "Gym",
          icon: "fa-solid fa-dumbbell",
        },
        {
          name: "Clubhouse",
          icon: "fa-solid fa-music",
        },
      ],
      parkingAvailability: "Available",
      flooringType: "Marble",
    },
    {
      id: 1,
      category: "Real Estate",
      city: "New York, NY",
      address: "New York, NY",
      built: "2022",
      name: "36 Mineola Avenue",
      pin: "10292",
      price: "$1,234",
      type: "Condos",
      area: "2345 sqft",
      img: "https://homes.sulekha.com/nriproperty/common/images/other/default-listing-img.jpg",
      rate: "$1,234 per Sq Ft",
      room: "5",
      bath: "8",
      saleType: "Resale",
      text: "This stunning neo-gothic home offers a myriad of possibilities with a 30' wide lot, a width unheard of in today's market. Currently set up as an owners triplex with a meticulously landscaped garden, with rental income from 4 apartments and a street level office. T",
      agentImg:
        "https://usimg.sulekha.io/cdn/nriproperty/images/thumbnail/nriproperty_2022-01-23-08-55-36-412_40.jpeg",
      agentName: "Nick Anable And Seth Podell",
      properties: 4,
      amenities: [
        {
          name: "Parking",
          icon: "fa-solid fa-square-parking",
        },
        {
          name: "Security",
          icon: "fa-solid fa-shield-halved",
        },
        {
          name: "Swimming Pool",
          icon: "fa-solid fa-person-swimming",
        },
        {
          name: "Garden",
          icon: "fa-solid fa-seedling",
        },
        {
          name: "Play Area",
          icon: "fa-solid fa-child",
        },
        {
          name: "Gym",
          icon: "fa-solid fa-dumbbell",
        },
        {
          name: "Clubhouse",
          icon: "fa-solid fa-music",
        },
      ],
      parkingAvailability: "Available",
      flooringType: "Tile",
    },
    {
      id: 1,
      category: "Real Estate",
      city: "New York, NY",
      pin: "10292",
      price: "$1,234",
      built: "2006",
      name: "30 Libby Avenue",
      type: "Condos",
      area: "2345 sqft",
      img: "https://homes.sulekha.com/nriproperty/common/images/other/default-listing-img.jpg",
      rate: "$1,234 per Sq Ft",
      room: "5",
      bath: "8",
      saleType: "Resale",
      text: "This stunning neo-gothic home offers a myriad of possibilities with a 30' wide lot, a width unheard of in today's market. Currently set up as an owners triplex with a meticulously landscaped garden, with rental income from 4 apartments and a street level office. T",
      agentImg:
        "https://usimg.sulekha.io/cdn/nriproperty/images/thumbnail/nriproperty_2022-01-23-08-55-36-412_40.jpeg",
      agentName: "Nick Anable And Seth Podell",
      properties: 4,
      amenities: [
        {
          name: "Parking",
          icon: "fa-solid fa-square-parking",
        },
        {
          name: "Security",
          icon: "fa-solid fa-shield-halved",
        },
        {
          name: "Swimming Pool",
          icon: "fa-solid fa-person-swimming",
        },
        {
          name: "Garden",
          icon: "fa-solid fa-seedling",
        },
        {
          name: "Play Area",
          icon: "fa-solid fa-child",
        },
        {
          name: "Gym",
          icon: "fa-solid fa-dumbbell",
        },
        {
          name: "Clubhouse",
          icon: "fa-solid fa-music",
        },
      ],
      parkingAvailability: "Available",
      flooringType: "Marble",
    },
    {
      id: 1,
      category: "Real Estate",
      city: "New York, NY",
      address: "New York, NY",
      name: "179 Sagamore Drive Unit 179",
      pin: "10292",
      price: "$1,234",
      built: "2010",
      type: "Condos",
      area: "2345 sqft",
      img: "https://homes.sulekha.com/nriproperty/common/images/other/default-listing-img.jpg",
      rate: "$1,234 per Sq Ft",
      room: "5",
      bath: "8",
      saleType: "Resale",
      text: "This stunning neo-gothic home offers a myriad of possibilities with a 30' wide lot, a width unheard of in today's market. Currently set up as an owners triplex with a meticulously landscaped garden, with rental income from 4 apartments and a street level office. T",
      agentImg:
        "https://usimg.sulekha.io/cdn/nriproperty/images/thumbnail/nriproperty_2022-01-23-08-55-36-412_40.jpeg",
      agentName: "Nick Anable And Seth Podell",
      properties: 4,
      amenities: [
        {
          name: "Parking",
          icon: "fa-solid fa-square-parking",
        },
        {
          name: "Security",
          icon: "fa-solid fa-shield-halved",
        },
        {
          name: "Swimming Pool",
          icon: "fa-solid fa-person-swimming",
        },
        {
          name: "Garden",
          icon: "fa-solid fa-seedling",
        },
        {
          name: "Play Area",
          icon: "fa-solid fa-child",
        },
      ],
      parkingAvailability: "Available",
      flooringType: "Marble",
    },
  ];

  const handleNotification = () => {
    setNotification(true);
    document.documentElement.style.overflow = "hidden";
  };
  const handleNotificationForm = () => {
    setNotification(false);
    document.documentElement.style.overflow = "";
  };

  return (
    <div className={Style.container}>
      <>
        {notification && (
          <NotificationForm handleNotificationForm={handleNotificationForm} />
        )}
      </>
      <div className={Style.wrapper}>
        <div className={Style.title}>
          <h2>
            {data[0].address} for Sale & {data[0].category}
          </h2>
        </div>
      </div>
      <div className={Style.searchBox}>
        <div className={Style.wrapper}>
          <form>
            <div className={Style.box}>
              <label>Location</label>
              <input type="text" />
            </div>
            <div className={Style.box}>
              <label>Property Type</label>
              <input type="text" />
            </div>
            <div className={Style.box}>
              <label>Price</label>
              <input type="text" />
            </div>
            <div className={Style.box}>
              <label>Beds</label>
              <input type="text" />
            </div>
            <div
              className={Style.advancedSearch}
              onClick={handleToggleAdvanced}
            >
              <h2>Advanced Search</h2>
              <span>{showAdvanced ? "▲" : "▼"}</span>
            </div>
            <div className={Style.button}>
              <button type="submit">Search</button>
            </div>
            <div
              className={`${Style.fullForm} ${showAdvanced ? Style.show : ""}`}
            >
              <div className={Style.box}>
                <label>Baths</label>
                <input type="text" />
              </div>
              <div className={Style.box}>
                <label>Seller Type</label>
                <select>
                  <option>Any</option>
                  <option>Owner</option>
                  <option>Agent</option>
                  <option>Builder</option>
                </select>
              </div>
              <div className={Style.box}>
                <label>Available for</label>
                <input type="text" />
              </div>
              <div className={Style.box}>
                <label>Sq Feet</label>
                <input type="text" />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className={Style.wrapper}>
        <div className={Style.mainbox}>
          <div className={Style.leftAside}>
            <div className={Style.title}>
              <h2>Property for Sale in New York, NY</h2>
              <h3> Apartments Available for Sale</h3>
            </div>
            <div className={Style.LeftAsideData}>
              {data &&
                data.map((item) => {
                  return <RealStateFilterData itemList={item} />;
                })}
            </div>
          </div>
          <div className={Style.rightAside}>
            {data &&
              data.slice(0, 1).map((item) => {
                return <RealStateFilterRight itemList={item} />;
              })}
          </div>
        </div>
      </div>
      <NotificationBanner handleNotification={handleNotification} />
      <Article />
      <Question />
    </div>
  );
};

export default RealStateFilter;
