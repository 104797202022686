import React, { useEffect, useRef, useState } from "react";
import Style from "../style/unanswered.module.scss";

const UnAnswered = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(null);
  const dropdownRef = useRef<any>(null);

  useEffect(() => {
    fetch("/data/questions.json")
      .then((res) => res.json())
      .then((value) => {
        const unansweredQuestions = value.filter(
          (item: any) => !item.answer || item.answer.trim() === ""
        );
        const uniqueSubjects: any = Array.from(
          new Map(
            unansweredQuestions
              .map((item: any) => [item.subject, item])
              .sort((a: any, b: any) => b[1].likes - a[1].likes)
          ).values()
        ).slice(0, 16);

        setData(uniqueSubjects);
      });
  }, []);

  const handleShare = (item: any) => {
    if (navigator.share) {
      navigator
        .share({
          title: item.question,
          text: `Check out this question: ${item.question}`,
          url: `${window.location.origin}/question/${item.category
            .toLowerCase()
            .replaceAll(" ", "-")}/${item.question
            .toLowerCase()
            .replaceAll(" ", "-")}`,
        })
        .then(() => console.log("Shared successfully"))
        .catch((error) => console.error("Error sharing:", error));
    } else {
      alert("Your browser does not support sharing.");
    }
  };

  const handleDrop = (index: any) => {
    setShow((prev) => (prev === index ? null : index));
  };

  const handlePrintPreview = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    window.print();
  };

  return (
    <div className={Style.container}>
      <div className={Style.breadcrumb}>
        <a href="/question">Question</a> <span>&gt;</span>{" "}
        <a href="/question/subject">Subject</a> <span>&gt;</span>{" "}
        <span>UnAnswered</span>
      </div>
      <div className={Style.title}>
        <h2>Answerable</h2>
        <p>Questions that need your answers!</p>
      </div>
      <div className={Style.content}>
        {data.map((item: any, index: number) => (
          <div className={Style.detail} key={index}>
            <div className={Style.category}>
              <a
                href={`/question/category/${item.category
                  .toLowerCase()
                  .replaceAll(" ", "-")}`}
              >
                {item.category}
              </a>
              <h2 onClick={() => handleDrop(index)}>
                <i className="fa-solid fa-ellipsis"></i>
                <div
                  className={`${Style.drop} ${
                    show === index ? Style.show : ""
                  }`}
                  ref={dropdownRef}
                >
                  <a href="/login">
                    <i className="fa-regular fa-flag"></i>
                    Report
                  </a>
                  <a
                    href="#/"
                    onClick={(e) => {
                      e.preventDefault();
                      handleShare(item);
                    }}
                  >
                    <i className="fa-regular fa-share-from-square"></i>
                    Share
                  </a>
                  <a href="#/" onClick={handlePrintPreview}>
                    <i className="fa-solid fa-print"></i>
                    Print
                  </a>
                </div>
              </h2>
            </div>
            <div className={Style.question}>
              <a
                href={`/question/${item.category
                  .toLowerCase()
                  .replaceAll(" ", "-")}/${item.question
                  .toLowerCase()
                  .replaceAll(" ", "-")}`}
              >
                {item.question}
              </a>
            </div>
            <div className={Style.user}>
              <h2>
                <img
                  src="https://lh3.googleusercontent.com/a/ACg8ocIYRM11Cyov1f-pnUkwNZh7sZbzsJJacuXnSYMF-w_XNGnM=s20-c"
                  alt="Content"
                  loading="lazy"
                />
                <span>Asked by</span>
                <strong>{item.posted_by}</strong>
              </h2>
            </div>
            <div className={Style.Button}>
              <button>
                <img
                  src="https://www.answers.com/icons/createIcon.svg"
                  alt="Icon"
                  loading="lazy"
                />
                Answer
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UnAnswered;
