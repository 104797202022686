import React from "react";
import Style from "../style/question.module.scss";
import { useNavigate } from "react-router-dom";

const Question = () => {
  const navigate = useNavigate();

  const handleClick = (e: any) => {
    e.preventDefault();
    navigate("/ask");
    document.body.style.overflow = "hidden";
  };

  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.content}>
          <div className={Style.text}>
            <h2>
              Questions & Answers – Find What
              <br /> You Need, Instantly!
            </h2>
            <h3>How can I update my business listing?</h3>
            <h4>Is it free to manage my business listing?</h4>
            <h4>How long does it take for my updates to reflect?</h4>
            <h4>Why is it important to keep my listing updated?</h4>
          </div>
          <div className={Style.question}>
            <span>Ask questions to the YP community</span>
            <span>Share your knowledge to help out others</span>
            <span>Find answers or offer solutions</span>
          </div>
          <div className={Style.button}>
            <a href="#/" onClick={handleClick}>
              Ask a Question
            </a>
          </div>
          <div className={Style.Image}>
            <span>
              <img
                src="https://storage.googleapis.com/website-production/uploads/2023/04/how-to-work-with-difficult-clients.webp"
                alt="Client"
                loading="lazy"
              />
            </span>
          </div>
          <div className={Style.category}>
            <h2>Browse Popular Questions & Answers</h2>
            <ul>
              <li>
                <a href="#/">Dentists</a>
              </li>
              <li>
                <a href="#/">Family Law</a>
              </li>
              <li>
                <a href="#/">Insurance</a>
              </li>
              <li>
                <a href="#/">Auto Repair</a>
              </li>
              <li>
                <a href="#/">Home Improvement</a>
              </li>
              <li>
                <a href="#/">Dogs</a>
              </li>
              <li>
                <a href="#/">Plumbing</a>
              </li>
              <li>
                <a href="#/">Restaurant</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question;
