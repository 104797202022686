import React, { useState } from "react";
import styles from "../style/faq.module.scss";

const faqData = [
  {
    category: "General",
    questions: [
      {
        question: "What services do you offer?",
        answer:
          "We provide web development, app development, and digital marketing services to help businesses grow.",
      },
      {
        question: "Where are you located?",
        answer:
          "Our headquarters are in New Delhi, India, but we serve clients globally.",
      },
      {
        question: "Do you offer custom solutions for businesses?",
        answer:
          "Yes, we provide tailored solutions to meet the specific needs of businesses across industries.",
      },
      {
        question: "How can I get a project estimate?",
        answer:
          "You can request a free consultation through our 'Contact Us' page to get a customized project estimate.",
      },
    ],
  },
  {
    category: "Billing & Payments",
    questions: [
      {
        question: "What payment methods do you accept?",
        answer:
          "We accept credit cards, PayPal, and bank transfers for all our services.",
      },
      {
        question: "Can I get an invoice for my purchase?",
        answer:
          "Yes, invoices are generated automatically and sent to your registered email address after the payment is processed.",
      },
      {
        question: "Do you offer refunds?",
        answer:
          "Yes, we offer a 30-day money-back guarantee for new subscriptions. Contact our support team for more details.",
      },
      {
        question: "Is there a trial period for your services?",
        answer:
          "Yes, we offer a 14-day free trial on selected services to help you explore our platform before committing.",
      },
    ],
  },
  {
    category: "Technical Support",
    questions: [
      {
        question: "How do I contact customer support?",
        answer:
          "You can reach out to us via our support email or the 'Contact Us' page for any technical assistance.",
      },
      {
        question: "Do you provide 24/7 support?",
        answer:
          "Yes, our technical support team is available 24/7 to address any urgent queries.",
      },
      {
        question: "Do you offer priority support for enterprise clients?",
        answer:
          "Yes, enterprise clients receive priority support with a dedicated account manager and faster response times.",
      },
      {
        question: "Where can I find troubleshooting guides?",
        answer:
          "You can access our detailed troubleshooting guides and FAQs in the 'Help Center' section of our website.",
      },
    ],
  },
  {
    category: "Account & Security",
    questions: [
      {
        question: "How do I reset my password?",
        answer:
          "You can reset your password by clicking on 'Forgot Password' on the login page and following the instructions.",
      },
      {
        question: "How do I update my account details?",
        answer:
          "Log in to your account and navigate to the settings page where you can update your details.",
      },
      {
        question: "Is my data secure?",
        answer:
          "Yes, we use industry-standard encryption and security measures to ensure your data remains safe and secure.",
      },
      {
        question: "How do I enable two-factor authentication (2FA)?",
        answer:
          "Go to 'Account Settings' > 'Security' and follow the instructions to enable two-factor authentication for added security.",
      },
    ],
  },
  {
    category: "API & Integration",
    questions: [
      {
        question: "Do you provide API documentation?",
        answer:
          "Yes, detailed API documentation is available on our 'Developers' page for seamless integration.",
      },
      {
        question: "Is there a limit on API usage?",
        answer:
          "Our standard plans include API limits. For high-volume requests, custom API plans are available.",
      },
      {
        question: "How can I integrate your API with my platform?",
        answer:
          "Our API documentation provides step-by-step instructions to help you integrate the API with your platform.",
      },
      {
        question: "Do you offer webhook support?",
        answer:
          "Yes, we support webhooks to send real-time updates to your platform based on specific events.",
      },
    ],
  },
  {
    category: "Training & Resources",
    questions: [
      {
        question: "Do you offer training for new users?",
        answer:
          "Yes, we offer onboarding sessions and webinars to help new users get familiar with our platform.",
      },
      {
        question: "Where can I find tutorials and guides?",
        answer:
          "Visit our 'Help Center' to explore a variety of tutorials, guides, and videos to enhance your knowledge.",
      },
      {
        question: "Do you provide certification after training?",
        answer:
          "Yes, we provide certification upon completion of advanced training programs for enterprise clients.",
      },
      {
        question: "Are the training sessions free?",
        answer:
          "Basic training is included with your subscription, while advanced sessions may incur additional charges.",
      },
    ],
  },
  {
    category: "Partnerships & Affiliates",
    questions: [
      {
        question: "Do you have a referral program?",
        answer:
          "Yes, we offer a referral program where you can earn rewards for every successful referral.",
      },
      {
        question: "Can I become an affiliate partner?",
        answer:
          "Yes, you can apply to join our affiliate program and earn commissions by promoting our services.",
      },
      {
        question: "Do you offer white-label solutions?",
        answer:
          "Yes, we offer white-label solutions for agencies and resellers looking to rebrand our services.",
      },
      {
        question: "How can I collaborate with your company?",
        answer:
          "If you’re interested in partnering with us, reach out through our 'Partnerships' page for more details.",
      },
    ],
  },
];

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const toggleFAQ = (index: any) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const filteredData = faqData.flatMap((section) =>
    section.questions
      .filter((item) =>
        item.question.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .map((item) => ({ ...item, category: section.category }))
  );

  return (
    <div className={styles.faqContainer}>
      <h2 className={styles.faqTitle}>How Can We Help You?</h2>

      <div className={styles.searchBox}>
        <input
          type="text"
          placeholder="Search for answers..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {searchTerm ? (
        <div className={styles.faqList}>
          {filteredData.length > 0 ? (
            filteredData.map((item, index) => (
              <div
                key={index}
                className={`${styles.faqItem} ${
                  openIndex === index ? styles.open : ""
                }`}
              >
                <div
                  className={styles.faqQuestion}
                  onClick={() => toggleFAQ(index)}
                >
                  {item.question}
                  <span className={styles.icon}>
                    {openIndex === index ? "−" : "+"}
                  </span>
                </div>
                <div
                  className={`${styles.faqAnswer} ${
                    openIndex === index ? styles.show : ""
                  }`}
                >
                  <p>{item.answer}</p>
                </div>
              </div>
            ))
          ) : (
            <p className={styles.noResult}>No results found!</p>
          )}
        </div>
      ) : (
        faqData.map((section, sectionIndex) => (
          <div key={sectionIndex} className={styles.faqSection}>
            <h3 className={styles.categoryTitle}>{section.category}</h3>
            {section.questions.map((item, index) => {
              const globalIndex = sectionIndex * 10 + index;
              return (
                <div
                  key={globalIndex}
                  className={`${styles.faqItem} ${
                    openIndex === globalIndex ? styles.open : ""
                  }`}
                >
                  <div
                    className={styles.faqQuestion}
                    onClick={() => toggleFAQ(globalIndex)}
                  >
                    {item.question}
                    <span className={styles.icon}>
                      {openIndex === globalIndex ? "−" : "+"}
                    </span>
                  </div>
                  <div
                    className={`${styles.faqAnswer} ${
                      openIndex === globalIndex ? styles.show : ""
                    }`}
                  >
                    <p>{item.answer}</p>
                  </div>
                </div>
              );
            })}
          </div>
        ))
      )}
    </div>
  );
};

export default FAQ;
