import React from "react";
import Style from "../style/aboutcore.module.scss";

const AboutCore = () => {
  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.title}>
          <h2>Why Choose Us?</h2>
        </div>
        <div className={Style.mainbox}>
          <div className={Style.Image}>
            <img
              src="https://tiimg.tistatic.com/new_website1/design2022/ti-about/trust.jpg"
              alt="Trust"
              loading="lazy"
            />
          </div>
          <div className={Style.content}>
            <h2>Free Listing</h2>
            <p>
              Free Business Listing get discovered by thousands of potential
              customers.
            </p>
            <ul>
              <li>
                Verified Listings & Trust Badge – Enhance credibility and gain
                customer confidence.
              </li>
              <li>
                Advanced Marketing Tools – Utilize smart lead management,
                competitor analysis, and targeted ads.
              </li>{" "}
              <li>
                {" "}
                User-Friendly Platform – Easily update business details, respond
                to reviews, and track performance.
              </li>
            </ul>
          </div>
        </div>
        <div className={Style.mainbox}>
          <div className={Style.content}>
            <h2>Building Customer Trust</h2>
            <p>
              We believe that customer trust is the foundation of every
              successful business. That’s why we encourage businesses to engage
              with their audience, respond to queries, and maintain
              transparency. A strong online presence not only improves
              credibility but also fosters long-term customer relationships.
            </p>
          </div>
          <div className={Style.Image}>
            <img
              src="https://tiimg.tistatic.com/new_website1/design2022/ti-about/constant-learning.jpg"
              alt="Constant"
              loading="lazy"
            />
          </div>
        </div>
        <div className={Style.mainbox}>
          <div className={Style.Image}>
            <img
              src="https://tiimg.tistatic.com/new_website1/design2022/ti-about/ownership.jpg"
              alt="OwnerShip"
              loading="lazy"
            />
          </div>
          <div className={Style.content}>
            <h2>Our Commitment</h2>
            <p>
              We are dedicated to supporting businesses of all kinds. Whether
              you're a startup, a small business, or a large enterprise, we
              provide the right tools and insights to help you grow. Our
              platform continues to evolve with innovative features to ensure
              businesses stay ahead in an ever-changing digital world.
            </p>
          </div>
        </div>
        <div className={Style.mainbox}>
          <div className={Style.content}>
            <h2>Drive and Deliver</h2>
            <p>
              We believe that having the determination to push boundaries and
              the dedication to turn ambitions into reality are vital components
              of success. Every member of our team is driven by a relentless
              pursuit of our goals, fueled by a passion for innovation and a
              sense of purpose.
            </p>
          </div>
          <div className={Style.Image}>
            <img
              src="https://tiimg.tistatic.com/new_website1/design2022/ti-about/drive-deliver.jpg"
              alt="Drive"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCore;
