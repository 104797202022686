import React, { useEffect, useState } from "react";
import Style from "../style/subject.module.scss";

const Subject = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("/data/questions.json")
      .then((res) => res.json())
      .then((value) => {
        const uniqueSubjects: any = Array.from(
          new Map(
            value
              .map((item: any) => [item.subject, item])
              .sort((a: any, b: any) => b[1].likes - a[1].likes)
          ).values()
        ).slice(0, 16);
        setData(uniqueSubjects);
      });
  }, []);
  return (
    <div className={Style.container}>
      <div className={Style.Image}>
        <img
          src="https://www.answers.com/icons/subjectHeader.svg"
          alt="Subject"
          loading="lazy"
        />
      </div>
      <div className={Style.header}>
        <h1>💯 Subjects</h1>
        <p>
          Dive deeper into all of our education subjects and learn, study, and
          connect in a safe and welcoming online community.
        </p>
      </div>
      <div className={Style.content}>
        {data.slice(0, 20).map((item: any) => {
          return (
            <a
              href={`/question/subject/${item.subject
                .toLowerCase()
                .replaceAll(" ", "-")}`}
            >
              <span>{item.subject}</span>
              <p>{item.description.subject}</p>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Subject;
