import React, { useEffect, useState } from "react";
import { fetchCategory, fetchStates } from "../redux/Slice";
import { RootState, AppDispatch } from "../redux/store";
import Style from "../style/sitemap.module.scss";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getCategoriesFromDB, saveCategoriesToDB } from "../indexDb/categoryDb";
import { getStatesFromDB, saveStatesToDB } from "../indexDb/stateDb";

const Sitemap: React.FC = () => {
  const dispatch = useAppDispatch<AppDispatch>();

  const { categoryData, stateData } = useAppSelector(
    (state: RootState) => state.detail
  );

  const [localCategories, setLocalCategories] = useState<any[]>([]);
  const [localStates, setLocalStates] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const categoriesFromDB: any = await getCategoriesFromDB();
      const statesFromDB = await getStatesFromDB();

      if (categoriesFromDB.length > 0) {
        setLocalCategories(categoriesFromDB);
      } else {
        dispatch(fetchCategory()).then((result) => {
          if (result.payload?.categories) {
            saveCategoriesToDB(result.payload.categories);
            setLocalCategories(result.payload.categories);
          }
        });
      }

      if (statesFromDB.length > 0) {
        setLocalStates(statesFromDB);
      } else {
        dispatch(fetchStates()).then((result) => {
          if (result.payload?.states) {
            saveStatesToDB(result.payload.states);
            setLocalStates(result.payload.states);
          }
        });
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <div className={Style.sitemapdata}>
      <div className={Style.wrapper}>
        <div className={Style.headdata}>
          <a href="/">Home</a>
          <span>/</span>
          <p>Site Map</p>
        </div>

        <div className={Style.bannerdata}>
          <img
            src="https://plus.unsplash.com/premium_photo-1661964071015-d97428970584?fm=jpg&q=60&w=3000"
            alt="Sitemap Banner"
            loading="lazy"
          />
        </div>

        <div className={Style.section}>
          <h2>All Categories & Subcategories</h2>
          {(localCategories.length > 0
            ? localCategories
            : categoryData?.categories
          )?.map((category) => (
            <div key={category.id} className={Style.categoryBox}>
              <h3>{category.name}</h3>
              <ul>
                {category.subcategories &&
                  category.subcategories.map((item: any) => (
                    <li key={item.id}>
                      <a
                        href={`/listing/subcategory/${item.name
                          .toLowerCase()
                          .replaceAll(" ", "-")}`}
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          ))}
        </div>

        <div className={Style.section}>
          <h2>All States & Cities</h2>
          {(localStates.length > 0 ? localStates : stateData?.states)?.map(
            (state) => (
              <div key={state.id} className={Style.stateBox}>
                <h3>{state.name}</h3>
                <ul>
                  {state.cities.map((city: any) => (
                    <li key={city.id}>
                      <a
                        href={`/listing/city/${city.name
                          .toLowerCase()
                          .replaceAll(" ", "-")}`}
                      >
                        {city.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Sitemap;
