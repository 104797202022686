import React from "react";
import Style from "../../style/eventlanding.module.scss";

const EventLanding = () => {
  const data = [
    {
      id: "1",
      title: "Exciting Experience",
      city: "San Francisco, CA",
      date: "16 APR",
      time: "6:00 PM - 10:00 PM",
      location: "San Francisco Convention Center",
      address: "1001 Event Blvd, San Francisco, CA",
      organizers: "Livetunein",
      price: "$11 - $51",
      homepage_image:
        "https://images.unsplash.com/photo-1506377247377-2a5b3b417ebb",
      landing_image:
        "https://images.unsplash.com/photo-1472653431158-6364773b2a56",
      languages: ["Telugu", "Hindi"],
      event_types: ["Concert", "Live Music"],
      description:
        "This is a detailed description of Event 1, highlighting all the fun, learning, and cultural experience awaiting attendees.",
      registration_link: "https://example.com/register/event1",
      hashtags: ["#Festival", "#Culture"],
      artist: {
        name: "Artist 1",
        concerts: "(5 Concerts)",
        date_range: "May 10 - May 30",
        cities: "Chicago, IL, Dallas, TX, Austin, TX",
        profile_img:
          "https://images.unsplash.com/photo-1517841905240-472988babdf9",
      },
    },
  ];
  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        {data.map((item) => {
          return (
            <div className={Style.banner}>
              <div className={Style.Image}>
                <img src={item.landing_image} alt={item.time} />
              </div>
              <div className={Style.text}>
                <div className={Style.leftContent}>
                  <div className={Style.eventDetail}>
                    <div className={Style.eventType}>
                      {item.event_types.map((item) => {
                        return <a href="#/">{item}</a>;
                      })}
                    </div>
                    <div className={Style.eventLanguage}>
                      {item.languages.map((item) => {
                        return <a href="#/">{item}</a>;
                      })}
                    </div>
                  </div>
                  <div className={Style.title}>
                    <h2>{item.title}</h2>
                  </div>
                  <div className={Style.timer}>
                    <ul>
                      <li>
                        <i className="fa-solid fa-clock"></i>
                        {item.time}
                      </li>
                      <li>
                        <i className="fa-solid fa-location-dot"></i>
                        {item.location}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={Style.rightContent}>
                  <button>Buy Tickets</button>
                  <h2>Ticket Range : {item.price}</h2>
                </div>
              </div>
              <div className={Style.feature}>
                <ul>
                  <li>
                    <i className="fa-solid fa-heart"></i>
                    <span>Shortlist</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-calendar-days"></i>
                    <span> Add to Calendar</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-share-nodes"></i>
                    <span>Share</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-flag"></i>
                    <span>Report</span>
                  </li>
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EventLanding;
