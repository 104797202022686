import React, { useEffect, useState } from "react";
import Style from "../style/FilterProduct.module.scss";
import { useAppDispatch } from "../redux/hooks";
import { fetchListing } from "../redux/Slice";
import Article from "../component/Article";
import Question from "../component/Question";
import SeachResultOverlay from "../component/SeachResultOverlay";
import ProductBanner from "./ProductBanner";
import ProductDivide from "./ProductDivide";
import ProductListingData from "./ProductListingData";
import ProductRightContent from "./ProductRightContent";
import { useNavigate, useParams } from "react-router-dom";
import { getProductsFromDB, saveProductsToDB } from "../indexDb/productDb";

const ProductListing = () => {
  const { userCategory, userCity } = useParams();
  const dispatch = useAppDispatch();
  const [overlay, setOverlay] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [localListings, setLocalListings] = useState<any[]>([]);
  const itemsPerPage = 9;

  const navigate = useNavigate();

  useEffect(() => {
    if (userCategory && userCategory === "real-estate") {
      navigate("/listing/real-estate", { replace: true });
    }
  }, [userCategory, navigate]);

  useEffect(() => {
    const loadData = async () => {
      const localProducts: any = await getProductsFromDB();
      if (localProducts.length > 0) {
        setLocalListings(localProducts);
        dispatch(fetchListing(localProducts));
      }
      if (localProducts.length === 0) {
        try {
          const apiData = await dispatch(fetchListing()).unwrap();
          if (apiData?.listings?.length > 0) {
            setLocalListings(apiData.listings);
            await saveProductsToDB(apiData.listings);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    loadData();
  }, [dispatch]);

  const categoryParam = userCategory?.toLowerCase().replaceAll(" ", "-");
  const cityParam = userCity?.toLowerCase().replaceAll(" ", "-");
  const filterData: any = localListings?.filter((item) => {
    const categoryMatch =
      categoryParam &&
      item.category_name?.toLowerCase().replaceAll(" ", "-") === categoryParam;
    const cityMatch =
      cityParam &&
      item.city_name?.toLowerCase().replaceAll(" ", "-") === cityParam;

    if (categoryParam && cityParam) {
      return categoryMatch && cityMatch;
    }
    if (categoryParam) {
      return categoryMatch;
    }
    if (cityParam) {
      return cityMatch;
    }

    return true;
  });

  const listings = filterData && filterData.length > 0 ? filterData : [];
  const currentItems = listings.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber: number) => {
    if (
      pageNumber >= 1 &&
      pageNumber <= Math.ceil(listings.length / itemsPerPage)
    ) {
      setCurrentPage(pageNumber);
    }
  };

  const totalPages = Math.ceil(listings.length / itemsPerPage);

  return (
    <div className={Style.container}>
      {overlay && <SeachResultOverlay handleClick={() => setOverlay(false)} />}
      <div className={Style.wrapper}>
        <div className={Style.mainbox}>
          <div className={Style.breadcrumb}>
            <a href="/">Home</a>
            <i className="fa-solid fa-angle-right"></i>
            <a href="/all-items">All Items</a>

            {userCategory && (
              <>
                <i className="fa-solid fa-angle-right"></i>
                <a href={`/all-items/${categoryParam}`}>
                  {userCategory
                    .replaceAll("-", " ")
                    .replace(/\b\w/g, (char) => char.toUpperCase())}
                </a>
              </>
            )}
            {userCity && (
              <>
                <i className="fa-solid fa-angle-right"></i>
                <span>
                  {userCity
                    .replaceAll("-", " ")
                    .replace(/\b\w/g, (char) => char.toUpperCase())}
                </span>
              </>
            )}
          </div>
          <div className={Style.title}>
            <h2>
              {userCategory
                ?.replaceAll("-", " ")
                .replace(/\b\w/g, (char) => char.toUpperCase())}
            </h2>
          </div>
          <div className={Style.Main}>
            <div className={Style.leftAside}>
              <ProductDivide />
              <ProductBanner />
              <div className={Style.listItem}>
                {currentItems && currentItems.length > 0 ? (
                  currentItems.map((item: any, index: number) => (
                    <ProductListingData
                      key={item.id || index}
                      itemList={item}
                      index={index}
                    />
                  ))
                ) : (
                  <div className={Style.noData}>No Products Found</div>
                )}
              </div>
              <div className={Style.paginationButton}>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <button onClick={() => handlePageChange(1)}>1</button>
                {currentPage > 3 && <span>...</span>}
                {currentPage > 2 && (
                  <button onClick={() => handlePageChange(currentPage - 1)}>
                    {currentPage - 1}
                  </button>
                )}
                {currentPage !== 1 && currentPage !== totalPages && (
                  <button className={Style.active}>{currentPage}</button>
                )}
                {currentPage < totalPages - 1 && (
                  <button onClick={() => handlePageChange(currentPage + 1)}>
                    {currentPage + 1}
                  </button>
                )}
                {currentPage < totalPages - 2 && <span>...</span>}
                {totalPages > 1 && (
                  <button onClick={() => handlePageChange(totalPages)}>
                    {totalPages}
                  </button>
                )}

                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
            <div className={Style.rightAside}>
              <ProductRightContent handleOverlay={() => setOverlay(true)} />
            </div>
          </div>
          <Article />
          <Question />
        </div>
      </div>
    </div>
  );
};

export default ProductListing;
