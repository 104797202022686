import React, { useState } from "react";
import Style from "../../../style/relatorcontactform.module.scss";

type RelatorContactFormProps = {
  itemList: any;
  handleRelatorForm?: () => void;
  layout?: "popup" | "sidebar" | "rightside";
  showExtraField?: boolean;
};

const RelatorContactForm = ({
  itemList,
  handleRelatorForm,
  layout = "popup",
  showExtraField = false,
}: RelatorContactFormProps) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
    contactTime: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
    description: false,
  });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: false }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors: any = {};
    ["name", "email", "phone", "description"].forEach((field) => {
      if (!formData[field as keyof typeof formData].trim()) {
        validationErrors[field] = true;
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    console.log("Form submitted successfully:", formData);
  };

  return (
    <div className={layout === "popup" ? Style.overlay : Style.sidebarWrapper}>
      <div className={Style.wrapper}>
        {" "}
        {layout === "popup" ? (
          <>
            <div className={Style.header}>
              <h2>Get notified for new homes</h2>
              <h3 onClick={handleRelatorForm} className={Style.closeBtn}>
                X
              </h3>
            </div>
            <div className={Style.detail}>
              <span>
                <img src={itemList.img} alt={itemList.name} />
              </span>
              <div className={Style.data}>
                <h2>{itemList.name}</h2>
                <h3>{itemList.address}</h3>
              </div>
            </div>
            <div className={Style.formBox}>
              <form onSubmit={handleSubmit}>
                <div className={Style.divide}>
                  <div className={Style.leftAside}>
                    <div className={Style.box}>
                      <label className={errors.name ? Style.errorLabel : ""}>
                        Your Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className={Style.box}>
                      <label className={errors.email ? Style.errorLabel : ""}>
                        Your Email Address <span>*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className={Style.box}>
                      <label className={errors.phone ? Style.errorLabel : ""}>
                        Contact Number <span>*</span>
                      </label>
                      <input
                        type="number"
                        name="phone"
                        placeholder="Your Number"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className={Style.rightAside}>
                    {showExtraField && (
                      <div className={Style.box}>
                        <label>Preferred Contact Time</label>
                        <input
                          type="text"
                          name="contactTime"
                          placeholder="e.g. Morning or Evening"
                          value={formData.contactTime}
                          onChange={handleChange}
                        />
                      </div>
                    )}
                    <div className={Style.box}>
                      <label
                        className={errors.description ? Style.errorLabel : ""}
                      >
                        Enter a description <span>*</span>
                      </label>
                      <textarea
                        name="description"
                        placeholder="Description"
                        value={formData.description}
                        onChange={handleChange}
                        className={Style.textarea}
                      />
                    </div>
                  </div>
                </div>
                <div className={Style.bottom}>
                  <div className={Style.button}>
                    <button type="submit">Submit</button>
                    <button type="button" onClick={handleRelatorForm}>
                      Cancel
                    </button>
                  </div>
                  <p>
                    By Sending, you acknowledge that you have read and agree
                    with the <a href="/">Bipko.info</a>{" "}
                    <a href="/terms">Terms of Service</a> and{" "}
                    <a href="/privacy-policy">Privacy Policy</a> and Cookie
                    Policy.
                  </p>
                </div>
              </form>
            </div>
          </>
        ) : layout === "sidebar" ? (
          <div className={Style.contactForm}>
            <h2>Contact Agent</h2>
            <div className={Style.formBox}>
              <form onSubmit={handleSubmit}>
                <div className={Style.divide}>
                  <div className={Style.leftAside}>
                    <div className={Style.box}>
                      <label className={errors.name ? Style.errorLabel : ""}>
                        your Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className={Style.box}>
                      <label className={errors.email ? Style.errorLabel : ""}>
                        your Email Address <span>*</span>
                      </label>
                      <input
                        type="text"
                        name="email"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className={Style.box}>
                      <label className={errors.phone ? Style.errorLabel : ""}>
                        Contact Number <span>*</span>
                      </label>
                      <input
                        type="number"
                        name="phone"
                        placeholder="Your Number"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className={Style.rightAside}>
                    <div className={Style.box}>
                      <label
                        className={errors.description ? Style.errorLabel : ""}
                      >
                        Enter a description <span>*</span>
                      </label>
                      <textarea
                        name="description"
                        placeholder="Description"
                        value={formData.description}
                        onChange={handleChange}
                        className={Style.textarea}
                      />
                    </div>
                  </div>
                </div>
                <div className={Style.bottom}>
                  <div className={Style.button}>
                    <button type="submit">Contact Agent</button>
                  </div>
                  <p>
                    By Sending, you acknowledge that you have read and agree
                    with the <a href="/">Bipko.info</a>{" "}
                    <a href="/terms">Terms of Service,</a>{" "}
                    <a href="/privacy-policy">Privacy Policy</a> and Cookie
                    Policy.
                  </p>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className={Style.contactRightForm}>
            <h2>Contact Agent</h2>
            <div className={Style.formBox}>
              <form onSubmit={handleSubmit}>
                <div className={Style.divide}>
                  <div className={Style.box}>
                    <label className={errors.name ? Style.errorLabel : ""}>
                      your Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={Style.box}>
                    <label className={errors.email ? Style.errorLabel : ""}>
                      your Email Address <span>*</span>
                    </label>
                    <input
                      type="text"
                      name="email"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={Style.box}>
                    <label className={errors.phone ? Style.errorLabel : ""}>
                      Contact Number <span>*</span>
                    </label>
                    <input
                      type="number"
                      name="phone"
                      placeholder="Your Number"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>

                  <div className={Style.box}>
                    <label
                      className={errors.description ? Style.errorLabel : ""}
                    >
                      Enter a description <span>*</span>
                    </label>
                    <textarea
                      name="description"
                      placeholder="Description"
                      value={formData.description}
                      onChange={handleChange}
                      className={Style.textarea}
                    />
                  </div>
                </div>
                <div className={Style.bottom}>
                  <div className={Style.button}>
                    <button type="submit">Contact Agent</button>
                  </div>
                  <p>
                    By Sending, you acknowledge that you have read and agree
                    with the <a href="/">Bipko.info</a>{" "}
                    <a href="/terms">Terms of Service,</a>{" "}
                    <a href="/privacy-policy">Privacy Policy</a> and Cookie
                    Policy.
                  </p>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RelatorContactForm;
