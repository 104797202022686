import React, { useEffect, useState } from "react";
import Style from "../style/content.module.scss";
import { useAppDispatch } from "../redux/hooks";
import { fetchCategory } from "../redux/Slice";
import { getCategoriesFromDB, saveCategoriesToDB } from "../indexDb/categoryDb";
import categoriesData from "../json/category.json";

interface Category {
  id: number;
  name: string;
  slug: string;
  icon: string;
}

const Content = React.memo(() => {
  const dispatch = useAppDispatch();
  const [categories, setCategories] = useState<Category[]>(categoriesData);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = async () => {
    try {
      const cachedCategories = await getCategoriesFromDB();

      if (cachedCategories.length > 0) {
        setCategories(cachedCategories.slice(0, 10));
      } else {
        setCategories(categoriesData.slice(0, 10));
        await saveCategoriesToDB(categoriesData);
      }
      const response = await dispatch(fetchCategory()).unwrap();

      if (response?.categories) {
        const newCategories = response.categories;
        if (
          JSON.stringify(cachedCategories) !== JSON.stringify(newCategories)
        ) {
          setCategories(newCategories.slice(0, 10));
          await saveCategoriesToDB(newCategories);
        }
      }
    } catch (error) {
      console.error("Error loading categories", error);
      setError("Error loading categories");
    }
  };

  return (
    <div className={Style.Content}>
      <div className={Style.wrapper}>
        <div className={Style.Location}>
          {error ? (
            <div className={Style.error}>{error}</div>
          ) : categories.length > 0 ? (
            categories.slice(0, 10).map((item: Category) => (
              <a
                key={item.id}
                href={`/listing/category/${item.slug}`}
                className={Style.categoryLink}
              >
                <span>
                  <i className={item.icon}></i>
                </span>
                <h2>{item.name}</h2>
              </a>
            ))
          ) : (
            <div className={Style.error}>No Categories Found</div>
          )}
        </div>
        <div
          className={Style.button}
          onClick={() => window.location.assign("/category")}
        >
          <button>
            <span>All Categories</span>
          </button>
        </div>
      </div>
    </div>
  );
});

export default Content;
