import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./hooks";

const SliceData = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.detail);

  useEffect(() => {}, [dispatch]);
  console.log(data);

  return <div></div>;
};

export default SliceData;
