import React from "react";
import Style from "../../style/relators.module.scss";
import { useState } from "react";
import RealtorLeftSide from "./RealtorLeftSide";
import RelatorRightSide from "./RelatorsRightSide";
import NotificationBanner from "./NotificationBanner";
import Article from "../../component/Article";
import Question from "../../component/Question";
import NotificationForm from "./Form/NotificationForm";
import RelatorContactForm from "./Form/RelatorContactForm";

const Relators = () => {
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [notification, setNotification] = useState(false);
  const [contact, setContact] = useState(false);
  const [agent, setAgent] = useState("");

  const handleToggleAdvanced = () => {
    setShowAdvanced(!showAdvanced);
  };

  const data = [
    {
      id: 1,
      name: "John Doe",
      location: "New York, NY, USA",
      address: "123 Main Street, New York, NY 10001",
      service_areas: ["Manhattan", "Brooklyn", "Queens"],
      img: "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSpO4Z4ZYoBnCzzrTdRT18KNoNEkTgdASFJewjnseBYJWBbHqFZ",
      properties_listed: 25,
      price_range: "$200,000 - $5,000,000",
      experience: "5 - 10 years",
      description:
        "John Doe is a seasoned real estate expert specializing in luxury properties and tech industry relocations. With over 15 years of experience, he has helped countless clients find their dream homes in Silicon Valley and beyond. His expertise in high-end properties and deep understanding of the local market make him a top choice for buyers and sellers alike.",
      properties: {
        for_sale: 15,
        for_rent: 10,
        property_types: ["Apartments", "Villas", "Condos", "Commercial"],
      },
      established_since: 2010,
      specialization: ["Luxury homes", "First-time home buyers"],
      languages_known: ["English", "Spanish"],
      services_offered: ["Buyer’s Agent", "Seller’s Agent"],
      contact: {
        phone: "+1 234 567 890",
        email: "johndoe@example.com",
        website: "https://johndoeproperties.com",
      },
    },
    {
      id: 2,
      name: "Emma Smith",
      location: "Los Angeles, CA, USA",
      service_areas: ["Beverly Hills", "Hollywood", "Downtown LA"],
      img: "https://usimg.sulekha.io/cdn/localservices/images/thumbnail/localservices_2024-02-26-07-17-02-189_80.jpg",
      properties_listed: 30,
      price_range: "$300,000 - $10,000,000",
      address: "123 Main Street, New York, NY 10001",
      experience: "10 - 15years",
      description:
        "Emma Smith is a seasoned real estate expert specializing in luxury properties and tech industry relocations. With over 15 years of experience, he has helped countless clients find their dream homes in Silicon Valley and beyond. His expertise in high-end properties and deep understanding of the local market make him a top choice for buyers and sellers alike.",
      properties: {
        for_sale: 20,
        for_rent: 10,
        property_types: ["Mansions", "Apartments", "Luxury Villas"],
      },
      established_since: 2015,
      specialization: ["Luxury homes", "Investment properties"],
      languages_known: ["English", "French"],
      services_offered: [
        "Buyer’s Agent",
        "Seller’s Agent",
        "Relocation Services",
      ],
      contact: {
        phone: "+1 987 654 321",
        email: "emmasmith@example.com",
        website: "https://emmasmithrealty.com",
      },
    },
    {
      id: 3,
      name: "Michael Johnson",
      location: "Chicago, IL, USA",
      experience: "0 - 10years",
      service_areas: ["Downtown", "Lincoln Park", "Hyde Park"],
      img: "https://usimg.sulekha.io/cdn/nriproperty/images/thumbnail/nriproperty_2019-11-22-12-17-44-617_649.jpeg",
      properties_listed: 18,
      price_range: "$150,000 - $4,500,000",
      address: "123 Main Street, New York, NY 10001",
      description:
        "Michael Johnson is a seasoned real estate expert specializing in luxury properties and tech industry relocations. With over 15 years of experience, he has helped countless clients find their dream homes in Silicon Valley and beyond. His expertise in high-end properties and deep understanding of the local market make him a top choice for buyers and sellers alike.",
      properties: {
        for_sale: 10,
        for_rent: 8,
        property_types: ["Condos", "Townhouses", "Commercial Spaces"],
      },
      established_since: 2008,
      specialization: ["Foreclosed properties", "Luxury homes"],
      languages_known: ["English"],
      services_offered: ["Buyer’s Agent", "Foreclosed Property Deals"],
      contact: {
        phone: "+1 312 555 7890",
        email: "michaelj@example.com",
        website: "https://michaeljohnsonrealty.com",
      },
    },
    {
      id: 4,
      name: "Sophia Martinez",
      location: "Miami, FL, USA",
      service_areas: ["Miami Beach", "Downtown", "Coconut Grove"],
      img: "https://usimg.sulekha.io/cdn/localservices/images/thumbnail/localservices_2024-05-24-05-46-10-631_10.jpg",
      properties_listed: 40,
      price_range: "$500,000 - $8,000,000",
      address: "123 Main Street, New York, NY 10001",
      experience: " 0 - 5years",
      description:
        "Sophia Martinez is a seasoned real estate expert specializing in luxury properties and tech industry relocations. With over 15 years of experience, he has helped countless clients find their dream homes in Silicon Valley and beyond. His expertise in high-end properties and deep understanding of the local market make him a top choice for buyers and sellers alike.",
      properties: {
        for_sale: 30,
        for_rent: 10,
        property_types: ["Luxury Condos", "Beachfront Homes"],
      },
      established_since: 2012,
      specialization: ["Luxury homes", "Beachfront properties"],
      languages_known: ["English", "Spanish"],
      services_offered: ["Luxury Properties", "Relocation Services"],
      contact: {
        phone: "+1 786 555 9876",
        email: "sophia@example.com",
        website: "https://sophiamartinezrealty.com",
      },
    },
    {
      id: 5,
      name: "David Brown",
      location: "San Francisco, CA, USA",
      experience: "10 - 20years",
      service_areas: ["Downtown", "Palo Alto", "Silicon Valley"],
      img: "https://homes.sulekha.com/common/images/default/no-img.png",
      properties_listed: 35,
      price_range: "$700,000 - $12,000,000",
      address: "123 Main Street, New York, NY 10001",
      description:
        "David Brown is a seasoned real estate expert specializing in luxury properties and tech industry relocations. With over 15 years of experience, he has helped countless clients find their dream homes in Silicon Valley and beyond. His expertise in high-end properties and deep understanding of the local market make him a top choice for buyers and sellers alike.",
      properties: {
        for_sale: 25,
        for_rent: 10,
        property_types: ["Tech Homes", "Luxury Villas", "Penthouses"],
      },
      established_since: 2005,
      specialization: ["Tech industry relocations", "Luxury homes"],
      languages_known: ["English", "Mandarin"],
      services_offered: ["Buyer’s Agent", "New Construction Homes"],
      contact: {
        phone: "+1 415 555 3344",
        email: "davidbrown@example.com",
        website: "https://davidbrownrealty.com",
      },
    },
    {
      id: 6,
      name: "John Doe",
      location: "New York, NY, USA",
      address: "123 Main Street, New York, NY 10001",
      service_areas: ["Manhattan", "Brooklyn", "Queens"],
      img: "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSpO4Z4ZYoBnCzzrTdRT18KNoNEkTgdASFJewjnseBYJWBbHqFZ",
      properties_listed: 25,
      price_range: "$200,000 - $5,000,000",
      experience: "5 - 10 years",
      description:
        "John Doe is a seasoned real estate expert specializing in luxury properties and tech industry relocations. With over 15 years of experience, he has helped countless clients find their dream homes in Silicon Valley and beyond. His expertise in high-end properties and deep understanding of the local market make him a top choice for buyers and sellers alike.",
      properties: {
        for_sale: 15,
        for_rent: 10,
        property_types: ["Apartments", "Villas", "Condos", "Commercial"],
      },
      established_since: 2010,
      specialization: ["Luxury homes", "First-time home buyers"],
      languages_known: ["English", "Spanish"],
      services_offered: ["Buyer’s Agent", "Seller’s Agent"],
      contact: {
        phone: "+1 234 567 890",
        email: "johndoe@example.com",
        website: "https://johndoeproperties.com",
      },
    },
    {
      id: 7,
      name: "Emma Smith",
      location: "Los Angeles, CA, USA",
      service_areas: ["Beverly Hills", "Hollywood", "Downtown LA"],
      img: "https://usimg.sulekha.io/cdn/localservices/images/thumbnail/localservices_2024-02-26-07-17-02-189_80.jpg",
      properties_listed: 30,
      price_range: "$300,000 - $10,000,000",
      address: "123 Main Street, New York, NY 10001",
      experience: "10 - 15years",
      description:
        "Emma Smith is a seasoned real estate expert specializing in luxury properties and tech industry relocations. With over 15 years of experience, he has helped countless clients find their dream homes in Silicon Valley and beyond. His expertise in high-end properties and deep understanding of the local market make him a top choice for buyers and sellers alike.",
      properties: {
        for_sale: 20,
        for_rent: 10,
        property_types: ["Mansions", "Apartments", "Luxury Villas"],
      },
      established_since: 2015,
      specialization: ["Luxury homes", "Investment properties"],
      languages_known: ["English", "French"],
      services_offered: [
        "Buyer’s Agent",
        "Seller’s Agent",
        "Relocation Services",
      ],
      contact: {
        phone: "+1 987 654 321",
        email: "emmasmith@example.com",
        website: "https://emmasmithrealty.com",
      },
    },
    {
      id: 8,
      name: "Michael Johnson",
      location: "Chicago, IL, USA",
      experience: "0 - 10years",
      service_areas: ["Downtown", "Lincoln Park", "Hyde Park"],
      img: "https://usimg.sulekha.io/cdn/nriproperty/images/thumbnail/nriproperty_2019-11-22-12-17-44-617_649.jpeg",
      properties_listed: 18,
      price_range: "$150,000 - $4,500,000",
      address: "123 Main Street, New York, NY 10001",
      description:
        "Michael Johnson is a seasoned real estate expert specializing in luxury properties and tech industry relocations. With over 15 years of experience, he has helped countless clients find their dream homes in Silicon Valley and beyond. His expertise in high-end properties and deep understanding of the local market make him a top choice for buyers and sellers alike.",
      properties: {
        for_sale: 10,
        for_rent: 8,
        property_types: ["Condos", "Townhouses", "Commercial Spaces"],
      },
      established_since: 2008,
      specialization: ["Foreclosed properties", "Luxury homes"],
      languages_known: ["English"],
      services_offered: ["Buyer’s Agent", "Foreclosed Property Deals"],
      contact: {
        phone: "+1 312 555 7890",
        email: "michaelj@example.com",
        website: "https://michaeljohnsonrealty.com",
      },
    },
    {
      id: 9,
      name: "Sophia Martinez",
      location: "Miami, FL, USA",
      service_areas: ["Miami Beach", "Downtown", "Coconut Grove"],
      img: "https://usimg.sulekha.io/cdn/localservices/images/thumbnail/localservices_2024-05-24-05-46-10-631_10.jpg",
      properties_listed: 40,
      price_range: "$500,000 - $8,000,000",
      address: "123 Main Street, New York, NY 10001",
      experience: " 0 - 5years",
      description:
        "Sophia Martinez is a seasoned real estate expert specializing in luxury properties and tech industry relocations. With over 15 years of experience, he has helped countless clients find their dream homes in Silicon Valley and beyond. His expertise in high-end properties and deep understanding of the local market make him a top choice for buyers and sellers alike.",
      properties: {
        for_sale: 30,
        for_rent: 10,
        property_types: ["Luxury Condos", "Beachfront Homes"],
      },
      established_since: 2012,
      specialization: ["Luxury homes", "Beachfront properties"],
      languages_known: ["English", "Spanish"],
      services_offered: ["Luxury Properties", "Relocation Services"],
      contact: {
        phone: "+1 786 555 9876",
        email: "sophia@example.com",
        website: "https://sophiamartinezrealty.com",
      },
    },
    {
      id: 10,
      name: "David Brown",
      location: "San Francisco, CA, USA",
      experience: "10 - 20years",
      service_areas: ["Downtown", "Palo Alto", "Silicon Valley"],
      img: "https://homes.sulekha.com/common/images/default/no-img.png",
      properties_listed: 35,
      price_range: "$700,000 - $12,000,000",
      address: "123 Main Street, New York, NY 10001",
      description:
        "David Brown is a seasoned real estate expert specializing in luxury properties and tech industry relocations. With over 15 years of experience, he has helped countless clients find their dream homes in Silicon Valley and beyond. His expertise in high-end properties and deep understanding of the local market make him a top choice for buyers and sellers alike.",
      properties: {
        for_sale: 25,
        for_rent: 10,
        property_types: ["Tech Homes", "Luxury Villas", "Penthouses"],
      },
      established_since: 2005,
      specialization: ["Tech industry relocations", "Luxury homes"],
      languages_known: ["English", "Mandarin"],
      services_offered: ["Buyer’s Agent", "New Construction Homes"],
      contact: {
        phone: "+1 415 555 3344",
        email: "davidbrown@example.com",
        website: "https://davidbrownrealty.com",
      },
    },
  ];

  const handleNotification = () => {
    setNotification(true);
    document.documentElement.style.overflow = "hidden";
  };
  const handleNotificationForm = () => {
    setNotification(false);
    document.documentElement.style.overflow = "";
  };
  const handleNotificationCancel = () => {
    setNotification(false);
    document.documentElement.style.overflow = "";
  };

  const handleRelator = (detail: any) => {
    setContact(true);
    setAgent(detail);
    document.documentElement.style.overflow = "hidden";
  };
  const handleRelatorForm = () => {
    setContact(false);
    document.documentElement.style.overflow = "";
  };

  const itemList = data && data.map((item) => item);
  console.log(itemList, "hello");
  return (
    <div className={Style.container}>
      <>
        {notification && (
          <NotificationForm
            handleNotificationForm={handleNotificationForm}
            handleNotificationCancel={handleNotificationCancel}
          />
        )}
      </>
      <>
        {contact && (
          <RelatorContactForm
            itemList={agent}
            handleRelatorForm={handleRelatorForm}
            layout="popup"
          />
        )}
      </>
      <div className={Style.wrapper}>
        <div className={Style.title}>
          <h2>Top Realtors & Real Estate Agents in New York</h2>
          <p>
            Find New York Metro Area Real Estate Agents with 100% Free No Hassle
            Online Match. Get Started! Meet a Top Local Agent to Get Best
            Properties.
          </p>
        </div>
      </div>
      <div className={Style.searchBox}>
        <div className={Style.wrapper}>
          <form>
            <div className={Style.box}>
              <label>Location</label>
              <input type="text" />
            </div>
            <div className={Style.box}>
              <label>Agent Name</label>
              <input type="text" />
            </div>
            <div className={Style.box}>
              <label>Home Type</label>
              <input type="text" />
            </div>
            <div
              className={Style.advancedSearch}
              onClick={handleToggleAdvanced}
            >
              <h2>Advanced Search</h2>
              <span>{showAdvanced ? "▲" : "▼"}</span>
            </div>
            <div className={Style.button}>
              <button type="submit">Search</button>
            </div>
            <div
              className={`${Style.fullForm} ${showAdvanced ? Style.show : ""}`}
            >
              <div className={Style.box}>
                <label>Property Range</label>
                <input type="text" />
              </div>
              <div className={Style.box}>
                <label>Language</label>
                <select>
                  <option>Hindi</option>
                  <option>English</option>
                  <option>Spanish</option>
                  <option>French</option>
                </select>
              </div>
              <div className={Style.box}>
                <label>Specialties</label>
                <input type="text" />
              </div>
              <div className={Style.box}>
                <label>Rating</label>
                <input type="text" />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className={Style.wrapper}>
        <div className={Style.divide}>
          <div className={Style.leftAside}>
            <div className={Style.header}>
              <div className={Style.detail}>
                <h2>Top Rated Real Estate Agents in New York, NY</h2>
                <p>197 Agents near you</p>
              </div>
              <div className={Style.selected}>
                <select>
                  <option>Popularity</option>
                  <option>Distance</option>
                  <option>Activity</option>
                  <option>Top Scores</option>
                  <option>High Rating</option>
                </select>
              </div>
            </div>
            {data &&
              data.map((item) => {
                return (
                  <RealtorLeftSide
                    itemList={item}
                    handleRelator={() => handleRelator(item)}
                  />
                );
              })}
          </div>
          <div className={Style.rightAside}>
            <RelatorRightSide />
          </div>
        </div>
      </div>
      <NotificationBanner handleNotification={handleNotification} />
      <Article />
      <Question />
    </div>
  );
};

export default Relators;
