import React, { useState } from "react";
import Style from "../../style/realstatesearchbox.module.scss";

const propertyTypes = [
  "Apartment",
  "Villa",
  "Independent House",
  "Plot",
  "Office Space",
];
const cities = [
  "New York, NY",
  "Los Angeles, CA",
  "Chicago, IL",
  "Houston, TX",
  "San Francisco, CA",
];

const RealStateSearchBox = () => {
  const [activeTab, setActiveTab] = useState("Buy");
  const [selectedProperty, setSelectedProperty] = useState("Apartment");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.length > 0) {
      const filtered: any = cities.filter((city) =>
        city.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCities(filtered);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const handleSelectCity = (city: any) => {
    setSearchTerm(city);
    setShowDropdown(false);
  };

  return (
    <div className={Style.container}>
      <div className={Style.formbox}>
        <div className={Style.tabs}>
          {["Buy", "Sell", "Agents", "Rent"].map((tab) => (
            <button
              key={tab}
              className={activeTab === tab ? Style.activeTab : ""}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
        <div className={Style.formbar}>
          <form>
            <div className={Style.selectbox}>
              <select
                className={Style.propertyDropdown}
                value={selectedProperty}
                onChange={(e) => setSelectedProperty(e.target.value)}
              >
                {propertyTypes.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
            <div className={Style.box}>
              <input
                type="text"
                placeholder="Search for City, Address, or Neighbourhood"
                value={searchTerm}
                onChange={handleInputChange}
                onFocus={() => searchTerm.length > 0 && setShowDropdown(true)}
              />
              {showDropdown && (
                <ul className={Style.dropdown}>
                  {filteredCities.length > 0 ? (
                    filteredCities.map((city, index) => (
                      <li key={index} onClick={() => handleSelectCity(city)}>
                        <i className="fa-solid fa-location-dot"></i> {city}
                      </li>
                    ))
                  ) : (
                    <li className={Style.noResult}>No results found</li>
                  )}
                </ul>
              )}
            </div>
            <button className={Style.searchButton}>
              <i className="fa-solid fa-magnifying-glass"></i>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RealStateSearchBox;
