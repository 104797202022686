import React, { useEffect, useState } from "react";
import Style from "../../style/eventvenue.module.scss";

const EventVenue = () => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    fetch("/data/event.json")
      .then((res) => res.json())
      .then((res) => setData(res.events));
  }, []);

  const today = new Date();
  const venueStats: {
    [key: string]: { count: number; upcoming: number; sampleItem: any };
  } = {};

  data.forEach((item) => {
    const venueName = item.venue.name;
    const eventDate = new Date(item.start_date);

    if (!venueStats[venueName]) {
      venueStats[venueName] = { count: 0, upcoming: 0, sampleItem: item };
    }

    venueStats[venueName].count += 1;
    if (eventDate >= today) {
      venueStats[venueName].upcoming += 1;
    }
  });

  const uniqueVenues = Object.values(venueStats).slice(0, 6);

  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.title}>
          <h2>Explore Venues</h2>
        </div>
        <div className={Style.mainbox}>
          <ul>
            {uniqueVenues.map(({ sampleItem, count, upcoming }) => {
              const venue = sampleItem.venue;
              return (
                <li key={venue.name}>
                  <a
                    href={`/event/${venue.name
                      .toLowerCase()
                      .replaceAll(" ", "-")}`}
                  >
                    <div className={Style.VenueDetail}>
                      <h2>
                        {venue.name}
                        <i className="fa-solid fa-star-of-life"></i>
                      </h2>
                      <h3>
                        {venue.city} <span>{venue.state}</span>
                      </h3>
                      <h4>
                        {count} Events • {upcoming} Upcoming
                      </h4>
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={Style.button}>
          <a href="#/">View All</a>
        </div>
      </div>
    </div>
  );
};

export default EventVenue;
