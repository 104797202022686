import React, { useEffect, useState } from "react";
import Style from "../style/article.module.scss";
import { useAppDispatch } from "../redux/hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import { fetchBlogs } from "../redux/Slice";
import { addOrUpdateBlogs, getBlogsFromDB } from "../indexDb/blogDb";

type Blog = {
  id: number;
  image: string;
  categoryName: string;
  categorySlug: string;
  title: string;
  slug: string;
  author: string;
  created_at: string;
  content: string;
};

const Article = React.memo(() => {
  const dispatch = useAppDispatch();
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const baseUrl = "https://onedialusa.com/assets/img/blogs/";

  useEffect(() => {
    const fetchAndStoreBlogs = async () => {
      const cachedBlogs: any = await getBlogsFromDB();
      if (cachedBlogs?.length > 0) {
        setBlogs(cachedBlogs);
      } else {
        try {
          const response = await dispatch(fetchBlogs()).unwrap();
          if (response?.blogs?.blogs) {
            await addOrUpdateBlogs(response.blogs.blogs);
            setBlogs(response.blogs.blogs);
          }
        } catch (error) {
          console.error("Error fetching blogs:", error);
        }
      }
    };

    fetchAndStoreBlogs();
  }, [dispatch]);

  const sortedBlogs = [...blogs]
    .filter(
      (blog: any) => blog.created_at && !isNaN(Date.parse(blog.created_at))
    )
    .sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );

  const extractPTags = (html: any) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    const pTags = tempDiv.querySelectorAll("p");
    return Array.from(pTags)
      .map((p) => p.outerHTML)
      .join("");
  };

  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.title}>
          <h2>Read our latest blogs to Related Articles</h2>
          <a href="/blog">More</a>
        </div>

        <div className={Style.Article}>
          <Swiper
            modules={[Autoplay]}
            spaceBetween={10}
            slidesPerView={1}
            loop={true}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            breakpoints={{
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
            }}
          >
            {sortedBlogs.slice(0, 5).map((item) => (
              <SwiperSlide key={item.id}>
                <a
                  href={`/blog/title/${item.title
                    .toLowerCase()
                    .replace(/\s+/g, "-")}`}
                >
                  <div className={Style.ArticleData}>
                    <img
                      src={`${baseUrl}${item.image}`}
                      srcSet={`${baseUrl}${item.image}?w=300 300w, ${baseUrl}${item.image}?w=600 600w, ${baseUrl}${item.image}?w=900 900w`}
                      sizes="(max-width: 600px) 300px, (max-width: 1200px) 600px, 900px"
                      alt={item.title}
                      loading="lazy"
                      width="100%"
                      height="auto"
                      style={{ objectFit: "cover" }}
                    />
                    <div className={Style.ArticleText}>
                      <h2>{item.title}</h2>
                      <div
                        className={Style.text}
                        dangerouslySetInnerHTML={{
                          __html: extractPTags(item.content),
                        }}
                      />
                      <span>Read More</span>
                    </div>
                  </div>
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
});

export default Article;
