import React, { useEffect, useState, useCallback } from "react";
import Style from "../style/blog.module.scss";
import { useAppDispatch } from "../redux/hooks";
import { fetchBlogs } from "../redux/Slice";
import { useParams } from "react-router-dom";
import Loader from "../loader/Loader";
import { getBlogsFromDB } from "../indexDb/blogDb";

const Blog = () => {
  const { category } = useParams();
  const dispatch = useAppDispatch();

  const [blogs, setBlogs] = useState<any[]>([]);
  const [filteredBlogs, setFilteredBlogs] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 6;
  const baseUrl = "https://onedialusa.com/assets/img/blogs/";

  const loadCachedBlogs = async () => {
    let allCachedBlogs: any[] = [];
    const cachedBlogs: any = await getBlogsFromDB();
    if (cachedBlogs.length > 0) {
      allCachedBlogs = cachedBlogs;
      setBlogs(allCachedBlogs);
      return true;
    }
    return false;
  };

  const fetchBlogsBatch = useCallback(async () => {
    const response = await dispatch(fetchBlogs());
    const newBlogs = response.payload?.blogs?.blogs?.data || [];

    if (newBlogs.length > 0) {
      setBlogs((prev) => {
        const updatedBlogs = [...prev, ...newBlogs];
        return updatedBlogs;
      });
    }
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);

    const loadData = async () => {
      const hasCache = await loadCachedBlogs();
      if (!hasCache) {
        await fetchBlogsBatch();
      }
      setLoading(false);
    };

    loadData();
  }, [category, fetchBlogsBatch]);

  useEffect(() => {
    if (blogs.length > 0) {
      if (category) {
        const filtered = blogs.filter(
          (item) =>
            item.categoryName.toLowerCase().replaceAll(" ", "-") === category
        );
        setFilteredBlogs(filtered);
      } else {
        setFilteredBlogs(blogs);
      }
    }
  }, [category, blogs]);

  const paginatedBlogs = filteredBlogs.slice(0, currentPage * itemsPerPage);

  const loadMoreBlogs = () => {
    setCurrentPage((prev) => prev + 1);
    fetchBlogsBatch();
  };

  const extractPTags = (html: any) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    const pTags = tempDiv.querySelectorAll("p");
    return Array.from(pTags)
      .map((p) => p.outerHTML)
      .join("");
  };

  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.title}>
          <h2>{category ? `${category.toUpperCase()}` : "All Blogs"}</h2>
        </div>

        <div className={Style.Article}>
          {loading && blogs.length === 0 ? (
            <Loader />
          ) : paginatedBlogs.length > 0 ? (
            paginatedBlogs.map((item) => (
              <a
                href={`/blog/title/${item.title
                  .toLowerCase()
                  .replaceAll(" ", "-")}`}
                key={item.id}
              >
                <div className={Style.ArticleData}>
                  <img
                    src={`${baseUrl}${item.image}`}
                    srcSet={`${baseUrl}${item.image}?w=300 300w, ${baseUrl}${item.image}?w=600 600w, ${baseUrl}${item.image}?w=900 900w`}
                    sizes="(max-width: 600px) 300px, (max-width: 1200px) 600px, 900px"
                    alt={item.title}
                    loading="lazy"
                    width="100%"
                    height="auto"
                    style={{ objectFit: "cover" }}
                  />
                  <div className={Style.ArticleText}>
                    <h2>{item.title}</h2>
                    <div
                      className={Style.text}
                      dangerouslySetInnerHTML={{
                        __html: extractPTags(item.content),
                      }}
                    />
                    <span>Read More</span>
                  </div>
                </div>
              </a>
            ))
          ) : (
            <p>No blogs available.</p>
          )}
        </div>

        {filteredBlogs.length > itemsPerPage && (
          <div className={Style.pagination}>
            <button onClick={loadMoreBlogs} disabled={loading}>
              {loading ? "Loading..." : "Load More"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Blog;
