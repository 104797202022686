import React from "react";
import Style from "../../style/eventapp.module.scss";

const EventApp = () => {
  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.mainbox}>
          <div className={Style.Image}>
            <img
              src="https://sidebench.com/wp-content/uploads/2019/04/rectangle@2x-3.png"
              alt=""
            />
          </div>
          <div className={Style.content}>
            <h2>Get exclusive event offers!</h2>
            <p>
              Discover amazing events, build lasting connections, and grow your
              network.
            </p>
            <div className={Style.formbox}>
              <form>
                <input type="email" placeholder="Write Email" />
                <button>Send Link</button>
              </form>
            </div>
            <div className={Style.AppImage}>
              <a href="#/">
                <img
                  src="https://frzinapps.com/images/google-play-badge.png"
                  alt=""
                />
              </a>
              <a href="#/">
                <img
                  src="https://www.freeappsforme.com/wp-content/uploads/2022/08/appstore1-6-1024x304.jpg"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventApp;
