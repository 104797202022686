import React, { useEffect, useState } from "react";
import Style from "../style/subject.module.scss";
import { useParams } from "react-router-dom";

const CategoryLanding = () => {
  const { category } = useParams();
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch("/data/questions.json")
      .then((res) => res.json())
      .then((value) => {
        setData(value);
      });
  }, [category]);

  const filterData: any = data.filter(
    (item: any) => item.category.toLowerCase().replaceAll(" ", "-") === category
  );
  const categoryDescription =
    filterData[0]?.description?.category || "No description available.";

  const questionCount = filterData.length;

  const formattedSubject = category
    ? category.charAt(0).toUpperCase().replaceAll("-", " ") +
      category.slice(1).toLowerCase().replaceAll("-", " ")
    : "";

  return (
    <div className={Style.container}>
      <div className={Style.Image}>
        <img
          src="https://www.answers.com/icons/subjectHeader.svg"
          alt="Subject"
          loading="lazy"
        />
      </div>
      <div className={Style.breadcrumb}>
        <a href="/question">Question</a> <span>&gt;</span>{" "}
        <a href="/question/subject">Subject</a> <span>&gt;</span>{" "}
        <span className={Style.active}>{formattedSubject}</span>
      </div>
      <div className={Style.header}>
        <h1> {formattedSubject}</h1>
        <p>{categoryDescription}</p>
        <h3>{questionCount} Questions</h3>
      </div>
      <div className={Style.content}>
        {filterData.map((item: any) => {
          const answerCount = item.answers ? item.answers.length : 0;
          return (
            <a
              href={`/question/${item.category
                .toLowerCase()
                .replaceAll(" ", "-")}/${item.question
                .toLowerCase()
                .replaceAll(" ", "-")}`}
            >
              <span>{item.question}</span>
              <p>{answerCount} answers</p>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default CategoryLanding;
