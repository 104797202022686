import React from "react";
import Style from "../style/aboutvision.module.scss";

const AboutVision = () => {
  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.content}>
          <h2>Our Vision</h2>
          <p className={Style.paragraph}>
            We envision a world where every business has an equal opportunity to
            thrive by leveraging the power of digital marketing and online
            search. Our platform is designed to bridge the gap between
            businesses and customers, making it easier for people to find the
            services they need while supporting local entrepreneurs.
          </p>
        </div>
        <div className={Style.Image}>
          <img
            src="https://www.shutterstock.com/image-photo/business-technology-internet-network-concept-260nw-654039214.jpg"
            alt="Design"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutVision;
