import React, { useEffect, useState } from "react";
import Style from "../../style/eventbanner.module.scss";

const EventBanner = () => {
  const [data, setData] = useState<any | []>([]);
  useEffect(() => {
    fetch("/data/event.json")
      .then((value) => {
        return value.json();
      })
      .then((res) => {
        setData(res.events);
      });
  }, []);

  const uniqueTags = Array.from(
    new Set(data.map((item: any) => item.categories[0]))
  );

  return (
    <>
      <div className={Style.container}>
        <div className={Style.mainbox}>
          <div className={Style.text}>
            <h2>Find the Right Event for Every Passion.</h2>
            <div className={Style.searchbox}>
              <form>
                <div className={Style.box}>
                  <input
                    type="text"
                    placeholder="Enter the name of the event"
                  />
                  <button>Search</button>
                </div>
              </form>
            </div>
            <div className={Style.quickLink}>
              <ul>
                <span>Quick Link :</span>
                {data.slice(0, 5).map((item: any) => {
                  return (
                    <li>
                      <a
                        href={`/event/${item.title
                          .toLowerCase()
                          .replaceAll(" ", "-")}`}
                      >
                        {item.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className={Style.Image}>
            <span>
              <img
                src="https://img-c.udemycdn.com/course/750x422/6146603_3744_6.jpg"
                alt="Category"
              />
            </span>
            <div className={Style.border}></div>
            <div className={Style.category}>
              <ul>
                {uniqueTags.map((item: any) => {
                  return (
                    <li>
                      <a
                        href={`/event/${item
                          .toLowerCase()
                          .replaceAll(" ", "-")}`}
                      >
                        {item}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={Style.round}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <div className={Style.feature}>
        <div className={Style.feataureData}>
          <div className={Style.featureText}>
            <span>
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWkvQDQb3ws3TzW0b75ghRBg6flgkzDMu9pQuHnz-8ZLd-XvNY"
                alt=""
              />
              <p>On Demond</p>
            </span>
            <h2>Featured Services</h2>
          </div>
          <div className={Style.services}>
            <ul>
              {uniqueTags.map((item: any) => {
                return (
                  <li>
                    <a
                      href={`/event/${item.toLowerCase().replaceAll(" ", "-")}`}
                    >
                      {item}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventBanner;
