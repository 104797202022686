import React, { useEffect, useState } from "react";
import Style from "../style/subject.module.scss";
import { useParams } from "react-router-dom";

const SubjectPage = () => {
  const { subject } = useParams();
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch("/data/questions.json")
      .then((res) => res.json())
      .then((value) => {
        setData(value);
      });
  }, [subject]);

  const filterData: any =
    data &&
    data.filter(
      (item: any) => item.subject.toLowerCase().replaceAll(" ", "-") === subject
    );

  const uniqueData = Array.from(
    new Map(filterData.map((item: any) => [item.category, item])).values()
  );

  const categoryDescription =
    filterData[0]?.description?.subject || "No description available.";

  const formattedSubject = subject
    ? subject.charAt(0).toUpperCase().replaceAll("-", " ") +
      subject.slice(1).toLowerCase().replaceAll("-", " ")
    : "";

  return (
    <div className={Style.container}>
      <div className={Style.Image}>
        <img
          src="https://www.answers.com/icons/subjectHeader.svg"
          alt="Subject"
          loading="lazy"
        />
      </div>
      <div className={Style.breadcrumb}>
        <a href="/question">Question</a> <span>&gt;</span>{" "}
        <a href="/question/subject">Subject</a> <span>&gt;</span>{" "}
        <span className={Style.active}>{formattedSubject}</span>
      </div>
      <div className={Style.header}>
        <h1> {formattedSubject}</h1>
        <p>{categoryDescription}</p>
      </div>

      <div className={Style.content}>
        {uniqueData.map((item: any) => {
          return (
            <a
              href={`/question/category/${item.category
                .toLowerCase()
                .replaceAll(" ", "-")}`}
            >
              <span>{item.category}</span>
              <p>{item.description.category}</p>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default SubjectPage;
