import React, { useState } from "react";
import Style from "../../style/realstatelandingleft.module.scss";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import RelatorContactForm from "./Form/RelatorContactForm";

const RealStateLandingLeft = ({ itemList }: any) => {
  const [homePrice, setHomePrice] = useState(500000);
  const [downPayment, setDownPayment] = useState(100000);
  const [interestRate, setInterestRate] = useState(4.5);
  const [loanTerm, setLoanTerm] = useState(30);

  const loanAmount = homePrice - downPayment;
  const monthlyInterest = interestRate / 100 / 12;
  const numberOfPayments = loanTerm * 12;

  const monthlyPayment =
    (loanAmount * monthlyInterest) /
    (1 - Math.pow(1 + monthlyInterest, -numberOfPayments));

  const totalAmount = monthlyPayment * numberOfPayments;
  const interestAmount = totalAmount - loanAmount;
  const principalAmount = loanAmount;

  const data = [
    { name: "Principal Amount", value: principalAmount },
    { name: "Interest Amount", value: interestAmount },
  ];

  const COLORS = ["#0088FE", "#FF8042"];
  return (
    <div className={Style.container}>
      <div className={Style.place}>
        <div className={Style.placeTitle}>
          <h2>Browse Similar Places</h2>
          <ul>
            <li>
              <a href="#/">
                <span>
                  <img
                    src="https://d3aew4oo17ml6.cloudfront.net/common/images/other/smlr-city.jpg"
                    alt=""
                  />
                </span>
                <div className={Style.placeText}>
                  <h2>Ardsley, NY</h2>
                  <h3>View all</h3>
                </div>
              </a>
            </li>
            <li>
              <a href="#/">
                <span>
                  <img
                    src="https://homes.sulekha.com/nriproperty/common/images/other/property-type.jpg"
                    alt=""
                  />
                </span>
                <div className={Style.placeText}>
                  <h2>Single Family Homes</h2>
                  <h3>View all</h3>
                </div>
              </a>
            </li>
            <li>
              <a href="#/">
                <span>
                  <img
                    src="https://homes.sulekha.com/nriproperty/common/images/other/seller-type.jpg"
                    alt=""
                  />
                </span>
                <div className={Style.placeText}>
                  <h2>Agent Properties</h2>
                  <h3>View all</h3>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={Style.Description}>
        <h2>Description</h2>
        <p>{itemList.text}</p>
        <h3>Home Details</h3>
        <ul>
          {itemList.amenities.map((item: any) => {
            return (
              <li>
                <i className={item.icon}></i>
                <span>{item.name}</span>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={Style.propertyDetail}>
        <h2>Property Details</h2>
        <ul>
          <li>
            <span>Bed rooms</span>
            {itemList.room}
          </li>
          <li>
            <span>Bath rooms</span>
            {itemList.bath}
          </li>
          <li>
            <span>Parking</span>
            {itemList.parkingAvailability}
          </li>
          <li>
            <span>Floor</span>
            {itemList.flooringType}
          </li>
          <li>
            <span>Built Year</span>
            {itemList.built}
          </li>
        </ul>
      </div>
      <RelatorContactForm itemList={itemList} layout="sidebar" />
      <div className={Style.affordability}>
        <h2>Affordability </h2>
        <p>Calculate your monthly Mortgage payments</p>
        <div className={Style.affordData}>
          <label>
            Home Price: $
            <input
              type="number"
              value={homePrice}
              onChange={(e) => setHomePrice(Number(e.target.value))}
            />
          </label>
          <label>
            Down Payment: $
            <input
              type="number"
              value={downPayment}
              onChange={(e) => setDownPayment(Number(e.target.value))}
            />
          </label>
          <label>
            Interest Rate (%):
            <input
              type="number"
              value={interestRate}
              onChange={(e) => setInterestRate(Number(e.target.value))}
            />
          </label>
          <label>
            Loan Term (Years):
            <input
              type="number"
              value={loanTerm}
              onChange={(e) => setLoanTerm(Number(e.target.value))}
            />
          </label>
        </div>
        <div className={Style.rate}>
          <h3>💰 Monthly Payment: ${monthlyPayment.toFixed(2)} / Month</h3>
          <h3>🏠 Total Amount: ${totalAmount.toFixed(2)}</h3>
          <h3>📊 Interest Amount: ${interestAmount.toFixed(2)}</h3>
          <h3>🏦 Principal Amount: ${principalAmount.toFixed(2)}</h3>
        </div>
        <div className={Style.chart}>
          <PieChart width={400} height={300}>
            <Pie
              data={data}
              cx={200}
              cy={150}
              outerRadius={100}
              fill="#8884d8"
              dataKey="value"
              label
            >
              {data.map((_, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </div>
      </div>
      <div className={Style.neighbourhood}>
        <h2>Near by Neighbourhood in Ardsley, NY</h2>
        <div className={Style.neighbourhoodDetail}>
          <a href="#/">{itemList.city}</a>
          <h2>{itemList.type}</h2>
          <h3>{itemList.price}</h3>
        </div>
      </div>
    </div>
  );
};

export default RealStateLandingLeft;
