import React, { useEffect, useState } from "react";
import Style from "../../style/eventcategory.module.scss";

const EventCategory = () => {
  const [data, setData] = useState<any | []>([]);
  useEffect(() => {
    fetch("/data/event.json")
      .then((value) => {
        return value.json();
      })
      .then((res) => {
        setData(res.events);
      });
  }, []);

  const categoryData = Array.from(
    new Map(data.map((item: any) => [item.categories[0], item])).values()
  );

  const countByCategory = (category: string) =>
    data.filter((item: any) => item.categories[0] === category).length;
  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.mainbox}>
          <h2>Browse by Category</h2>
          <div className={Style.content}>
            <ul>
              {categoryData.slice(0, 5).map((item: any) => {
                const category = item.categories[0];
                return (
                  <>
                    {" "}
                    <li key={category}>
                      <a
                        href={`/event/${category
                          .toLowerCase()
                          .replaceAll(" ", "-")}`}
                      >
                        <i className={item.categoryIcon}></i>
                        <span>{category}</span>
                        <strong>{countByCategory(category)}.0 Events</strong>
                      </a>
                    </li>
                  </>
                );
              })}
              <li>View All</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCategory;
