import React from "react";
import Style from "../style/freeListing.module.scss";
import { useState } from "react";

const FreeListing = () => {
  const [isActive, setIsActive] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [accordian, setAccordian] = useState(0);
  const [showQuestion, setShowQuestion] = useState(false);
  const [button, setButton] = useState(true);

  const handleAccordian = (index: any) => {
    setAccordian((prev) => (prev === index ? null : index));
  };

  const handleQuestion = () => {
    setShowQuestion(true);
    setButton(false);
  };

  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.link}>
          <a href="/">Home</a>
          <i className="fa-solid fa-angle-right"></i>
          <span>Free Business Listing</span>
        </div>
        <div className={Style.ListContent}>
          <div className={Style.LeftAside}>
            <h1>
              List Your Business <strong>for FREE</strong>
              <br />& Expand Your Reach!
            </h1>
            <span>Boost Your Online Presence & Connect with More Customer</span>
            <p>
              Are you a business owner looking to grow your customer base and
              enhance your visibility? List your business for FREE on our local
              search engine and get discovered by thousands of potential
              customers. Whether you're a small business, a startup, or an
              established brand, a free business listing helps you showcase your
              products and services, respond to customer queries, and build
              trust—all in one place!
            </p>
            <form>
              <div
                className={`${Style.formbox} ${
                  isActive || inputValue ? Style.active : ""
                }`}
              >
                <div className={Style.formCountry}>
                  <span className={Style.countryImage}></span>
                  <span className={Style.NumberCode}>+91</span>
                </div>
                <div className={Style.box}>
                  <input
                    type="text"
                    value={inputValue}
                    onFocus={() => setIsActive(true)}
                    onBlur={() => !inputValue && setIsActive(false)}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                  <span className={Style.error}></span>
                </div>
                <label>Enter Mobile No.</label>
                <button>
                  Start Now <i className="fa-solid fa-arrow-right"></i>
                </button>
              </div>
            </form>
            <div className={Style.ListItem}>
              <ul>
                <li>
                  Expand Your Business Online – <br /> Increase your visibility
                  and attract more leads.
                </li>
                <li>
                  Respond to Reviews & Queries Faster – <br />
                  Engage with customers and build trust.
                </li>
                <li>
                  Showcase Your Products & Services – <br />
                  Highlight what makes your business unique.
                </li>
              </ul>
            </div>
            <div className={Style.policy}>
              <h2>By continuing, you agree to our </h2>
              <a href="#/">Terms of Use , </a>
              <a href="#/">Privacy & </a>
              <a href="#/">Infringement Policy</a>
            </div>
          </div>
          <div className={Style.rightAside}>
            <div className={Style.fullListItem}>
              <div className={Style.rightList}>
                <span className={Style.RightListImage}>
                  <img
                    src="https://akam.cdn.jdmagicbox.com/images/icontent/listingbusiness/Money-Ecommerce.svg"
                    alt="Money"
                    loading="lazy"
                  />
                </span>
                <span className={Style.rightListText}>
                  <b>19.8 Crore+</b>
                  <span>Buyers*</span>
                </span>
              </div>
              <div className={Style.rightList}>
                <span className={Style.RightListImage}>
                  <img
                    src="https://akam.cdn.jdmagicbox.com/images/icontent/listingbusiness/happy.svg"
                    alt="Happy"
                    loading="lazy"
                  />
                </span>
                <span className={Style.rightListText}>
                  <b>5.9 Lakh+</b>
                  <span>Happy Customers</span>
                </span>
              </div>
              <div className={Style.rightList}>
                <span className={Style.RightListImage}>
                  <img
                    src="https://akam.cdn.jdmagicbox.com/images/icontent/listingbusiness/shop.svg"
                    alt="Shop"
                    loading="lazy"
                  />
                </span>
                <span className={Style.rightListText}>
                  <b>4.6 Crore+ </b>
                  <span>Businesses Listed</span>
                </span>
              </div>
            </div>
            <div className={Style.rightImage}>
              <img
                src="https://img.freepik.com/free-vector/blogger-review-illustration-with-woman_23-2148528962.jpg?semt=ais_hybrid"
                alt="Home"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={Style.successStory}>
        <div className={Style.wrapper}>
          <div className={Style.story}>
            <span>
              <img
                src="https://akam.cdn.jdmagicbox.com/images/icontent/listingbusiness/quoteleft.svg"
                alt="Quote"
                loading="lazy"
              />
            </span>
            <h2>Success Stories</h2>
            <h3>5.9 Lakh+ Advertisers</h3>
            <a href="#/">See All Stories</a>
          </div>
          <div className={Style.StoryListItem}>
            <div className={Style.storyList}>
              <span>
                <img
                  src="https://cdn.pixabay.com/photo/2019/12/18/13/07/right-4703938_1280.jpg"
                  alt="Client"
                  loading="lazy"
                />
              </span>
              <div className={Style.VedioIcon}></div>
              <div className={Style.CompanyText}>
                <div className={Style.CompanyDetail}>
                  <h2>Rajesh Chhabria</h2>
                  <h3>Chhabria and Sons</h3>
                  <h4>Tile Dealers-RAK</h4>
                </div>
                <div className={Style.CompanyBottomText}>
                  <h2>Customer since 12 years</h2>
                  <a href="#/">Visit Bussiness</a>
                </div>
              </div>
            </div>
            <div className={Style.storyList}>
              <span>
                <img
                  src="https://cdn.pixabay.com/photo/2019/12/18/13/08/right-4703944_1280.jpg"
                  alt="Client"
                  loading="lazy"
                />
              </span>
              <div className={Style.VedioIcon}></div>
              <div className={Style.CompanyText}>
                <div className={Style.CompanyDetail}>
                  <h2>Varshini</h2>
                  <h3>V2 Makeover</h3>
                  <h4>Makeup Artists</h4>
                </div>
                <div className={Style.CompanyBottomText}>
                  <h2>Customer since 3 year</h2>
                  <a href="#/">Visit Bussiness</a>
                </div>
              </div>
            </div>
            <div className={Style.storyList}>
              <span>
                <img
                  src="https://cdn.pixabay.com/photo/2015/01/08/18/29/entrepreneur-593358_1280.jpg"
                  alt="Client"
                  loading="lazy"
                />
              </span>
              <div className={Style.VedioIcon}></div>
              <div className={Style.CompanyText}>
                <div className={Style.CompanyDetail}>
                  <h2>Gourab Neogi</h2>
                  <h3>Tally Academy (Corporate Centre -Gariahat)</h3>
                  <h4>Accounting Training Institutes</h4>
                </div>
                <div className={Style.CompanyBottomText}>
                  <h2>Customer since 6 year</h2>
                  <a href="#/">Visit Bussiness</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={Style.FreeBussinessTips}>
        <div className={Style.wrapper}>
          <div className={Style.title}>
            <h2>Get a FREE Business Listing in 3 Simple Steps</h2>
          </div>
          <div className={Style.BussinessStep}>
            <div className={Style.Step}>
              <span>
                <img
                  src="https://www.justdial.com/Free-Listing/_next/image?url=https%3A%2F%2Fakam.cdn.jdmagicbox.com%2Fimages%2Ficontent%2Flistingbusiness%2Fcreateaccount%402x.png&w=256&q=75"
                  alt="Account"
                  loading="lazy"
                />
              </span>
              <div className={Style.firstStepText}>
                <h2>Step 1</h2>
                <h3>Create Account</h3>
                <p>Sign Up by number and email</p>
              </div>
            </div>
            <div className={Style.Step}>
              <span>
                <img
                  src="https://www.justdial.com/Free-Listing/_next/image?url=https%3A%2F%2Fakam.cdn.jdmagicbox.com%2Fimages%2Ficontent%2Flistingbusiness%2Fenterbusinessdetails%402x.png&w=256&q=75"
                  alt="Bussiness"
                  loading="lazy"
                />
              </span>
              <div className={Style.firstStepText}>
                <h2>Step 2</h2>
                <h3>Business Details</h3>
                <p>Enter Your Business Details</p>
              </div>
            </div>
            <div className={Style.Step}>
              <span>
                <img
                  src="https://www.justdial.com/Free-Listing/_next/image?url=https%3A%2F%2Fakam.cdn.jdmagicbox.com%2Fimages%2Ficontent%2Flistingbusiness%2Fselectcat%402x.png&w=384&q=75"
                  alt="Cat"
                  loading="lazy"
                />
              </span>
              <div className={Style.firstStepText}>
                <h2>Step 3</h2>
                <h3>Start</h3>
                <p>Start Connecting with Customers</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={Style.ConnectCustomer}>
        <div className={Style.wrapper}>
          <div className={Style.title}>
            <h2>Premium Features to Elevate Your Business</h2>
          </div>
          <div className={Style.ConnectionContent}>
            <div className={Style.connectionImage}>
              <img
                src="https://www.justdial.com/Free-Listing/_next/image?url=http%3A%2F%2Fakam.cdn.jdmagicbox.com%2Fimages%2Ficontent%2Flistingbusiness%2Ffreebusinesslisting%402x.png&w=640&q=75"
                alt="Content"
                loading="lazy"
              />
            </div>
            <div className={Style.ConnectionText}>
              <ul>
                <li>
                  <span>🌟</span>
                  Premium Listing – Get top placement for higher visibility.
                </li>
                <li>
                  <span>🔹</span>
                  Verified Seal – Gain customer trust with a verified profile.
                </li>
                <li>
                  <span>📢</span>
                  Website & Mobile Banner Ads – Advertise on competitor
                  listings.
                </li>
                <li>
                  <span>📊</span>
                  Smart Lead Management – Track, manage, and convert more leads.
                </li>
              </ul>
              <b>Register Your Business Today</b>
              <form>
                <div
                  className={`${Style.formbox} ${
                    isActive || inputValue ? Style.active : ""
                  }`}
                >
                  <div className={Style.formCountry}>
                    <span className={Style.countryImage}></span>
                    <span className={Style.NumberCode}>+91</span>
                  </div>
                  <div className={Style.box}>
                    <input
                      type="text"
                      value={inputValue}
                      onFocus={() => setIsActive(true)}
                      onBlur={() => !inputValue && setIsActive(false)}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                    <span className={Style.error}></span>
                  </div>
                  <label>Enter Mobile No.</label>
                  <button>
                    Start Now <i className="fa-solid fa-arrow-right"></i>
                  </button>
                </div>
              </form>
              <div className={Style.policy}>
                <h2>By continuing, you agree to our </h2>
                <a href="#/">Terms of Use , </a>
                <a href="#/">Privacy & </a>
                <a href="#/">Infringement Policy</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={Style.Profile}>
        <h2>How to Make Your Business Profile Stand Out</h2>
        <ul>
          <li>
            <span>✔ </span>
            Add High-Quality Images & Descriptions – Attract more attention.
          </li>
          <li>
            <span>✔</span> Encourage & Respond to Customer Reviews – Build
            credibility.
          </li>
          <li>
            <span>✔</span> Keep Your Contact Information Updated – Ensure
            seamless communication.
          </li>
        </ul>
      </div>
      <div className={Style.bussiness}>
        <h2>How to List Your Business for FREE in 3 Easy Steps</h2>
        <p>
          Getting your business listed on our local search platform is quick and
          hassle-free. Follow these simple steps to start attracting more
          customers today!
        </p>
        <ul>
          <li>
            <span>Step 1: Sign Up & Enter Your Business Details</span>
            <h2>
              Visit our website and click on "List Your Business for Free."
            </h2>
            <h2>
              Fill in essential details like business name, category, address,
              contact information, and website (if available).
            </h2>
            <h2>
              Add a brief description to let customers know what you offer.
            </h2>
          </li>
          <li>
            <span>Step 2: Verify & Optimize Your Profile</span>
            <h2>
              Complete the verification process via phone, email, or OTP
              (One-Time Password).
            </h2>
            <h2>
              Upload high-quality images of your storefront, products, or
              services to make your profile more appealing..
            </h2>
            <h2>
              Ensure your business hours and contact details are accurate for
              better customer engagement.
            </h2>
          </li>
          <li>
            <span>Step 3: Get Listed & Start Growing</span>
            <h2>
              Once verified, your business listing will go live on our platform.
            </h2>
            <h2>
              Customers can now find, contact, and review your business online.
            </h2>
            <h2>
              Regularly update your profile, respond to reviews, and stay active
              to maximize your reach!
            </h2>
          </li>
        </ul>
      </div>
      <div className={Style.Question}>
        <div className={Style.wrapper}>
          <div className={Style.title}>
            <h2>Got a question?</h2>
          </div>
          <div className={Style.QuestionContent}>
            <div className={Style.OuestionNumber}>
              <h2
                onClick={() => handleAccordian(1)}
                className={`${accordian === 1 ? Style.accordian : ""}`}
              >
                How do I list my business for free?
              </h2>
              <div
                className={`${Style.QuestionText} ${
                  accordian === 1 ? Style.accordian : ""
                }`}
              >
                <p>
                  Listing your business is simple! Click on “List Your Business
                  for Free”, enter your business details, complete the
                  verification process, and your listing will go live.
                </p>
              </div>
            </div>
            <div className={Style.OuestionNumber}>
              <h2
                onClick={() => handleAccordian(2)}
                className={`${accordian === 2 ? Style.accordian : ""}`}
              >
                What are the benefits of a free business listing?
              </h2>
              <div
                className={`${Style.QuestionText} ${
                  accordian === 2 ? Style.accordian : ""
                }`}
              >
                <p>
                  A free listing helps you increase online visibility, connect
                  with more customers, showcase your products and services, and
                  respond to customer reviews & queries.
                </p>
              </div>
            </div>
            <div className={Style.OuestionNumber}>
              <h2
                onClick={() => handleAccordian(3)}
                className={`${accordian === 3 ? Style.accordian : ""}`}
              >
                How can I improve my business listing?
              </h2>
              <div
                className={`${Style.QuestionText} ${
                  accordian === 3 ? Style.accordian : ""
                }`}
              >
                <p>
                  To make your listing more effective, add high-quality images,
                  provide a detailed business description, update contact
                  details, and respond to customer reviews regularly.
                </p>
              </div>
            </div>
            <div className={Style.OuestionNumber}>
              <h2
                onClick={() => handleAccordian(4)}
                className={`${accordian === 4 ? Style.accordian : ""}`}
              >
                Can I edit my business information after listing?
              </h2>
              <div
                className={`${Style.QuestionText} ${
                  accordian === 4 ? Style.accordian : ""
                }`}
              >
                <p>
                  Yes! You can log into your account anytime to update your
                  business name, address, phone number, images, and other
                  details to keep your profile accurate.
                </p>
              </div>
            </div>
            {showQuestion && (
              <>
                <div className={Style.OuestionNumber}>
                  <h2
                    onClick={() => handleAccordian(5)}
                    className={`${accordian === 5 ? Style.accordian : ""}`}
                  >
                    How will customers find my business?
                  </h2>
                  <div
                    className={`${Style.QuestionText} ${
                      accordian === 5 ? Style.accordian : ""
                    }`}
                  >
                    <p>
                      Customers can find your business when they search for
                      relevant products or services in your area. A
                      well-optimized listing with accurate details and good
                      customer reviews will rank higher and attract more
                      visitors.
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
          {button && (
            <div className={Style.button}>
              <button onClick={handleQuestion}>View More Questions</button>
            </div>
          )}
        </div>
      </div>
      <div className={Style.BussinessProfile}>
        <div className={Style.wrapper}>
          <div className={Style.title}>
            <h2>
              Learn How to Make Your Business Profile Look More Professional
            </h2>
          </div>
          <div className={Style.ProfileContent}>
            <div className={Style.ProfileDetail}>
              <span>
                <img
                  src="https://www.justdial.com/Free-Listing/_next/image?url=https%3A%2F%2Fakam.cdn.jdmagicbox.com%2Fimages%2Ficontent%2Flistingbusiness%2Fessintialbusiness2x.png&w=640&q=75"
                  alt="Content"
                  loading="lazy"
                />
              </span>
              <h2>
                How to Fill in the Essential Business Information
                <strong>
                  Learn More <i className="fa-solid fa-arrow-right"></i>
                </strong>
              </h2>
            </div>
            <div className={Style.ProfileDetail}>
              <span>
                <img
                  src="https://www.justdial.com/Free-Listing/_next/image?url=https%3A%2F%2Fakam.cdn.jdmagicbox.com%2Fimages%2Ficontent%2Flistingbusiness%2Frightcat%402x.png&w=640&q=75"
                  alt="Fakam"
                  loading="lazy"
                />
              </span>
              <h2>
                The Art of Selecting the Right Categories
                <strong>
                  Learn More <i className="fa-solid fa-arrow-right"></i>
                </strong>
              </h2>
            </div>
            <div className={Style.ProfileDetail}>
              <span>
                <img
                  src="https://www.justdial.com/Free-Listing/_next/image?url=http%3A%2F%2Fakam.cdn.jdmagicbox.com%2Fimages%2Ficontent%2Flistingbusiness%2Fcustomerreview%402x.png&w=640&q=75"
                  alt="View"
                  loading="lazy"
                />
              </span>
              <h2>
                How to Respond to Customer Reviews and Questions
                <strong>
                  Learn More <i className="fa-solid fa-arrow-right"></i>
                </strong>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className={Style.BussinessList}>
        <div className={Style.wrapper}>
          <div className={Style.title}>
            <h2>List Your Business for FREE on this Website Today</h2>
            <p>India's No. 1 Local Search Engine</p>
          </div>
          <form>
            <div
              className={`${Style.formbox} ${
                isActive || inputValue ? Style.active : ""
              }`}
            >
              <div className={Style.formCountry}>
                <span className={Style.countryImage}></span>
                <span className={Style.NumberCode}>+91</span>
              </div>
              <div className={Style.box}>
                <input
                  type="text"
                  value={inputValue}
                  onFocus={() => setIsActive(true)}
                  onBlur={() => !inputValue && setIsActive(false)}
                  onChange={(e) => setInputValue(e.target.value)}
                />
                <span className={Style.error}></span>
              </div>
              <label>Enter Mobile No.</label>
              <button>
                Start Now <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </form>
          <div className={Style.policy}>
            <h2>By continuing, you agree to our </h2>
            <a href="#/">Terms of Use , </a>
            <a href="#/">Privacy & </a>
            <a href="#/">Infringement Policy</a>
          </div>
        </div>
      </div>
      <div className={Style.BusinessFullDetail}>
        <div className={Style.wrapper}>
          <div className={Style.Detaillisting}>
            <h2>
              List Your Business for FREE on this Website Local Search Engine
            </h2>
            <p>
              Are you a business owner seeking to expand your reach and attract
              more customers? Justdial, India’s leading local search engine,
              offers an incredible opportunity to list your business for free
              and boost your online visibility. Whether you’re a startup, an
              SMB, or an established enterprise, listing your business on
              Justdial can increase your reach and drive valuable leads.
            </p>
          </div>
          <div className={Style.ListingReason}>
            <h2>Why List Your Business on this Website?</h2>
            <ul>
              <li>
                Massive Audience : Justdial connects millions of users with
                local businesses daily.
              </li>
              <li>
                24/7 Virtual Storefront : Increase accessibility to your
                products or services.
              </li>
              <li>
                Enhanced Credibility : Build trust with potential customers.
              </li>
              <li>
                Targeted Lead Generation : Attract users actively searching for
                your offerings.
              </li>
            </ul>
          </div>
          <div className={Style.BussinessListingStep}>
            <h2>How List Your Business on this Website?</h2>
            <ul>
              <li>
                Visit the Justdial Free Listing Page :{" "}
                <a href="/">http://localhost:3000/</a>
              </li>
              <li>Click “Start Now” : Begin the registration process.</li>
              <li>
                Provide Business Details : Name, address, contact, category,
                etc.
              </li>
              <li>OTP Verification : Ensure a secure and verified listing.</li>
            </ul>
          </div>
          <div className={Style.BusinessProfit}>
            <h2>Benefits of Justdial’s Free Listing</h2>
            <ul>
              <li>Increased Online Visibility</li>
              <li>Boosted Credibility</li>
              <li>Direct Lead Generation</li>
              <li>Nationwide Reach</li>
              <li>Keyword Optimization</li>
              <li>Potential for Business Growth</li>
            </ul>
          </div>
          <div className={Style.BussinessTips}>
            <h2>Additional Tips for SEO Optimization</h2>
            <ul>
              <li>
                Local Keywords : Target terms like “[your city's name] +
                [service your provide]”.
              </li>
              <li>
                Business Reviews : Encourage positive reviews for social proof.
              </li>
              <li>
                Featured Listings & Ads : Consider Justdial’s paid options for
                even more prominence.
              </li>
            </ul>
            <h3>
              Create your FREE this website business profile today and unlock
              new growth opportunities!
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeListing;
