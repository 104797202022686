import React, { useEffect, useState } from "react";
import Style from "../style/productrightcontent.module.scss";
import { useAppDispatch } from "../redux/hooks";
import { fetchListing } from "../redux/Slice";
import { useParams } from "react-router-dom";
import { getProductsFromDB, saveProductsToDB } from "../indexDb/productDb";

interface IType {
  handleOverlay: any;
}

const ProductRightContent: React.FC<IType> = ({ handleOverlay }) => {
  const { userCategory } = useParams();
  const dispatch = useAppDispatch();
  const [localListings, setLocalListings] = useState<any[]>([]);
  useEffect(() => {
    const loadData = async () => {
      const localProducts: any = await getProductsFromDB();
      if (localProducts.length > 0) {
        setLocalListings(localProducts);
        dispatch(fetchListing(localProducts));
      }
      if (localProducts.length === 0) {
        try {
          const apiData = await dispatch(fetchListing()).unwrap();
          if (apiData?.listings?.length > 0) {
            setLocalListings(apiData.listings);
            await saveProductsToDB(apiData.listings);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    loadData();
  }, [dispatch]);

  const filterItem =
    localListings &&
    localListings.filter(
      (item) =>
        item.category_name &&
        item.category_name.toLowerCase().replaceAll(" ", "-") === userCategory
    );
  console.log(filterItem, "data");
  return (
    <>
      <div className={Style.rightdetail}>
        <h2 onClick={handleOverlay}>About Search Results</h2>
      </div>
      <div className={Style.rightListing}>
        <a href="#/">
          <h2>
            Manage your <br />
            <b>free</b> listing
          </h2>
          <img
            src="	https://i3.ypcdn.com/ypu/images/srp-claim-banner.png?a0b7a33"
            alt="Claim"
            loading="lazy"
          />
          <p>Update your business information in a few steps.</p>
          <button>Claim Your Listing</button>
        </a>
      </div>
      <div className={Style.rightBanner}>
        <h2>{userCategory?.toUpperCase()}</h2>
        <ul>
          {filterItem &&
            filterItem.slice(0, 8).map((item) => {
              return (
                <li>
                  <a
                    href={`/listing/page/${item.title
                      .toLowerCase()
                      .replaceAll(" ", "-")}`}
                  >
                    {item.title}
                  </a>
                  <div className={Style.star}>
                    {Array.from({ length: 5 }, (_, i) => (
                      <i
                        key={i}
                        className={
                          i < Math.round(item.employee_count)
                            ? "fa-solid fa-star"
                            : "fa-regular fa-star"
                        }
                      ></i>
                    ))}
                    <h6>({item.employee_count})</h6>
                  </div>
                  <h3>{item.phone}</h3>
                  <h4>{item.address}</h4>
                </li>
              );
            })}
        </ul>
      </div>
      <div className={Style.rightBannerImage}>
        <img
          src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/travel-ads-design-template-3cc8c79f504ffbd707697e72b7821ef2_screen.jpg?ts=1707837394"
          alt="Travel"
          loading="lazy"
        />
      </div>
      <div className={Style.rightBannerImage}>
        <img
          src="https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img,w_300,h_250/https://themillbrooktimes.ca/wp-content/uploads/2013/11/Screen-Shot-2016-01-22-at-3.01.43-PM.png"
          alt="Glossy"
          loading="lazy"
        />
      </div>
      <div className={Style.rightBannerImage}>
        <img
          src="https://www.creatopy.com/blog/wp-content/uploads/2019/09/online-banking-travel-financial-service-ad-424x600.jpg"
          alt="Online"
          loading="lazy"
        />
      </div>
      <div className={Style.rightBannerImage}>
        <img
          src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/business-marketing-ads-design-template-5b0a01d1712c34a42c9f6d6c45c8986e_screen.jpg?ts=1650617472"
          alt="Bussiness"
          loading="lazy"
        />
      </div>
    </>
  );
};

export default ProductRightContent;
