import React from "react";
import Style from "../../style/event.module.scss";
import EventBanner from "./EventBanner";
import EventCategory from "./EventCategory";
import FeatureEvent from "./FeatureEvent";
import EventCity from "./EventCity";
import EventVenue from "./EventVenue";
import EventOrganiser from "./EventOrganiser";
import EventSubscribe from "./EventSubscribe";
import EventApp from "./EventApp";

const Event = () => {
  return (
    <div className={Style.container}>
      <EventBanner />
      <EventCategory />
      <FeatureEvent />
      <EventCity />
      <EventVenue />
      <EventOrganiser />
      <EventSubscribe />
      <EventApp />
    </div>
  );
};

export default Event;
