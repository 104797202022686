import React from "react";
import Style from "../../style/performance.module.scss";

const Perform = () => {
  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.title}>
          <h2>Performance</h2>
        </div>
        <div className={Style.mainbox}>
          <div className={Style.content}>
            <h2>1209+</h2>
            <h3>Active Properties</h3>
          </div>
          <div className={Style.content}>
            <h2>1209+</h2>
            <h3>Active Properties</h3>
          </div>
          <div className={Style.content}>
            <h2>1209+</h2>
            <h3>Active Properties</h3>
          </div>
          <div className={Style.content}>
            <h2>1209+</h2>
            <h3>Active Properties</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Perform;
