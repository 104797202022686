import React from "react";
import Style from "../style/aboutoffer.module.scss";

const AboutOffer = () => {
  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.Image}>
          <img
            src="https://st3.depositphotos.com/7249566/16576/i/450/depositphotos_165761754-stock-photo-offerings-man-working-on-holographic.jpg"
            alt="Design"
            loading="lazy"
          />
        </div>
        <div className={Style.content}>
          <h2>What We Offer</h2>
          <p className={Style.paragraph}>
            With our easy-to-use business listing platform, you can showcase
            your products and services, engage with customers, and manage your
            business profile—all in one place. We also offer premium features
            such as priority listings, lead tracking, and digital advertising
            solutions to help businesses maximize their growth potential.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutOffer;
