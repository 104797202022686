import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Style from "../style/bloglanding.module.scss";
import { useAppDispatch } from "../redux/hooks";
import { fetchBlogs } from "../redux/Slice";
import { useParams } from "react-router-dom";
import { getBlogsFromDB } from "../indexDb/blogDb";

const BlogLanding = () => {
  const { userTitle } = useParams();
  const dispatch = useAppDispatch();
  const rightAsideRef = useRef<HTMLDivElement>(null);
  const [allBlogsState, setAllBlogsState] = useState<any[]>([]);
  const [isSticky, setIsSticky] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [initialTop, setInitialTop] = useState<number | null>(null);
  const leftAsideRef = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState({ title: "", category: "" });

  useEffect(() => {
    let isMounted = true;

    const fetchAllBlogs = async () => {
      setIsLoading(true);
      let allBlogs: any[] = [];
      let hasMorePages = true;

      try {
        const storedBlogs: any = await getBlogsFromDB();
        if (storedBlogs.length && isMounted) {
          setAllBlogsState([...storedBlogs]);
        }

        if (storedBlogs.length === 0) {
          while (hasMorePages) {
            const apiResponse = await dispatch(fetchBlogs()).unwrap();
            const apiBlogs = apiResponse?.blogs?.blogs?.data || [];

            if (apiBlogs.length) {
              allBlogs = [...allBlogs, ...apiBlogs];
            } else {
              hasMorePages = false;
            }
          }

          if (allBlogs.length && isMounted) {
            setAllBlogsState((prev) => [...prev, ...allBlogs]);
          }
        }
      } catch (error) {
        console.error("Error fetching blogs:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllBlogs();

    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  const filterData = useMemo(() => {
    if (!allBlogsState.length) return null;
    return (
      allBlogsState.find(
        (item) =>
          item.title.toLowerCase().replaceAll(" ", "-") ===
          userTitle?.toLowerCase()
      ) || null
    );
  }, [allBlogsState, userTitle]);

  const categoryCounts = useMemo(
    () =>
      allBlogsState.reduce((acc: Record<string, number>, blog) => {
        if (blog.categoryName) {
          acc[blog.categoryName] = (acc[blog.categoryName] || 0) + 1;
        }
        return acc;
      }, {}),
    [allBlogsState]
  );

  const handleScroll = useCallback(() => {
    if (rightAsideRef.current && leftAsideRef.current) {
      const rightAsideRect = rightAsideRef.current.getBoundingClientRect();
      const leftAsideRect = leftAsideRef.current.getBoundingClientRect();
      const scrollY = window.scrollY;

      if (initialTop === null) {
        setInitialTop(rightAsideRect.top + scrollY);
      }

      if (scrollY > 100 && scrollY > initialTop!) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      if (leftAsideRect.bottom <= window.innerHeight) {
        setIsSticky(false);
      }
    }
  }, [initialTop]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (search.title) {
      window.location.pathname = `/blog/title/${search.title
        .toLowerCase()
        .replaceAll(" ", "-")}`;
    } else if (search.category) {
      window.location.pathname = `/blog/category/${search.category
        .toLowerCase()
        .replaceAll(" ", "-")}`;
    }
  };

  const baseUrl = "https://onedialusa.com/assets/img/blogs/";

  return (
    <div className={Style.container}>
      <div className={Style.title}>
        {filterData ? <h1>{filterData.title}</h1> : <h1>No blog found</h1>}
      </div>
      <div className={Style.wrapper}>
        <div className={Style.divide}>
          {filterData ? (
            <div
              key={filterData.id}
              className={Style.leftAside}
              ref={leftAsideRef}
            >
              <span>
                <img
                  src={`${baseUrl}${filterData.image}`}
                  srcSet={`${baseUrl}${filterData.image}?w=300 300w, ${baseUrl}${filterData.image}?w=600 600w, ${baseUrl}${filterData.image}?w=900 900w`}
                  sizes="(max-width: 600px) 300px, (max-width: 1200px) 600px, 900px"
                  alt={filterData.title}
                  loading="lazy"
                  width="100%"
                  height="auto"
                  style={{ objectFit: "cover" }}
                />
              </span>
              <div className={Style.detail}>
                <ul>
                  <li>
                    <i className="fa-solid fa-user"></i> Admin
                  </li>
                  <li>
                    <i className="fa-solid fa-calendar"></i>{" "}
                    {filterData.created_at}
                  </li>
                  <li>
                    <i className="fa-solid fa-tag"></i>{" "}
                    {filterData.categoryName}
                  </li>
                </ul>
              </div>
              <div className={Style.fullData}>
                <h2>{filterData.title}</h2>
                <div className={Style.shareContainer}>
                  <div className={Style.navButtons}>
                    <button className={Style.prevNext}>
                      <i className="fa-solid fa-arrow-left"></i> Previous
                    </button>
                    <button className={Style.prevNext}>
                      Next <i className="fa-solid fa-arrow-right"></i>
                    </button>
                  </div>
                  <div className={Style.socialIcons}>
                    <a
                      href="https://www.facebook.com/sharer/sharer.php?u=YOUR_URL"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://plus.google.com/share?url=YOUR_URL"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-google-plus-g"></i>
                    </a>
                    <a
                      href="https://pinterest.com/pin/create/button/?url=YOUR_URL"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-pinterest-p"></i>
                    </a>
                    <a
                      href="https://www.stumbleupon.com/submit?url=YOUR_URL"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-stumbleupon"></i>
                    </a>
                    <a
                      href="https://www.tumblr.com/widgets/share/tool?canonicalUrl=YOUR_URL"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-tumblr"></i>
                    </a>
                    <a
                      href="https://twitter.com/intent/tweet?url=YOUR_URL"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className={Style.Description}
                dangerouslySetInnerHTML={{ __html: filterData.content }}
              />
            </div>
          ) : (
            <p>No blogs found.</p>
          )}
          <div
            className={`${Style.rightAside} ${isSticky ? Style.sticky : ""}`}
            ref={rightAsideRef}
          >
            <div className={Style.searchBar}>
              <h2>Search Blogs</h2>
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="Search by title..."
                  value={search.title}
                  onChange={(e) =>
                    setSearch((prev) => ({ ...prev, title: e.target.value }))
                  }
                />
                <input
                  type="text"
                  placeholder="Search by category..."
                  value={search.category}
                  onChange={(e) =>
                    setSearch((prev) => ({
                      ...prev,
                      category: e.target.value,
                    }))
                  }
                />
                <button>Search</button>
              </form>
            </div>
            <div className={Style.category}>
              <h2>Categories</h2>
              <ul>
                {Object.entries(categoryCounts).map(([category, count]) => (
                  <li key={category}>
                    <a
                      href={`/blog/category/${category
                        .toLowerCase()
                        .replaceAll(" ", "-")}`}
                    >
                      {category} ({count})
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogLanding;
