import React from "react";
import Style from "../../style/agent.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Navigation } from "swiper/modules";
import { useState } from "react";
import RelatorContactForm from "./Form/RelatorContactForm";

const Agent = () => {
  const [showForm, setShowForm] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState<any>(null);
  const data = [
    {
      id: "1",
      img: "https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRMZpg9BaGH8tN-aUwADcclKgMNgD4S6t43kwyZFMv9IN0Q6AKR",
      name: "Tamanna Jaggi",
      experiance: "0 - 5 Years",
      properties: "8",
      address: " Syosset, NY",
    },
    {
      id: "2",
      img: "https://secure.gravatar.com/avatar/20ac7110bec2412f8651586cb5a5437f?s=96&d=https://www.malayalamchristiannetwork.com/wp-content/themes/churchope/images/noava.png&r=g",
      name: "Richards",
      experiance: "0 - 8 Years",
      properties: "5",
      address: " Syosset, NY",
    },
    {
      id: "3",
      img: "https://secure.gravatar.com/avatar/20ac7110bec2412f8651586cb5a5437f?s=96&d=https://www.malayalamchristiannetwork.com/wp-content/themes/churchope/images/noava.png&r=g",
      name: "Deborah Dianne Smith",
      experiance: "0 - 10 Years",
      properties: "15",
      address: " Syosset, NY",
    },
    {
      id: "4",
      img: "https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRMZpg9BaGH8tN-aUwADcclKgMNgD4S6t43kwyZFMv9IN0Q6AKR",
      name: "Tamanna Jaggi",
      experiance: "0 - 5 Years",
      properties: "8",
      address: " Syosset, NY",
    },
    {
      id: "5",
      img: "https://secure.gravatar.com/avatar/20ac7110bec2412f8651586cb5a5437f?s=96&d=https://www.malayalamchristiannetwork.com/wp-content/themes/churchope/images/noava.png&r=g",
      name: "Richards",
      experiance: "0 - 8 Years",
      properties: "5",
      address: " Syosset, NY",
    },
    {
      id: "6",
      img: "https://secure.gravatar.com/avatar/20ac7110bec2412f8651586cb5a5437f?s=96&d=https://www.malayalamchristiannetwork.com/wp-content/themes/churchope/images/noava.png&r=g",
      name: "Deborah Dianne Smith",
      experiance: "0 - 10 Years",
      properties: "15",
      address: " Syosset, NY",
    },
  ];

  const handleRelator = () => {
    window.location.pathname = "/relator";
  };

  const handleContactClick = (agent: any) => {
    setSelectedAgent(agent);
    setShowForm(true);
  };

  const closeForm = () => {
    setShowForm(false);
    setSelectedAgent(null);
  };

  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.title}>
          <h2>Best Local Real Estate Agents</h2>
        </div>
        <div className={Style.mainbox}>
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            navigation
            loop={true}
            modules={[Navigation]}
            breakpoints={{
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
            }}
          >
            {data.map((item) => (
              <SwiperSlide key={item.id}>
                <div className={Style.content}>
                  <span>
                    <img src={item.img} alt={item.name} />
                  </span>
                  <div className={Style.detail}>
                    <a href="#/">{item.name}</a>
                    <ul>
                      <li>{item.experiance} Experience</li>
                      <li>{item.properties} Properties</li>
                    </ul>
                    <h3>{item.address}</h3>
                    <div className={Style.button}>
                      <button onClick={() => handleContactClick(item)}>
                        Contact Agent
                      </button>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={Style.viewbutton}>
          <button onClick={handleRelator}>View More</button>
        </div>
      </div>
      {showForm && selectedAgent && (
        <RelatorContactForm
          itemList={selectedAgent}
          handleRelatorForm={closeForm}
          layout="popup"
        />
      )}
    </div>
  );
};

export default Agent;
