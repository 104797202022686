import React from "react";
import Style from "../../style/journey.module.scss";
import Img from "../../img/image.png";
import Img1 from "../../img/dollor.png";
import Img2 from "../../img/agent.png";
import Img3 from "../../img/glass.png";

const Journey = () => {
  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.title}>
          <h2>Get Start Your Real Estate Journey</h2>
        </div>
        <div className={Style.mainbox}>
          <div className={Style.content}>
            <span>
              <img src={Img} alt="" />
            </span>
            <h2>Browse perfect home in preferred location with best price</h2>
            <a href="#/">Browse Properties</a>
          </div>
          <div className={Style.content}>
            <span>
              <img src={Img1} alt="" />
            </span>
            <h2>Browse perfect home in preferred location with best price</h2>
            <a href="#/">Sell Properties</a>
          </div>
          <div className={Style.content}>
            <span>
              <img src={Img2} alt="" />
            </span>
            <h2>Browse perfect home in preferred location with best price</h2>
            <a href="#/">Looking for Agents</a>
          </div>
          <div className={Style.content}>
            <span>
              <img src={Img3} alt="" />
            </span>
            <h2>Browse perfect home in preferred location with best price</h2>
            <a href="#/">List Agents</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Journey;
