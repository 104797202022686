import React, { useState } from "react";
import Style from "../../style/getconnected.module.scss";

const GetConnected = ({ handleClose }: any) => {
  const [minValue, setMinValue] = useState<number | string>("");
  const [maxValue, setMaxValue] = useState<number | string>("");
  const [priceRange, setPriceRange] = useState("Select Price Range");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTimeframe, setSelectedTimeframe] = useState("");

  const toggleDropdown = () => {
    setIsOpen(true);
  };

  const convertToNumber = (value: string) => {
    const numberValue = value
      .replace("$", "")
      .replace("k", "")
      .replace("M", "000000");
    return value.includes("M")
      ? parseInt(numberValue)
      : parseInt(numberValue) * 1000;
  };

  const handleMinSelect = (value: string) => {
    const digitValue = convertToNumber(value);
    setMinValue(digitValue);
    const newPriceRange = `${digitValue}${maxValue ? ` - ${maxValue}` : ""}`;
    setPriceRange(newPriceRange);
    if (digitValue && maxValue) {
      setIsOpen(false);
    }
  };

  const handleMaxSelect = (value: string) => {
    const digitValue = convertToNumber(value);
    setMaxValue(digitValue);
    const newPriceRange = `${minValue ? `${minValue} - ` : ""}${digitValue}`;
    setPriceRange(newPriceRange);
    if (minValue && digitValue) {
      setIsOpen(false);
    }
  };

  return (
    <div className={Style.overlay}>
      <div className={Style.wrapper}>
        <div className={Style.content}>
          <div className={Style.text}>
            <h2>Looking to buy a Property in your city?</h2>
            <p>
              Share your requirement and get connected with agents/owners that
              match you
            </p>
          </div>
          <div className={Style.cross} onClick={handleClose}>
            X
          </div>
        </div>
        <div className={Style.mainbox}>
          <div className={Style.leftAside}>
            <h2>Which type of property do you need?*</h2>
            <ul>
              <li>
                <input type="checkbox" />
                <span>Condos</span>
              </li>
              <li>
                <input type="checkbox" />
                <span>Apartments</span>
              </li>
              <li>
                <input type="checkbox" />
                <span>Single family homes</span>
              </li>
              <li>
                <input type="checkbox" />
                <span>Multi family homes</span>
              </li>
              <li>
                <input type="checkbox" />
                <span>Lands / Lots</span>
              </li>
              <li>
                <input type="checkbox" />
                <span>Farms & Ranches</span>
              </li>
              <li>
                <input type="checkbox" />
                <span>Mobile & Manufactured Homes</span>
              </li>
              <li>
                <input type="checkbox" />
                <span>Townhouses</span>
              </li>
              <li>
                <input type="checkbox" />
                <span>Commercial Property</span>
              </li>
              <li>
                <input type="checkbox" />
                <span>Cabin/Cottage</span>
              </li>
            </ul>
          </div>
          <div className={Style.rightAside}>
            <h2>Budget Range*</h2>
            <div className={Style.box}>
              <input
                type="text"
                value={priceRange}
                placeholder="Select Price Range"
                readOnly
                onClick={toggleDropdown}
              />
              {isOpen && (
                <div className={Style.drop}>
                  <div className={Style.dropDivide}>
                    <div className={Style.searchBox}>
                      <input
                        type="number"
                        value={minValue ? minValue.toString() : ""}
                        onChange={(e) => handleMinSelect(`$${e.target.value}k`)}
                        placeholder="Min Price"
                      />
                      <input
                        type="number"
                        value={maxValue ? maxValue.toString() : ""}
                        onChange={(e) => handleMaxSelect(`$${e.target.value}k`)}
                        placeholder="Max Price"
                      />
                    </div>
                    <div className={Style.dropData}>
                      <div className={Style.dropLeft}>
                        <h2>Minimum</h2>
                        <ul>
                          <li onClick={() => handleMinSelect("$50k")}>$50k</li>
                          <li onClick={() => handleMinSelect("$100k")}>
                            $100k
                          </li>
                          <li onClick={() => handleMinSelect("$150k")}>
                            $150k
                          </li>
                          <li onClick={() => handleMinSelect("$200k")}>
                            $200k
                          </li>
                          <li onClick={() => handleMinSelect("$250k")}>
                            $250k
                          </li>
                          <li onClick={() => handleMinSelect("$300k")}>
                            $300k
                          </li>
                          <li onClick={() => handleMinSelect("$500k")}>
                            $500k
                          </li>
                          <li onClick={() => handleMinSelect("$750k")}>
                            $750k
                          </li>
                          <li onClick={() => handleMinSelect("$950k")}>
                            $950k
                          </li>
                          <li onClick={() => handleMinSelect("$1M")}>$1M</li>
                        </ul>
                      </div>
                      <div className={Style.dropRight}>
                        <h2>Maximum</h2>
                        <ul>
                          <li onClick={() => handleMaxSelect("$50k")}>$50k</li>
                          <li onClick={() => handleMaxSelect("$100k")}>
                            $100k
                          </li>
                          <li onClick={() => handleMaxSelect("$150k")}>
                            $150k
                          </li>
                          <li onClick={() => handleMaxSelect("$200k")}>
                            $200k
                          </li>
                          <li onClick={() => handleMaxSelect("$250k")}>
                            $250k
                          </li>
                          <li onClick={() => handleMaxSelect("$300k")}>
                            $300k
                          </li>
                          <li onClick={() => handleMaxSelect("$350k")}>
                            $350k
                          </li>
                          <li onClick={() => handleMaxSelect("$400k")}>
                            $400k
                          </li>
                          <li onClick={() => handleMaxSelect("$750k")}>
                            $750k
                          </li>
                          <li onClick={() => handleMaxSelect("$1M")}>$1M</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={Style.timeframeBox}>
              <h2>When do you need this property?*</h2>
              <label>
                <input
                  type="radio"
                  name="timeframe"
                  value="Immediate"
                  defaultValue={selectedTimeframe}
                  onChange={(e) => setSelectedTimeframe(e.target.value)}
                />
                Immediate
              </label>
              <label>
                <input
                  type="radio"
                  name="timeframe"
                  value="Within 1 Month"
                  defaultValue={selectedTimeframe}
                  onChange={(e) => setSelectedTimeframe(e.target.value)}
                />
                Within 1 Month
              </label>
              <label>
                <input
                  type="radio"
                  name="timeframe"
                  value="Within 2 to 3 Months"
                  defaultValue={selectedTimeframe}
                  onChange={(e) => setSelectedTimeframe(e.target.value)}
                />
                Within 2 to 3 Months
              </label>
            </div>
          </div>
        </div>
        <div className={Style.bottom}>
          <div className={Style.button}>
            <button>Next</button>
          </div>
          <p>
            By Sending, you acknowledge that you have read and agree with the{" "}
            <a href="/">Bipko.info</a> <a href="/terms">Terms of Service,</a>
            <a href="/privacy-policy"> Privacy Policy</a> and Cookie Policy
          </p>
        </div>
      </div>
    </div>
  );
};

export default GetConnected;
