import React from "react";
import Style from "../style/detail.module.scss";

const Detail = () => {
  return (
    <div className={Style.Detail}>
      <div className={Style.title}>
        <h2>Simple Steps to Success!</h2>
        <p>
          Explore some of the best tips from around the world from our partners
          and friends.lacinia viverra lectus.
        </p>
      </div>
      <div className={Style.wrapper}>
        <div className={Style.content}>
          <span>
            <img
              src="https://s.alicdn.com/@img/imgextra/i3/O1CN01IqRqQr1UYmu3AOqmF_!!6000000002530-2-tps-96-96.png"
              alt="Extra"
              loading="lazy"
            />
          </span>
          <h2>Easy Registration </h2>
          <p>Sign up and get your business listed in just a few clicks.</p>
        </div>
        <div className={Style.content}>
          <span>
            <img
              src="https://s.alicdn.com/@img/imgextra/i1/O1CN01yTM8EE1GOLFPjalEn_!!6000000000612-2-tps-96-96.png"
              alt=""
            />
          </span>
          <h2>Seamless Updates </h2>
          <p>Keep your business details accurate and relevant anytime.</p>
        </div>
        <div className={Style.content}>
          <span>
            <img
              src="https://s.alicdn.com/@img/imgextra/i3/O1CN01TFyXvb261lkMrez96_!!6000000007602-2-tps-96-96.png"
              alt=""
            />
          </span>
          <h2>Boost Your Presence </h2>
          <p>Improve visibility and attract more local customers.</p>
        </div>
        <div className={Style.content}>
          <span>
            <img
              src="https://s.alicdn.com/@img/imgextra/i4/O1CN01xIFSCp1DYT9tsnHfj_!!6000000000228-2-tps-96-96.png"
              alt=""
            />
          </span>
          <h2>Grow & Engage </h2>
          <p>Connect with shoppers and turn views into loyal customers.</p>
        </div>
      </div>
    </div>
  );
};

export default Detail;
