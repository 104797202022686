import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IBlogApiResponse, ICategory, IListing, IState } from "./Article";
const fetchWithRetry = async (url: string, retries = 3) => {
  const controller = new AbortController();
  const { signal } = controller;

  for (let i = 0; i < retries; i++) {
    try {
      const response = await fetch(url, { signal });
      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      if (i === retries - 1) throw error;
    }
  }
};
interface IApiState {
  categoryData: ICategory | null;
  stateData: IState | null;
  listingData: IListing | null;
  blogs: IBlogApiResponse | null;
  isLoading: boolean;
  error: string | null;
  hasFetchedBlogs: boolean;
  hasFetchedCategory: boolean;
  hasFetchedListing: boolean;
  hasFetchedStates: boolean;
}

const initialState: IApiState = {
  categoryData: null,
  stateData: null,
  listingData: null,
  blogs: null,
  isLoading: false,
  error: null,
  hasFetchedBlogs: false,
  hasFetchedCategory: false,
  hasFetchedListing: false,
  hasFetchedStates: false,
};
export const fetchCategory = createAsyncThunk(
  "api/fetchCategory",
  async (_, { rejectWithValue }) => {
    try {
      return await fetchWithRetry("https://onedialusa.com/api/categories");
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchStates = createAsyncThunk(
  "api/fetchStates",
  async (_, { rejectWithValue }) => {
    try {
      return await fetchWithRetry("https://onedialusa.com/api/states");
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchListing = createAsyncThunk(
  "api/fetchListing",
  async (_, { rejectWithValue }) => {
    console.time("API Request");
    try {
      const response = await fetchWithRetry(
        `https://onedialusa.com/api/listings`
      );
      console.timeEnd("API Request");
      return response;
    } catch (error: any) {
      console.timeEnd("API Request");
      return rejectWithValue(error.message);
    }
  }
);

export const fetchBlogs = createAsyncThunk(
  "api/fetchBlogs",
  async (_, { rejectWithValue }) => {
    try {
      return await fetchWithRetry(`https://onedialusa.com/api/blogs`);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
const apiSlice = createSlice({
  name: "api",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategory.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCategory.fulfilled, (state, action) => {
        state.categoryData = action.payload;
        state.isLoading = false;
        state.hasFetchedCategory = true;
      })
      .addCase(fetchCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchStates.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchStates.fulfilled, (state, action) => {
        state.stateData = action.payload;
        state.isLoading = false;
        state.hasFetchedStates = true;
      })
      .addCase(fetchStates.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchListing.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchListing.fulfilled, (state, action) => {
        state.listingData = action.payload;
        state.isLoading = false;
        state.hasFetchedListing = true;
      })
      .addCase(fetchListing.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchBlogs.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchBlogs.fulfilled, (state, action) => {
        state.blogs = action.payload;
        state.isLoading = false;
        state.hasFetchedBlogs = true;
      })
      .addCase(fetchBlogs.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const { resetState } = apiSlice.actions;
export default apiSlice.reducer;
