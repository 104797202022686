import React, { forwardRef, useMemo } from "react";
import Style from "../style/productlistingdata.module.scss";
import { IListingData } from "../redux/Article";

interface IType {
  itemList: IListingData;
  index: number;
}

const ProductListingData = forwardRef<HTMLDivElement, IType>(
  ({ itemList, index }, ref) => {
    const listingUrl = `/listing/page/${
      itemList.title && itemList.title.toLowerCase().replaceAll(" ", "-")
    }`;
    const whatsappUrl = "https://www.whatsapp.com/";

    const ratingStars = useMemo(
      () =>
        Array.from({ length: 5 }).map((_, i) => (
          <i
            key={i}
            className={`fa-star ${
              i < itemList.employee_count ? "fa-solid" : "fa-regular"
            }`}
            aria-hidden="true"
          ></i>
        )),
      [itemList.employee_count]
    );
    return (
      <>
        <div
          className={`${Style.ProductData} ${
            index < 2 ? Style.changeBackground : ""
          }`}
          ref={ref}
        >
          <a href={listingUrl} aria-label={itemList.title}>
            <div className={Style.productImage}>
              <span>
                <img
                  src={
                    "https://media.istockphoto.com/id/950986656/photo/business-finance-accounting-contract-advisor-investment-consulting-marketing-plan-for-the.jpg?s=612x612&w=0&k=20&c=U-y6cADCby4QwENFptPrVcK_MplesqZmnDxUMMkJZvM="
                  }
                  alt={itemList.title || "Default Image"}
                  loading="lazy"
                  decoding="async"
                  width="300"
                  height="200"
                />
              </span>
              <div className={Style.productImageContent}>
                <div className={Style.ProductImageIcon}>
                  <i className="fa-regular fa-heart" aria-hidden="true"></i>
                  <a href="#/">
                    <i className="fa-solid fa-lock-open" aria-hidden="true"></i>{" "}
                    Open Now
                  </a>
                </div>
                <div className={Style.ProductRatingDetail}>
                  <h2>{itemList.employee_count}</h2>
                  <div className={Style.RatingDetail}>
                    <div className={Style.rating}>{ratingStars}</div>
                    <h3>{itemList.employee_count} Reviews</h3>
                  </div>
                </div>
              </div>
            </div>
          </a>
          <div className={Style.productContent}>
            <div className={Style.name}>
              <h2>{itemList.title}</h2>
              <h3>
                <i className="fa-solid fa-location-dot" aria-hidden="true"></i>
                <span>{itemList.address}</span>
              </h3>
            </div>
            <div className={Style.productParagraph}>
              <div
                className={Style.productDisc}
                dangerouslySetInnerHTML={{ __html: itemList.description }}
              />
              <div className={Style.ContactButton}>
                <a
                  href={itemList.website}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <i className="fa-solid fa-link" aria-hidden="true"></i>{" "}
                  Website
                </a>
                <a
                  href={whatsappUrl}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <i className="fa-brands fa-whatsapp" aria-hidden="true"></i>{" "}
                  WhatsApp
                </a>
                <a href="#/" target="_blank" rel="nofollow noopener noreferrer">
                  <i className="fa-solid fa-clipboard-question"></i> Enquiry Now
                </a>
              </div>
            </div>
            <div className={Style.category}>
              <h2>{itemList.category_name}</h2>
              <h3>
                <i className="fa-solid fa-phone" aria-hidden="true"></i>{" "}
                {itemList.phone}
              </h3>
            </div>
          </div>
        </div>
        {index % 3 === 2 && index !== -1 && (
          <div className={Style.advertisementBox}>
            <div className={Style.adImageContainer}>
              <img
                src="https://via.placeholder.com/600x200?text=Business+Trading+Opportunities"
                alt="Advertisement"
                className={Style.adImage}
              />
            </div>
            <div className={Style.adContent}>
              <h2>Exclusive Offer - Limited Time Only!</h2>
              <p>Get 15% off on your next purchase!</p>
              <a
                href="https://your-promotion-link.com"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className={Style.adButton}
              >
                Shop Now
              </a>
            </div>
          </div>
        )}
      </>
    );
  }
);

export default React.memo(ProductListingData);
