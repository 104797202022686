import React from "react";
import Style from "../style/claim.module.scss";

const Claim = () => {
  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.content}>
          <div className={Style.Image}>
            <img
              src="https://www.paychex.com/sites/default/files/image/2022-12/guide-small-business-tax-thumbnail.jpg"
              alt="Guide"
              loading="lazy"
            />
          </div>
          <div className={Style.mainbox}>
            <div className={Style.text}>
              <h2>
                Manage Your <span>Free</span> Listing – Your Business, Your
                Control!
              </h2>
              <p>
                Update your details in a few simple steps and boost your online
                presence.
              </p>
              <h3>
                <span>New!</span> Post a job opening on your free listing.
              </h3>
              <a href="#/">Claim Your Listing</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Claim;
