import React, { useState, useRef } from "react";
import Style from "../style/answersubmit.module.scss";

const AnswerBox = ({ onClose }: any) => {
  const [text, setText] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length <= 5000) {
      setText(e.target.value);
    }
  };

  const handleCameraClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className={Style.container}>
      <div className={Style.answerBox}>
        <div className={Style.formbox}>
          <div className={Style.button}>
            <h2 onClick={onClose}>Close</h2>
            <h3>Submit</h3>
          </div>
          <textarea
            className={Style.textarea}
            placeholder="Write Your Answer..."
            value={text}
            onChange={handleTextChange}
            maxLength={5000}
          />
          <div className={Style.count}>
            <span>
              <img
                src="https://www.answers.com/icons/cameraIcon.svg"
                alt="Icon"
                loading="lazy"
              />
            </span>
            <p>{text.length} / 5000</p>
          </div>
          <div className={Style.changes}>
            <span>
              <b>B</b>
            </span>
            <span>
              <u>U</u>
            </span>
            <span>
              <i>I</i>
            </span>

            <span onClick={handleCameraClick} style={{ cursor: "pointer" }}>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <text
                  x="1"
                  y="6"
                  fontSize="5"
                  fill="currentColor"
                  fontFamily="Arial"
                  fontWeight="bold"
                >
                  1.
                </text>
                <text
                  x="1"
                  y="11"
                  fontSize="5"
                  fill="currentColor"
                  fontFamily="Arial"
                  fontWeight="bold"
                >
                  2.
                </text>
                <text
                  x="1"
                  y="16"
                  fontSize="5"
                  fill="currentColor"
                  fontFamily="Arial"
                  fontWeight="bold"
                >
                  3.
                </text>
                <path
                  d="M6 4H16M6 9H16M6 14H16"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </span>
            <span>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="2.5" cy="4" r="1.5" fill="currentColor" />
                <circle cx="2.5" cy="9" r="1.5" fill="currentColor" />
                <circle cx="2.5" cy="14" r="1.5" fill="currentColor" />
                <path
                  d="M6 4H16M6 9H16M6 14H16"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnswerBox;
