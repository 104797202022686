import React from "react";
import Style from "../../style/relatorsleftside.module.scss";

const RealtorCard = ({ itemList, handleRelator }: any) => {
  return (
    <div className={Style.card}>
      <div className={Style.fullData}>
        <div className={Style.header}>
          <span>
            <img src={itemList.img} alt={itemList.name} />
          </span>
          <div className={Style.text}>
            <a href="#/">{itemList.name}</a>
            <h2>{itemList.location}</h2>
            <ul>
              <li>
                <i className="fa-solid fa-phone"></i>
                {itemList.contact.phone}
              </li>
              <li>
                <i className="fa-solid fa-envelope"></i>
                {itemList.contact.email}
              </li>
              <li>
                <i className="fa-solid fa-globe"></i>
                <a href={itemList.contact.website}>
                  {" "}
                  {itemList.contact.website}
                </a>
              </li>
              <li>
                <i className="fa-solid fa-location-dot"></i>
                {itemList.address}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={Style.detail}>
        <ul>
          <li>
            Experience
            <span>{itemList.experience}</span>
          </li>
          <li>
            Language<span>{itemList.languages_known}</span>
          </li>
          <li>
            Properties Listed<span>{itemList.properties_listed}</span>
          </li>
          <li>
            Price<span>{itemList.price_range}</span>
          </li>
          <li>
            Established<span>{itemList.established_since}</span>
          </li>
        </ul>
      </div>
      <div className={Style.description}>
        <p>{itemList.description}</p>
      </div>
      <div className={Style.properties}>
        <ul>
          <li>
            For Sale <span>{itemList.properties.for_sale}</span>
          </li>
          <li>
            For Rent <span>{itemList.properties.for_rent}</span>
          </li>
        </ul>
      </div>
      <div className={Style.bottom}>
        <h2>Serving Across Multiple Cities</h2>
        <button onClick={handleRelator}>Contact Agent</button>
      </div>
    </div>
  );
};

export default RealtorCard;
