import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { fetchCategory, fetchListing, fetchStates } from "../redux/Slice";
import { useParams, Link } from "react-router-dom";
import { getProductsFromDB, saveProductsToDB } from "../indexDb/productDb";
import { getCategoriesFromDB, saveCategoriesToDB } from "../indexDb/categoryDb";
import { getStatesFromDB, saveStatesToDB } from "../indexDb/stateDb";
import Style from "../style/productlanding.module.scss";
import ProductReview from "./ProductReview";

const ProductLanding: React.FC = () => {
  const { userTitle } = useParams<{ userTitle?: string }>();
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.detail);

  const [localData, setLocalData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [relatedItems, setRelatedItems] = useState([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [todayStatus, setTodayStatus] = useState<string>("Closed");
  const [tomorrowHours, setTomorrowHours] = useState<string>("Closed");

  const business = filteredData[0];

  const time: any = {
    hours: {
      monday: { open: "08:00", close: "17:00" },
      tuesday: { open: "08:00", close: "17:00" },
      wednesday: { open: "08:00", close: "17:00" },
      thursday: { open: "08:00", close: "17:00" },
      friday: { open: "08:00", close: "17:00" },
      saturday: { open: "09:00", close: "14:00" },
      sunday: null,
    },
  };

  const checkBusinessHours = useCallback(() => {
    const now = new Date();
    const today = now
      .toLocaleString("en-US", { weekday: "long" })
      .toLowerCase();
    const tomorrow = new Date();
    tomorrow.setDate(now.getDate() + 1);
    const tomorrowDay = tomorrow
      .toLocaleString("en-US", { weekday: "long" })
      .toLowerCase();

    const currentTime = now.getHours() * 60 + now.getMinutes();
    const todayHours = time.hours[today];

    if (todayHours) {
      const [openHour, openMin] = todayHours.open.split(":").map(Number);
      const [closeHour, closeMin] = todayHours.close.split(":").map(Number);
      const openTime = openHour * 60 + openMin;
      const closeTime = closeHour * 60 + closeMin;

      if (currentTime >= openTime && currentTime < closeTime) {
        setIsOpen(true);
        setTodayStatus(`${todayHours.open} - ${todayHours.close}`);
      } else {
        setIsOpen(false);
        setTodayStatus("Closed");
      }
    } else {
      setIsOpen(false);
      setTodayStatus("Closed");
    }

    if (time.hours[tomorrowDay]) {
      setTomorrowHours(
        `${time.hours[tomorrowDay].open} - ${time.hours[tomorrowDay].close}`
      );
    } else {
      setTomorrowHours("Closed");
    }
  }, [time.hours]);

  useEffect(() => {
    checkBusinessHours();
    const timer = setInterval(checkBusinessHours, 60000);
    return () => clearInterval(timer);
  }, [checkBusinessHours]);

  useEffect(() => {
    const fetchData = async () => {
      const dbData = await getProductsFromDB();
      if (dbData.length > 0) {
        setLocalData(dbData);
      } else {
        const action = await dispatch(fetchListing());
        if (action.payload?.listings) {
          saveProductsToDB(action.payload.listings);
          setLocalData(action.payload.listings);
        }
      }

      const dbCategories: any = await getCategoriesFromDB();
      if (dbCategories.length > 0) {
        dispatch({
          type: "detail/setCategoryData",
          payload: { categories: dbCategories },
        });
      } else {
        const categoryAction = await dispatch(fetchCategory());
        if (categoryAction.payload?.categories) {
          saveCategoriesToDB(categoryAction.payload.categories);
          dispatch({
            type: "detail/setCategoryData",
            payload: { categories: categoryAction.payload.categories },
          });
        }
      }

      const dbCities = await getStatesFromDB();
      if (dbCities.length > 0) {
        dispatch({ type: "detail/setCityData", payload: { cities: dbCities } });
      } else {
        const stateAction = await dispatch(fetchStates());
        if (stateAction.payload?.cities) {
          saveStatesToDB(stateAction.payload.cities);
          dispatch({
            type: "detail/setCityData",
            payload: { cities: stateAction.payload.cities },
          });
        }
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (userTitle) {
      const filteredItem = localData.filter(
        (item) =>
          item.title &&
          item.title.toLowerCase().replaceAll(" ", "-") === userTitle
      );
      if (filteredItem.length > 0) {
        setFilteredData(filteredItem);
        const category =
          filteredItem[0]?.category_name || filteredItem[0]?.Category;
        console.log("Category:", category);
        const related: any = localData.filter(
          (item) => item.category_name === category && item.title !== userTitle
        );
        console.log("Related Items:", related);
        setRelatedItems(related);
      } else {
        setFilteredData([]);
        setRelatedItems([]);
      }
    }
  }, [localData, userTitle]);

  const renderStars = (rating: number) => {
    const totalStars = 5;
    return (
      <div className={Style.stars}>
        {[...Array(totalStars)].map((_, index) => (
          <i
            key={index}
            className={
              index < rating ? "fa-solid fa-star" : "fa-regular fa-star"
            }
          />
        ))}
      </div>
    );
  };

  const getFirstParagraph = (html: any) => {
    if (!html) return "<p>No content available.</p>";

    const match = html.match(/<p[^>]*>.*?<\/p>/);
    return match ? match[0] : "<p>No paragraph found.</p>";
  };

  return (
    <div className={Style.container}>
      <nav className={Style.breadcrumb}>
        <ul>
          <li>
            <Link to="/">
              Home <i className="fa-solid fa-angle-right"></i>
            </Link>
          </li>
          <li>
            <Link to="/listing">
              Listing <i className="fa-solid fa-angle-right"></i>
            </Link>
          </li>
          {business?.category_name && (
            <li>
              <Link
                to={`/listing/category/${business.category_name
                  .toLowerCase()
                  .replaceAll(" ", "-")}`}
              >
                {business.category_name}
                <i className="fa-solid fa-angle-right"></i>
              </Link>
            </li>
          )}
          {business?.title && <span>{business.title}</span>}
        </ul>
      </nav>
      <div className={Style.divide}>
        <div className={Style.leftside}>
          <div className={Style.address}>
            <ul>
              <li>
                <i className="fa-solid fa-phone"></i>
                <a href="#/">{business?.phone}</a>
              </li>
              <li>
                <i className="fa-solid fa-arrow-up-right-from-square"></i>
                <a href={business?.website}>Visit Website</a>
              </li>
              <li>
                <i className="fa-solid fa-location-dot"></i>
                <a href="#/">
                  <span>Map & Directions</span>
                  <br />
                  {business?.address}
                </a>
              </li>
              <li>
                <i className="fa-regular fa-star"></i>
                <a href="#/">Write a Review</a>
              </li>
            </ul>
          </div>
          <div className={Style.time}>
            <h2>Hours</h2>
            <h3>Regular Hours</h3>
            <ul>
              <li>
                Mon - Thu: <span>11:00 am - 1:00 am</span>
              </li>
              <li>
                Fri - Sat: <span>11:00 am - 2:00 am</span>
              </li>
              <li>
                Sun: <span>11:00 am - 12:00 am</span>
              </li>
            </ul>
          </div>
          <div className={Style.businessdata}>
            <div className={Style.Customizedata}>
              <span>
                <h2>Is this your business?</h2>
                <p>Customize this page.</p>
              </span>
              <img
                src="https://i1.ypcdn.com/ypu/images/svgs/business-icon.svg?496f2a9"
                alt=""
              />
            </div>
            <div className={Style.claimdata}>
              <a href="#/">Claim This Business</a>
            </div>
          </div>
          <div className={Style.category}>
            <h2>Browse by Categories</h2>
            <ul>
              {data.categoryData?.categories?.map((item) => (
                <li key={item.id}>
                  <Link
                    to={`/listing/category/${item.name
                      .toLowerCase()
                      .replaceAll(" ", "-")}`}
                  >
                    <i className={item.icon}></i>
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className={Style.cities}>
            <h2>Browse by Cities</h2>
            <ul>
              {data.stateData?.states?.map((item) => (
                <li key={item.id}>
                  <Link
                    to={`/listing/city/${item.name
                      .toLowerCase()
                      .replaceAll(" ", "-")}`}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={Style.rightside}>
          <section className={Style.details}>
            <div className={Style.imageSection}>
              <img
                src={`https://media.istockphoto.com/id/950986656/photo/business-finance-accounting-contract-advisor-investment-consulting-marketing-plan-for-the.jpg?s=612x612&w=0&k=20&c=U-y6cADCby4QwENFptPrVcK_MplesqZmnDxUMMkJZvM=`}
                alt={business?.title}
                loading="lazy"
                className={Style.mainImage}
              />
            </div>
            <div className={Style.info}>
              <h2>{business?.title}</h2>
              <ul>
                <li>
                  <i className="fa-solid fa-map-marker-alt"></i>{" "}
                  <p>{business?.address}</p>
                </li>
                <li>
                  {renderStars(business?.average_rating || 0)} (
                  {business?.whatsapp_status} Reviews)
                </li>
                <li>
                  <i className="fa-regular fa-clock"></i>
                  <span style={{ color: isOpen ? "green" : "red" }}>
                    {isOpen ? "OPEN NOW" : "CLOSED NOW"}
                  </span>
                  <p>Today: {todayStatus}</p>
                  <p>Tomorrow: {tomorrowHours}</p>
                </li>
              </ul>
            </div>
          </section>
          <div className={Style.description}>
            <h3>More About Bussiness</h3>
            <div className={Style.detail}>
              <ul>
                <li>
                  <h2>General Info</h2>
                  <div
                    className={Style.data}
                    dangerouslySetInnerHTML={{
                      __html: getFirstParagraph(business?.description),
                    }}
                  />
                </li>
                <li>
                  <h2>Email</h2>
                  <a href={`/${business?.email}`}>{business?.email}</a>
                </li>
                <li>
                  <h2>Services/Products</h2>
                  <p>{business?.services}</p>
                </li>
                <li>
                  <h2>Brands</h2>
                  <p>{business?.brands}</p>
                </li>
                <li>
                  <h2>Payment method</h2>
                  <p>{business?.payment_options}</p>
                </li>
                <li>
                  <h2>Location</h2>
                  <p>{business?.address}</p>
                </li>
                <li>
                  <h2>Amenities</h2>
                  <p>{business?.amenities[2]}</p>
                </li>
                <li>
                  <h2>Social Links</h2>
                  <a href={business?.facebook}>
                    <i className="fa-brands fa-square-facebook"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <section className={Style.reviewSection}>
            <ProductReview renderStars={renderStars} />
          </section>
          <div className={Style.itemDetail}>
            <h2>Details</h2>
            <ul>
              <li>
                <h3>Phone</h3>
                <p>: (844) 699-3144</p>
              </li>
              <li>
                <h3>Address</h3>
                <p>: 11060 W Pico Blvd, Los Angeles, CA 90064</p>
              </li>
              <li>
                <h3>Website</h3>
                <a href={business?.website}>{business?.website}</a>
              </li>
            </ul>
          </div>
          <div className={Style.relatedItems}>
            <h2>Related Item</h2>
            <ul>
              {relatedItems &&
                relatedItems.slice(0, 16).map((item: any) => {
                  return (
                    <li>
                      <a
                        href={`/listing/page/${item.title
                          .toLowerCase()
                          .replaceAll(" ", "-")}`}
                      >
                        {item.title}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductLanding;
