import React, { useState } from "react";
import Style from "../../style/requirement.module.scss";
import GetConnected from "./GetConnected";

const Requirement = () => {
  const [show, setShow] = useState(false);
  const handleClick = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const data = [
    { id: "1", name: "Homes for Sale in New York, NY" },
    { id: "2", name: "Homes for Sale in Los Angeles, CA" },
    { id: "3", name: "Homes for Sale in Chicago, IL" },
    { id: "4", name: "Homes for Sale in Houston, TX" },
    { id: "5", name: "Homes for Sale in Phoenix, AZ" },
    { id: "6", name: "Homes for Sale in Philadelphia, PA" },
    { id: "7", name: "Homes for Sale in San Antonio, TX" },
    { id: "8", name: "Homes for Sale in San Diego, CA" },
    { id: "9", name: "Homes for Sale in Dallas, TX" },
    { id: "10", name: "Homes for Sale in San Jose, CA" },
    { id: "11", name: "Homes for Sale in Austin, TX" },
    { id: "12", name: "Homes for Sale in Jacksonville, FL" },
    { id: "13", name: "Homes for Sale in Fort Worth, TX" },
    { id: "14", name: "Homes for Sale in Columbus, OH" },
    { id: "15", name: "Homes for Sale in Charlotte, NC" },
    { id: "16", name: "Homes for Sale in Indianapolis, IN" },
    { id: "17", name: "Homes for Sale in San Francisco, CA" },
    { id: "18", name: "Homes for Sale in Seattle, WA" },
    { id: "19", name: "Homes for Sale in Denver, CO" },
    { id: "20", name: "Homes for Sale in Washington, DC" },
  ];

  return (
    <div className={Style.container}>
      <>{show && <GetConnected handleClose={handleClose} />}</>
      <div className={Style.wrapper}>
        <div className={Style.divide}>
          <div className={Style.leftAside}>
            <h2>Share your requirement</h2>
            <p>
              Please provide your requirement like property type, location and
              budget. Sulekha will connect with perfect property owners/agents.
            </p>
            <button onClick={handleClick}>Get Connected</button>
          </div>
          <div className={Style.rightAside}>
            <div className={Style.text}>
              <h2>Popular Cities</h2>
              <ul>
                {data &&
                  data.map((item) => {
                    return (
                      <li>
                        <a href="#/">{item.name}</a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Requirement;
