import React from "react";
import Style from "../../style/homeservices.module.scss";

const HomeServices = () => {
  const data = [
    {
      id: 1,
      img: "https://assets.streamlinehq.com/image/private/w_240,h_240,ar_1/f_auto/v1/icons/transfer/transfer-van-03ywhiaayv0q7orgl6mn5pr.png/transfer-van-swf27es5dtjtx5ixyj0f9.png?_a=DAJFJtWIZAAC",
      service: "Packers & Movers",
      content: "5 star rated home shifting service for smooth moving",
    },
    {
      id: 2,
      img: "https://assets.streamlinehq.com/image/private/w_240,h_240,ar_1/f_auto/v1/icons/building-store/building-2-49lj4k1m0wec4m1ew0gmhw.png/building-2-inrxwwzr1iaca63hoxldh.png?_a=DAJFJtWIZAAC",
      service: "Home Interiors",
      content:
        "Get design and decor ideas for every room in your home beautiful",
    },
    {
      id: 3,
      img: "https://assets.streamlinehq.com/image/private/w_240,h_240,ar_1/f_auto/v1/icons/furniture/shelf-wu77lsuu21dzg8tjokztl.png/shelf-6h8z9xwevx4n6059q3arh7.png?_a=DAJFJtWIZAAC",
      service: "Home Loans",
      content:
        "Looking for a new home loan, or want to refinance your current loan for cash back?",
    },
    {
      id: 4,
      img: "https://assets.streamlinehq.com/image/private/w_240,h_240,ar_1/f_auto/v1/icons/health-appointments/insurance-hand-2-2r9my5qcsurodrd9olze1o.png/insurance-hand-2-pm3f24usq1a5md38xjio2c.png?_a=DAJFJtWIZAAC",
      service: "Home Insurance",
      content:
        "Get a free home insurance quote online and work with an agent to find the right coverage",
    },
    {
      id: 5,
      img: "https://assets.streamlinehq.com/image/private/w_240,h_240,ar_1/f_auto/v1/icons/places/building-real-estate-vrie2e3ld6e076qqcjhoe6j.png/building-real-estate-of727f9tz4usm46objx8.png?_a=DAJFJtWIZAAC",
      service: "Property Management",
      content:
        "Screening tenants, drafting, signing, and renewing leases on behalf of property owners",
    },
  ];
  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.title}>
          <h2>Home Related Services</h2>
        </div>
        <div className={Style.mainbox}>
          {data &&
            data.map((item) => {
              return (
                <div className={Style.content}>
                  <span>
                    <img src={item.img} alt={item.service} />
                  </span>
                  <h2>{item.service}</h2>
                  <p>{item.content}</p>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default HomeServices;
