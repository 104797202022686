import React, { useEffect, useState } from "react";
import Style from "../../style/eventorganiser.module.scss";

const EventOrganiser = () => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    fetch("/data/event.json")
      .then((res) => res.json())
      .then((res) => setData(res.events));
  }, []);

  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.title}>
          <h2>Featured Organizers</h2>
          <p>Leading event organisers</p>
        </div>
        <div className={Style.mainbox}>
          <ul>
            {[...data.slice(0, 8), ...data.slice(0, 8)].map((item, index) => (
              <li key={index}>
                <a
                  href={`/event/${item.organizer.name
                    .toLowerCase()
                    .replaceAll(" ", "-")}`}
                >
                  <img
                    src={item.floor_plan.image_url}
                    alt={item.organizer.name}
                  />
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className={Style.button}>
          <a href="#/">View All</a>
        </div>
      </div>
    </div>
  );
};

export default EventOrganiser;
