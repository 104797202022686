import React, { useEffect, useState } from "react";
import Style from "../style/city.module.scss";
import { useAppDispatch } from "../redux/hooks";
import { fetchStates } from "../redux/Slice";
import { getStatesFromDB, saveStatesToDB } from "../indexDb/stateDb";
import staticCities from "../json/state.json";

const City = React.memo(() => {
  const dispatch = useAppDispatch();
  const [localCities, setLocalCities] = useState<any[]>([]);

  const baseUrl = "https://onedialusa.com/assets/img/location/city/";

  useEffect(() => {
    const loadCities = async () => {
      const cachedData = await getStatesFromDB();
      const cachedCities = cachedData.flatMap((state) => state.cities || []);

      if (cachedCities.length > 0) {
        setLocalCities(cachedCities.slice(0, 14));
        preloadImages(cachedCities.slice(0, 14));
      } else {
        setLocalCities(staticCities.slice(0, 14));
        preloadImages(staticCities.slice(0, 14));
        fetchFreshCities([]);
      }
    };

    loadCities();
  }, [dispatch]);

  const fetchFreshCities = async (cachedCities: any[]) => {
    try {
      const response = await dispatch(fetchStates()).unwrap();
      if (response?.states) {
        const newCities = response.states.flatMap(
          (state: any) => state.cities || []
        );

        if (!areCitiesEqual(cachedCities, newCities)) {
          await saveStatesToDB(response.states);
          setLocalCities(newCities.slice(0, 14));
          preloadImages(newCities.slice(0, 14));
        }
      }
    } catch (error) {
      console.error("Failed to fetch states:", error);
    }
  };

  const areCitiesEqual = (cachedCities: any[], newCities: any[]) => {
    if (cachedCities.length !== newCities.length) return false;
    const cachedIds = cachedCities.map((city) => city.id).sort();
    const newIds = newCities.map((city) => city.id).sort();
    return JSON.stringify(cachedIds) === JSON.stringify(newIds);
  };

  const preloadImages = (cities: any[]) => {
    cities.slice(0, 3).forEach((city) => {
      const link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = `${baseUrl}${city.feature_image}?w=150&h=100&format=webp`;
      document.head.appendChild(link);
    });
  };

  const handleClick = () => {
    window.location.pathname = "/all-cities";
  };

  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.title}>
          <h2>Explore Top Cities</h2>
          <div className={Style.button}>
            <span onClick={handleClick}>Show All Cities</span>
          </div>
        </div>
        <div className={Style.content}>
          {localCities.map((item) => (
            <a
              href={`/listing/city/${item.name
                .toLowerCase()
                .replaceAll(" ", "-")}`}
              key={item.id}
            >
              <span>
                <img
                  src={`${baseUrl}${item.feature_image}?w=150&h=100&format=webp`}
                  srcSet={`
                      ${baseUrl}${item.feature_image}?w=150&h=100&format=webp 150w,
                      ${baseUrl}${item.feature_image}?w=300&h=200&format=webp 300w
                    `}
                  sizes="(max-width: 768px) 150px, 300px"
                  alt={item.name}
                  width="150"
                  height="100"
                  loading="lazy"
                  decoding="async"
                />
              </span>
              <div className={Style.overlay}>
                <h2>{item.name}</h2>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
});

export default City;
