import React, { useEffect, useState } from "react";
import Style from "../../style/featureevent.module.scss";

const FeatureEvent = () => {
  const [data, setData] = useState<any | []>([]);
  useEffect(() => {
    fetch("/data/event.json")
      .then((value) => {
        return value.json();
      })
      .then((res) => {
        setData(res.events);
      });
  }, []);

  const formatEventDate = (start: string, end: string) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const startDay = startDate.toLocaleDateString("en-US", {
      weekday: "short",
      day: "2-digit",
    });

    const endDay = endDate.toLocaleDateString("en-US", {
      weekday: "short",
      day: "2-digit",
    });

    const monthYear = endDate.toLocaleDateString("en-US", {
      month: "short",
      year: "numeric",
    });

    return `${startDay} - ${endDay} ${monthYear}`;
  };
  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.title}>
          <h2>Featured Events</h2>
          <p>Handpicked popular events</p>
        </div>
        <div className={Style.mainbox}>
          <ul>
            {data.slice(0, 9).map((item: any) => {
              return (
                <li>
                  <a
                    href={`/event/${item.title
                      .toLowerCase()
                      .replaceAll(" ", "-")}`}
                  >
                    <div className={Style.eventDivide}>
                      <div className={Style.eventFile}>
                        <div className={Style.date}>
                          <h2>
                            {formatEventDate(item.start_date, item.end_date)}
                          </h2>
                        </div>
                        <div className={Style.contentTitle}>
                          <h2>{item.title}</h2>
                        </div>
                        <div className={Style.city}>
                          <h2>
                            {item.venue.city} , <span>{item.venue.state}</span>
                          </h2>
                        </div>
                      </div>
                      <div className={Style.eventImage}>
                        <img src={item.floor_plan.image_url} alt={item.title} />
                      </div>
                    </div>
                    <div className={Style.detail}>
                      <div className={Style.category}>
                        {item.categories.map((item: any) => {
                          return <h2>{item}</h2>;
                        })}
                      </div>
                      <div className={Style.Icon}>
                        <i className="fa-solid fa-share-nodes"></i>
                      </div>
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={Style.button}>
          <a href="/login">Add Event</a>
        </div>
      </div>
    </div>
  );
};

export default FeatureEvent;
