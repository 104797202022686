import React from "react";
import Style from "../../style/realstatelanding.module.scss";
import RealStateLandingLeft from "./RealStateLandingLeft";
import Article from "../../component/Article";
import Question from "../../component/Question";
import RealStateLandingRight from "./RealStateLandingRight";

const RealStateLanding = () => {
  const data = [
    {
      id: 1,
      category: "real-estate",
      city: "New York, NY",
      name: "30 Libby Avenue",
      address: "New York, NY",
      price: "$1,234",
      pin: "10292",
      type: "Condos",
      area: "2345 sqft",
      phone: "832-648-2109",
      built: "2000",
      propertyage: "25+",
      img: "https://homes.sulekha.com/nriproperty/common/images/other/default-listing-img.jpg",
      rate: "$1,234 per Sq Ft",
      room: "5",
      bath: "8",
      saleType: "Resale",
      text: "This stunning neo-gothic home offers a myriad of possibilities with a 30' wide lot, a width unheard of in today's market. Currently set up as an owners triplex with a meticulously landscaped garden, with rental income from 4 apartments and a street level office. T",
      agentImg:
        "https://usimg.sulekha.io/cdn/nriproperty/images/thumbnail/nriproperty_2022-01-23-08-55-36-412_40.jpeg",
      agentName: "Nick Anable",
      properties: 4,
      amenities: [
        {
          name: "Parking",
          icon: "fa-solid fa-square-parking",
        },
        {
          name: "Security",
          icon: "fa-solid fa-shield-halved",
        },
        {
          name: "Swimming Pool",
          icon: "fa-solid fa-person-swimming",
        },
        {
          name: "Garden",
          icon: "fa-solid fa-seedling",
        },
        {
          name: "Play Area",
          icon: "fa-solid fa-child",
        },
        {
          name: "Gym",
          icon: "fa-solid fa-dumbbell",
        },
        {
          name: "Clubhouse",
          icon: "fa-solid fa-music",
        },
      ],
      parkingAvailability: "Available",
      flooringType: "Marble",
    },
  ];
  return (
    <div className={Style.container}>
      {data.map((item) => {
        return (
          <div className={Style.divide}>
            <div className={Style.wrapper}>
              <div className={Style.content}>
                <div className={Style.type}>
                  <h2>{item.type}</h2>
                  <h3>{item.area}</h3>
                  <h4>{item.built}</h4>
                </div>
                <div className={Style.detail}>
                  <h2>
                    {item.name},{item.address}
                  </h2>
                  <div className={Style.roomDetail}>
                    <ul>
                      <li>
                        <i className="fa-solid fa-bed"></i> {item.room} Bed
                        Rooms
                      </li>
                      <li>
                        <i className="fa-solid fa-shower"></i>
                        {item.bath} Bath Rooms
                      </li>
                    </ul>
                    <h3>
                      <i className="fa-solid fa-calendar"></i>Property Age -
                      {item.propertyage}years, <span>{item.saleType}</span>
                    </h3>
                  </div>
                </div>
                <div className={Style.relator}>
                  <div className={Style.itemPrice}>
                    <h2>List Price</h2>
                    <h3>{item.price}</h3>
                    <h4>Price Per Square - {item.rate}</h4>
                  </div>
                  <div className={Style.agentDetail}>
                    <div className={Style.agentImage}>
                      <img src={item.agentImg} alt={item.agentName} />
                    </div>
                    <div className={Style.agentData}>
                      <ul>
                        <li>{item.agentName}</li>
                        <li>Agent</li>
                        <li>
                          {" "}
                          <a href="/listing/realstate">View all property</a>
                        </li>
                        <li>
                          <i className="fa-solid fa-phone"></i>
                          {item.phone}
                        </li>
                        <li>(Pin{item.pin})</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.Image}>
                <img src={item.img} alt={item.name} />
              </div>
            </div>
          </div>
        );
      })}
      <div className={Style.mainbox}>
        <div className={Style.wrapper}>
          <div className={Style.leftAside}>
            {data &&
              data.map((item) => {
                return <RealStateLandingLeft itemList={item} />;
              })}
          </div>
          <div className={Style.rightAside}>
            {data &&
              data.map((item) => {
                return <RealStateLandingRight itemList={item} />;
              })}
          </div>
        </div>
      </div>
      <Article />
      <Question />
    </div>
  );
};

export default RealStateLanding;
