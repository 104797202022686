import React from "react";
import Style from "../../style/eventsubscribe.module.scss";

const EventSubscribe = () => {
  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.mainbox}>
          <div className={Style.title}>
            <h2>Never miss out on good events</h2>
          </div>
          <div className={Style.content}>
            <div className={Style.Image}>
              <span>
                <img
                  src="https://s.repetitor.ru/image/2023/09/blog_65141d6ba90fd.svg"
                  alt="Subscribe"
                />
              </span>
              <p>
                Sit back and relax, We will do all the hard work and give you
                the best
              </p>
            </div>
            <div className={Style.formBox}>
              <form>
                <input type="Email" placeholder="Enter Email to Subscribe" />
                <button>Subscribe</button>
              </form>
              <div className={Style.attached}>
                <div className={Style.socialLink}>
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJg75LWB1zIJt1VTZO7O68yKciaDSkk3KMdw&s"
                    alt=""
                  />
                  <span>Continue With Google</span>
                </div>
                <div className={Style.socialLink}>
                  <img
                    src="https://i.pinimg.com/474x/18/7e/51/187e511cdb50bf5614bc5dbc29eaeed8.jpg"
                    alt=""
                  />
                  <span>Continue With Linkedin</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventSubscribe;
