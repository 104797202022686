import React, { useEffect, useMemo, useState } from "react";
import Style from "../style/categorydata.module.scss";
import { useAppDispatch } from "../redux/hooks";
import { fetchCategory } from "../redux/Slice";
import { saveCategoriesToDB, getCategoriesFromDB } from "../indexDb/categoryDb";

interface Category {
  id: number;
  name: string;
  subcategories?: { id: number; name: string; category_id: number }[];
}

const CategoryData = () => {
  const [query, setQuery] = useState("");
  const [cachedCategories, setCachedCategories] = useState<Category[]>(
    JSON.parse(localStorage.getItem("cachedCategories") || "[]") // 🚀 Show immediately
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const loadCategories = async () => {
      try {
        // ✅ Get categories from IndexedDB first
        const storedCategories = await getCategoriesFromDB();

        if (storedCategories.length > 0) {
          setCachedCategories(storedCategories); // 🚀 Update state instantly
          localStorage.setItem(
            "cachedCategories",
            JSON.stringify(storedCategories)
          ); // Save for next load
        }

        // ✅ Fetch latest data from API in the background
        dispatch(fetchCategory())
          .unwrap()
          .then(async (response) => {
            if (response?.categories?.length > 0) {
              const newCategories = response.categories;

              // ✅ Only update IndexedDB if API data is different
              if (
                JSON.stringify(storedCategories) !==
                JSON.stringify(newCategories)
              ) {
                await saveCategoriesToDB(newCategories);
                setCachedCategories(newCategories);
                localStorage.setItem(
                  "cachedCategories",
                  JSON.stringify(newCategories)
                ); // Save new data
              }
            }
          })
          .catch((error) => console.error("Error fetching categories:", error));
      } catch (error) {
        console.error("Error loading categories:", error);
      }
    };

    loadCategories();
  }, [dispatch]);

  const filterData = useMemo(() => {
    if (!query.trim()) return cachedCategories;
    return cachedCategories.filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );
  }, [query, cachedCategories]);

  return (
    <div className={Style.StateContainer}>
      <div className={Style.wrapper}>
        <div className={Style.SearchCategory}>
          <h2 className={Style.title}>All Categories</h2>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className={Style.box}>
              <input
                type="text"
                placeholder="Search Category"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <button type="submit">
                <i className="fa-solid fa-magnifying-glass"></i>
              </button>
            </div>
          </form>
        </div>

        <div className={Style.StateList}>
          {filterData.map((category) => (
            <div key={category.id} className={Style.StateBox}>
              <div className={Style.StateCard}>
                <a
                  href={`/listing/category/${category.name
                    .toLowerCase()
                    .replaceAll(" ", "-")}`}
                >
                  <h2>{category.name}</h2>
                </a>
              </div>

              {category.subcategories && category.subcategories.length > 0 && (
                <div className={Style.CityList}>
                  {category.subcategories.map((subcategory) => (
                    <a
                      key={subcategory.id}
                      href={`/listing/${subcategory.name
                        .toLowerCase()
                        .replaceAll(" ", "-")}`}
                      className={Style.city}
                    >
                      {subcategory.name}
                    </a>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryData;
