import React, { useState } from "react";
import Style from "../../../style/notificationform.module.scss";

const NotificationForm = ({ handleNotificationForm }: any) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    budget: "",
    location: "",
    notification: "",
    bedrooms: "",
    bathroom: "",
    propertyType: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    budget: false,
    location: false,
    notification: false,
    bedrooms: false,
    bathroom: false,
    propertyType: false,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: false }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let validationErrors: any = {};

    Object.keys(formData).forEach((key) => {
      if (!formData[key as keyof typeof formData].trim()) {
        validationErrors[key] = true;
      }
    });

    if (Object.values(validationErrors).includes(true)) {
      setErrors(validationErrors);
      return;
    }

    console.log("Form Submitted Successfully", formData);
  };

  return (
    <div className={Style.overlay}>
      <div className={Style.wrapper}>
        <div className={Style.header}>
          <h2>Get notified for new homes</h2>
          <h3 onClick={handleNotificationForm}>X</h3>
        </div>
        <div className={Style.formBox}>
          <form onSubmit={handleSubmit}>
            <div className={Style.divide}>
              <div className={Style.box}>
                <label className={errors.name ? Style.errorLabel : ""}>
                  Enter your Name <span>*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className={Style.box}>
                <label className={errors.bedrooms ? Style.errorLabel : ""}>
                  Bedrooms <span>*</span>
                </label>
                <select
                  name="bedrooms"
                  value={formData.bedrooms}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option>1+</option>
                  <option>2+</option>
                  <option>3+</option>
                  <option>4+</option>
                  <option>5+</option>
                </select>
              </div>
            </div>
            <div className={Style.divide}>
              <div className={Style.box}>
                <label className={errors.email ? Style.errorLabel : ""}>
                  Enter your Email <span>*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className={Style.box}>
                <label className={errors.bathroom ? Style.errorLabel : ""}>
                  Bathroom <span>*</span>
                </label>
                <select
                  name="bathroom"
                  value={formData.bathroom}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option>1+</option>
                  <option>2+</option>
                  <option>3+</option>
                  <option>4+</option>
                  <option>5+</option>
                </select>
              </div>
            </div>
            <div className={Style.divide}>
              <div className={Style.box}>
                <label className={errors.propertyType ? Style.errorLabel : ""}>
                  Property Type <span>*</span>
                </label>
                <select
                  name="propertyType"
                  value={formData.propertyType}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option>Condos</option>
                  <option>Apartments</option>
                  <option>Single Family Homes</option>
                  <option>Multi-Family Homes</option>
                  <option>Farms & Ranches</option>
                  <option>Lands/Lots</option>
                  <option>Mobile & Manufactured Homes</option>
                  <option>Townhouses</option>
                  <option>Commercial Property</option>
                  <option>Cabin/Cottage</option>
                </select>
              </div>
              <div className={Style.box}>
                <label className={errors.budget ? Style.errorLabel : ""}>
                  Budget Range <span>*</span>
                </label>
                <input
                  type="number"
                  name="budget"
                  placeholder="Budget From"
                  value={formData.budget}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={Style.divide}>
              <div className={Style.box}>
                <label className={errors.location ? Style.errorLabel : ""}>
                  Location <span>*</span>
                </label>
                <input
                  type="text"
                  name="location"
                  placeholder="Location"
                  value={formData.location}
                  onChange={handleChange}
                />
              </div>
              <div className={Style.box}>
                <label className={errors.notification ? Style.errorLabel : ""}>
                  Notification From <span>*</span>
                </label>
                <div className={Style.radio}>
                  <div className={Style.radioDetail}>
                    <input
                      type="radio"
                      name="notification"
                      value="Daily"
                      checked={formData.notification === "Daily"}
                      onChange={handleChange}
                    />
                    <h2>Daily</h2>
                  </div>
                  <div className={Style.radioDetail}>
                    <input
                      type="radio"
                      name="notification"
                      value="Weekly"
                      checked={formData.notification === "Weekly"}
                      onChange={handleChange}
                    />
                    <h2>Weekly</h2>
                  </div>
                  <div className={Style.radioDetail}>
                    <input
                      type="radio"
                      name="notification"
                      value="Monthly"
                      checked={formData.notification === "Monthly"}
                      onChange={handleChange}
                    />
                    <h2>Monthly</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className={Style.bottom}>
              <div className={Style.button}>
                <button type="submit">Submit</button>
                <button type="button" onClick={handleNotificationForm}>
                  Cancel
                </button>
              </div>
              <p>
                By Sending, you acknowledge that you have read and agree with
                the <a href="/">Bipko.info</a>{" "}
                <a href="/terms">Terms of Service,</a>{" "}
                <a href="/prvicay-policy">Privacy Policy</a> and Cookie Policy
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NotificationForm;
