import React, { useEffect, useState } from "react";
import { fetchStates } from "../redux/Slice";
import Style from "../style/statecity.module.scss";
import { useAppDispatch } from "../redux/hooks";
import { getStatesFromDB, syncStatesWithDB } from "../indexDb/stateDb";

const StateCity = () => {
  const dispatch = useAppDispatch();
  const [localStates, setLocalStates] = useState<any[]>([]);

  useEffect(() => {
    const loadStates = async () => {
      const cachedData = await getStatesFromDB();
      if (cachedData.length > 0) {
        setLocalStates(cachedData);
      }
      dispatch(fetchStates())
        .unwrap()
        .then(async (response) => {
          if (response?.states) {
            if (
              JSON.stringify(response.states) !== JSON.stringify(cachedData)
            ) {
              await syncStatesWithDB(response.states);
            }
          }
        })
        .catch((error) => console.error("Error fetching states:", error));
    };

    loadStates();
    const interval = setInterval(loadStates, 60000);
    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <div className={Style.StateContainer}>
      <h1 className={Style.title}>Explore States & Cities</h1>
      <div className={Style.StateList}>
        {localStates.length > 0
          ? localStates.map((state) => (
              <div key={state.id} className={Style.StateBox}>
                <div className={Style.StateCard}>
                  <h2>{state.name}</h2>
                </div>
                <div className={Style.CityList}>
                  {state.cities?.map((city: any) => (
                    <a
                      href={`/listing/city/${city.name
                        .toLowerCase()
                        .replaceAll(" ", "-")}`}
                      key={city.id}
                      className={Style.city}
                    >
                      {city.name}
                    </a>
                  ))}
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default StateCity;
