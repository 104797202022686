import React from "react";
import Style from "../style/bussiness.module.scss";

const Bussiness = () => {
  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.divide}>
          <div className={Style.image}>
            <img
              src="https://www.shutterstock.com/image-photo/digital-marketing-commerce-online-sale-600nw-2269149669.jpg"
              alt="Digital"
              loading="lazy"
            />
          </div>
          <div className={Style.content}>
            <h2>
              Reach Local Customers and Boost Your Business's{" "}
              <span>
                Visibility!<i></i>
              </span>
            </h2>
            <p>
              Make it easier for customers to discover you by promoting your
              business here.
            </p>
            <a href="/contact">Learn More</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bussiness;
