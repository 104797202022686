import React from "react";
import Style from "../../style/realstate.module.scss";
import { ReactTyped } from "react-typed";
import Journey from "./Journey";
import Perform from "./Perform";
import Agent from "./Agent";
import Requirement from "./Requirement";
import Recent from "./Recent";
import Rating from "./Rating";
import Question from "../../component/Question";
import { useParams } from "react-router-dom";
import RealStateSearchBox from "./RealStateSearchBox";
import Feature from "./Feature";
import ProtoType from "./ProtoType";
import HomeServices from "./HomeServices";
import Img from "../../img/post.png";

const RealState = () => {
  const { userCategory } = useParams();
  console.log(userCategory, "hello");
  return (
    <div className={Style.container}>
      <div className={Style.banner}>
        <div className={Style.searchbox}>
          <div className={Style.text}>
            <h2>Discover Your Ideal Option</h2>
            <ReactTyped
              strings={[
                "Apartment",
                "House",
                "Land",
                "Commercial Space",
                "Condo",
              ]}
              typeSpeed={200}
              backSpeed={200}
              className={Style.animate}
              loop
            />
          </div>
          <RealStateSearchBox />
        </div>
      </div>
      <div className={Style.listingProperty}>
        <img
          src={Img}
          alt=""
          style={{
            width: "100%",
            height: "120px",
            objectFit: "cover",
            margin: "20px 0px",
            objectPosition: "center",
          }}
        />
      </div>
      <Journey />
      <Perform />
      <Agent />
      <Requirement />
      <Recent />
      <Feature />
      <ProtoType />
      <Rating />
      <HomeServices />
      <Question />
    </div>
  );
};

export default RealState;
