import React from "react";
import Style from "../../style/rating.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Navigation } from "swiper/modules";

const Rating = () => {
  const data = [
    {
      id: "1",
      profile: "M",
      name: "Prince Singh",
      detail: "LISTING PRO REALTY",
      text: "Absolutely proactive , reliable and knowledgeable in his line of profession. Mr Manoj Mathai keeps his clients up to date on the progress of the property you ar",
    },
    {
      id: "2",
      profile: "O",
      name: "Sahil Bohra",
      detail: "LISTING PRO REALTY",
      text: "Absolutely proactive , reliable and knowledgeable in his line of profession. Mr Manoj Mathai keeps his clients up to date on the progress of the property you ar",
    },
    {
      id: "3",
      profile: "T",
      name: "Satyam Mehra",
      detail: "LISTING PRO REALTY",
      text: "Absolutely proactive , reliable and knowledgeable in his line of profession. Mr Manoj Mathai keeps his clients up to date on the progress of the property you ar",
    },
    {
      id: "4",
      profile: "A",
      name: "Abhishek Jha",
      detail: "LISTING PRO REALTY",
      text: "Absolutely proactive , reliable and knowledgeable in his line of profession. Mr Manoj Mathai keeps his clients up to date on the progress of the property you ar",
    },
    {
      id: "5",
      profile: "M",
      name: "Prince Singh",
      detail: "LISTING PRO REALTY",
      text: "Absolutely proactive , reliable and knowledgeable in his line of profession. Mr Manoj Mathai keeps his clients up to date on the progress of the property you ar",
    },
    {
      id: "6",
      profile: "O",
      name: "Sahil Bohra",
      detail: "LISTING PRO REALTY",
      text: "Absolutely proactive , reliable and knowledgeable in his line of profession. Mr Manoj Mathai keeps his clients up to date on the progress of the property you ar",
    },
    {
      id: "7",
      profile: "T",
      name: "Satyam Mehra",
      detail: "LISTING PRO REALTY",
      text: "Absolutely proactive , reliable and knowledgeable in his line of profession. Mr Manoj Mathai keeps his clients up to date on the progress of the property you ar",
    },
    {
      id: "8",
      profile: "A",
      name: "Abhishek Jha",
      detail: "LISTING PRO REALTY",
      text: "Absolutely proactive , reliable and knowledgeable in his line of profession. Mr Manoj Mathai keeps his clients up to date on the progress of the property you ar",
    },
  ];
  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.title}>
          <h2>Rating & Reviews</h2>
        </div>
        <div className={Style.mainbox}>
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            loop={true}
            navigation
            modules={[Navigation]}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            breakpoints={{
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 4 },
            }}
          >
            {data &&
              data.map((item) => {
                return (
                  <SwiperSlide>
                    <div className={Style.content}>
                      <h2>
                        <span>{item.profile}</span>
                      </h2>
                      <h3>{item.name}</h3>
                      <div className={Style.star}>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                      </div>
                      <a href="#/">{item.detail}</a>
                      <p>{item.text}</p>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Rating;
