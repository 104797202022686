const defaultData = {
  id: 9999,
  status: 1,
  visibility: 1,
  title: "Default Auto Service Center",
  slug: "default-auto-service-center",
  amenities: '["5","7","11","12"]',
  services: "general auto repair, tire replacement, oil change",
  city_id: 0,
  state_id: 0,
  business_contact_number: "+1234567890",
  website: "https://defaultautocenter.com",
  email: "default@autocenter.com",
  phone: "+1234567890",
  facebook: null,
  twitter: null,
  linkedin: null,
  contact_person_name: "John Doe",
  telegram: null,
  payment_options: '["1"]',
  year_founded: "2000",
  products: [],
  service_radius: null,
  brands: null,
  employee_count: 10,
  associations: null,
  meta_keyword: "auto service, tire replacement, oil change",
  meta_description:
    "Default Auto Service Center offers reliable auto services for all types of vehicles.",
  zip: 12345,
  description: "Providing quality service since 2000.",
  address: "123 Main St, Default City, 12345",
  category_name: "Automotive",
  city_name: "Default City",
  category_icon: "fas fa-car",
  vendor: {
    id: 1,
    name: "Default Vendor",
    email: "vendor@default.com",
  },
  galleries: [
    {
      id: 1,
      image_url: "default_image.jpg",
    },
  ],
  faqs: [],
  specifications: [],
};

export default defaultData;
