import React, { useEffect, useState } from "react";
import { ReactTyped } from "react-typed";
import Style from "../style/imagebox.module.scss";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { fetchCategory, fetchStates } from "../redux/Slice";

const ImageBox = () => {
  const dispatch = useAppDispatch();
  const apiData = useAppSelector((state) => state.detail);

  useEffect(() => {
    dispatch(fetchCategory());
    dispatch(fetchStates());
  }, [dispatch]);

  const [data, setData] = useState({
    location: "",
    category: "",
  });

  const [selectedCityName, setSelectedCityName] = useState<string | null>(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState<
    string | null
  >(null);
  const [showCityDropdown, setShowCityDropdown] = useState(false);
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));

    if (name === "location") {
      setShowCityDropdown(value.trim() !== "");
    } else if (name === "category") {
      setShowCategoryDropdown(value.trim() !== "");
    }
  };

  const handleSelectCity = (cityName: string) => {
    setData((prev) => ({ ...prev, location: cityName }));
    setSelectedCityName(cityName);
    setShowCityDropdown(false);
  };

  const handleSelectCategory = (categoryName: string) => {
    setData((prev) => ({ ...prev, category: categoryName }));
    setSelectedCategoryName(categoryName);
    setShowCategoryDropdown(false);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const { location, category } = data;

    if (!location && !category) {
      alert("Please enter at least one input before searching.");
      return;
    }

    if (location && !category) {
      window.location.pathname = `/listing/city/${selectedCityName
        ?.toLowerCase()
        .replaceAll(" ", "-")}`;
    } else if (!location && category) {
      window.location.pathname = `/listing/category/${selectedCategoryName
        ?.toLowerCase()
        .replaceAll(" ", "-")}`;
    } else if (location && category) {
      window.location.pathname = `/listing/${selectedCategoryName
        ?.toLowerCase()
        .replaceAll(" ", "-")}/${selectedCityName
        ?.toLowerCase()
        .replaceAll(" ", "-")}`;
    }
  };

  const filteredCities: any = apiData.stateData?.states
    ?.flatMap((state) => state.cities)
    ?.filter((city) =>
      city.name.toLowerCase().includes(data.location.toLowerCase())
    );

  const filteredCategories: any = apiData.categoryData?.categories?.filter(
    (category) =>
      category.name.toLowerCase().includes(data.category.toLowerCase())
  );

  return (
    <div className={Style.Imagebox}>
      <div className={Style.wrapper}>
        <div className={Style.Image}>
          <div className={Style.data}>
            <span>
              <img
                src="https://plus.unsplash.com/premium_photo-1661512333448-c8ff01b82c93?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Banner"
                loading="lazy"
              />
            </span>
          </div>
          <div className={Style.text}>
            <div className={Style.content}>
              <h2>
                Find a{" "}
                <ReactTyped
                  strings={["Service", "Business", "Restaurant", "Shop"]}
                  typeSpeed={200}
                  backSpeed={200}
                  className={Style.animate}
                  loop
                />{" "}
                Near You
              </h2>
              <div className={Style.searchBoxContainer}>
                <form className={Style.searchBox} onSubmit={handleSubmit}>
                  <div className={Style.inputGroup}>
                    <i className="fa-solid fa-location-dot"></i>
                    <input
                      type="text"
                      placeholder="Enter Location"
                      name="location"
                      value={data.location}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    {showCityDropdown && filteredCities?.length > 0 && (
                      <div className={Style.dropdown}>
                        <ul>
                          {filteredCities.map((city: any) => (
                            <li
                              key={city.id}
                              onClick={() => handleSelectCity(city.name)}
                            >
                              {city.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className={Style.inputGroup}>
                    <i className="fa-solid fa-briefcase"></i>
                    <input
                      type="text"
                      placeholder="Business Category"
                      name="category"
                      value={data.category}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    {showCategoryDropdown && filteredCategories?.length > 0 && (
                      <div className={Style.dropdown}>
                        <ul>
                          {filteredCategories.map((category: any) => (
                            <li
                              key={category.id}
                              onClick={() =>
                                handleSelectCategory(category.name)
                              }
                            >
                              {category.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <button type="submit" className={Style.searchButton}>
                    <i className="fa-solid fa-magnifying-glass"></i> Search
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageBox;
