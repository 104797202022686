import React, { useEffect, useState } from "react";
import Style from "../../style/eventcity.module.scss";

const EventCity = () => {
  const [data, setData] = useState<any | []>([]);
  useEffect(() => {
    fetch("/data/event.json")
      .then((value) => {
        return value.json();
      })
      .then((res) => {
        setData(res.events);
      });
  }, []);
  const countByCategory = (city: string) =>
    data.filter((item: any) => item.venue.city === city).length;
  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.title}>
          <h2>Browse Events By City</h2>
        </div>
        <div className={Style.content}>
          <ul>
            {data.slice(0, 12).map((item: any) => {
              return (
                <li>
                  <a
                    href={`/event/${item.venue.city
                      .toLowerCase()
                      .replaceAll(" ", "-")}`}
                  >
                    <img src={item.cityImage} alt={item.venue.city} />
                    <span> {item.venue.city}</span>
                    <strong>{countByCategory(item.venue.city)} Events</strong>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EventCity;
