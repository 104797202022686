import React from "react";
import Style from "../../style/prototype.module.scss";

const ProtoType = () => {
  const data = [
    {
      id: 1,
      type: "Condos for Sale Near You",
    },
    {
      id: 2,
      type: "Single Family Homes for Sale Near You",
    },
    {
      id: 3,
      type: " Multi-Family Homes for Sale Near You",
    },
    {
      id: 4,
      type: "Townhouses for Sale Near You",
    },
    {
      id: 5,
      type: "Lands/Lots for Sale Near You",
    },
    {
      id: 6,
      type: "  Cabin/Cottage	for Sale Near You",
    },
    {
      id: 7,
      type: "Commercial Property	for Sale Near You",
    },
    {
      id: 8,
      type: "Apartments for Sale Near You",
    },
    {
      id: 9,
      type: "Farms &amp; Ranches	for Sale Near You",
    },
    {
      id: 10,
      type: "Mobile &amp; Manufactured Homes	for Sale Near You",
    },
  ];
  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.title}>
          <h2>Property Types for Sale Near You</h2>
        </div>

        <div className={Style.mainbox}>
          {data &&
            data.map((item) => {
              return (
                <div className={Style.content}>
                  <a href="#/">{item.type}</a>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ProtoType;
