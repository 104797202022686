import React from "react";
import Style from "../style/aboutdigital.module.scss";

const AboutDigital = () => {
  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.content}>
          <h2>Who We Are</h2>
          <p className={Style.paragraph}>
            We are committed to helping businesses succeed by providing a
            powerful local search platform that connects them with potential
            customers. Our goal is to ensure that businesses of all sizes can
            increase their online visibility, attract more leads, and grow
            effortlessly through our free and premium listing services.
          </p>
        </div>
        <div className={Style.Image}>
          <img
            src="https://jsppharma.in/images/Who-we-are.png"
            alt="IndiaMart"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutDigital;
