import React from "react";
import Style from "../../style/feature.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Navigation } from "swiper/modules";

const Feature = () => {
  const data = [
    {
      id: "1",
      type: "Single Family Home",
      img: "https://idx-acnt-ihouseprd.b-cdn.net/AR1155661/file_manager/Featured%20Communities/area%20shutterstock_120120670.jpg?width=500&aspect_ratio=100:101",
    },
    {
      id: "2",
      type: "Town Houses",
      img: "https://img.freepik.com/premium-photo/apartment-townhouse-residential-building-car-parked-street_250132-6309.jpg",
    },
    {
      id: "3",
      type: "Lands / Lots",
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSifGxZvZpBXdWBAboLhYMDvIzEbQB-2ah0nLcRfl-VruyV1gbN",
    },
    {
      id: "4",
      type: "commercial Property",
      img: "https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcT9dfv8XEDQY1fcLqdNHkVAezBS4y8NlcjsVDI4OaqWA0bnyzew",
    },
    {
      id: "5",
      type: "Mobile & Manufactured Homes",
      img: "https://a0.muscache.com/im/ml/photo_enhancement/pictures/83301bf5-34a4-4241-ae93-01ec2eded2d8.jpg?im_w=720",
    },
  ];
  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.title}>
          <h2>Featured Homes</h2>
        </div>
        <div className={Style.mainbox}>
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            loop={true}
            navigation
            modules={[Navigation]}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            breakpoints={{
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 4 },
            }}
          >
            {data &&
              data.map((item) => {
                return (
                  <SwiperSlide key={item.id}>
                    <div className={Style.content}>
                      <span>
                        <img src={item.img} alt={item.type} />
                      </span>
                      <div className={Style.text}>
                        <a href="#/">{item.type}</a>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Feature;
