import React, { useEffect, useRef, useState } from "react";
import Style from "../style/questionHome.module.scss";

const aiPersonalities = [
  {
    name: "Professor",
    tagline: `Change my mind.  
    I dare you.  
    Let's explore the unknown.  
    Challenge accepted.`,
    img: "https://sdmntpreastus2.oaiusercontent.com/files/00000000-5978-51f6-988f-02ca13583455/raw?se=2025-03-28T09%3A58%3A06Z&sp=r&sv=2024-08-04&sr=b&scid=3019756c-bf14-561b-b495-f283c27ee99c&skoid=ac1d63ad-0c69-4017-8785-7a50eb04382c&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2025-03-28T03%3A31%3A04Z&ske=2025-03-29T03%3A31%3A04Z&sks=b&skv=2024-08-04&sig=GnmlNDY4cfekDwYrlRT84IhTuvy1qj/HUr1ebhUwn4o%3D",
  },
  {
    name: "Steve",
    tagline: `I've poured enough drinks.  
    People don't always want advice.  
    They just want to talk.  
    I'm here to listen.`,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXmfJ-ZcZ_-vr25nhqQFOx3jpLIn5ns6Rm5vMgMDgDn3HJP2h1FVZnk3yThmUSvxtrbf4&usqp=CAU",
  },
  {
    name: "Taiga",
    tagline: `You're doing better.  
    Than you think!  
    Keep pushing forward.  
    Success is near.`,
    img: "https://img.freepik.com/premium-photo/young-man-ninja-anime-boy-full-body-with-china-monument-background_747552-3693.jpg",
  },
  {
    name: "Jordan",
    tagline: `Your ride-or-die bestie.  
    Through every high and low.  
    Always by your side.  
    Let's do this together.`,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmjyVcUAK5yUqDOSlGyBVoNMYsgH7lvhoakMjH9rqpymQ92vwquxxJ6nFoF344pNUngj4&usqp=CAU",
  },
  {
    name: "Blake",
    tagline: `Success isn't about winning.  
    It's about vision and patience.  
    Play the long game.  
    You'll get there.`,
    img: "https://imgservice.answers.com/bots/9/blake-older-brother.png?1933198428&filter=icon",
  },
  {
    name: "Fran",
    tagline: `No fun in playing it safe.  
    Dare to take risks.  
    Embrace the unknown.  
    Life's an adventure.`,
    img: "https://imgservice.answers.com/bots/8/fran-grandma.png?1933198428&filter=icon",
  },
  {
    name: "Vivi",
    tagline: `Simplicity is key.  
    Focus on what matters.  
    Eliminate distractions.  
    Make it beautiful.`,
    img: "https://imgservice.answers.com/bots/6/vivi-bff.png?1933198428&filter=icon",
  },
  {
    name: "Rafa",
    tagline: `Every question is an opportunity.  
    Dive into curiosity.  
    Learn, grow, and explore.  
    Keep asking, keep learning.`,
    img: "https://imgservice.answers.com/bots/17/dio-wildcard.png?1933198428&filter=icon",
  },
];

const QuestionHome = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    fetch("/data/questions.json")
      .then((res) => res.json())
      .then((value) => setData(value));
  }, []);

  const handleShare = (item: any) => {
    if (navigator.share) {
      navigator
        .share({
          title: item.question,
          text: `Check out this question: ${item.question}`,
          url: `${window.location.origin}/question/${item.category
            .toLowerCase()
            .replaceAll(" ", "-")}/${item.question
            .toLowerCase()
            .replaceAll(" ", "-")}`,
        })
        .then(() => console.log("Shared successfully"))
        .catch((error) => console.error("Error sharing:", error));
    } else {
      alert("Your browser does not support sharing.");
    }
  };

  const handleDrop = (index: any) => {
    setShow((prev) => (prev === index ? null : index));
  };

  const handlePrintPreview = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    window.print();
  };

  return (
    <div className={Style.container}>
      <div className={Style.wrapper}>
        <div className={Style.bannerContainer}>
          <div className={Style.bannerContent}>
            <h1>No Right Answers, Just the Right Discussion</h1>
            <p>Chat with AI to explore different perspectives.</p>
          </div>
          <div className={Style.personalitiesContainer}>
            <div className={Style.personalityCard}>
              <img
                src="https://imgservice.answers.com/bots/16/rene-devils-advocate.png?1933198428&filter=icon"
                alt="Rene"
                loading="lazy"
              />
              <div className={Style.personalityInfo}>
                <h3>Rene</h3>
                <p>Change my mind. I dare you.</p>
              </div>
            </div>
            <div className={Style.questionList}>
              <button className={Style.questionBtn}>
                What smells deter bears?
              </button>
              <button className={Style.questionBtn}>
                Why are there craters on the moon?
              </button>
            </div>
          </div>
        </div>
        <h2 className={Style.sectionTitle}>Chat with Our AI Personalities</h2>
        <div className={Style.aiContainer}>
          {aiPersonalities.map((ai, index) => (
            <div key={index} className={Style.aiCard}>
              <img src={ai.img} alt={ai.img} loading="lazy" />
              <h3>{ai.name}</h3>
              <p>{ai.tagline}</p>
              <button className={Style.chatButton}>Chat with {ai.name}</button>
            </div>
          ))}
        </div>
        <div className={Style.questionList}>
          <h2 className={Style.sectionTitle}>Topics to Explore</h2>
          <div className={Style.questionCard}>
            {Array.from(new Set(data.map((item: any) => item.category)))
              .slice(0, 7)
              .map((category, index) => (
                <a
                  href={`/question/category/${category
                    .toLowerCase()
                    .replaceAll(" ", "-")}`}
                >
                  {category}
                </a>
              ))}
          </div>
        </div>
        <div className={Style.OuestionData}>
          {data.slice(0, 20).map((item: any, index: any) => {
            return (
              <div className={Style.DataCard} key={item.id}>
                <div className={Style.cardContent}>
                  <div className={Style.leftSection}>
                    <div className={Style.cardCategory}>
                      <a
                        href={`/question/subject/${item.subject
                          .toLowerCase()
                          .replaceAll(" ", "-")}`}
                      >
                        {item.subject}
                      </a>
                    </div>
                    <div className={Style.question}>
                      <a
                        href={`/question/${item.category
                          .toLowerCase()
                          .replaceAll(" ", "-")}/${item.question
                          .toLowerCase()
                          .replaceAll(" ", "-")}`}
                      >
                        {item.question}
                      </a>
                    </div>
                    <div className={Style.asked}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="#0678B1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm0 4c1.38 0 2.5 1.12 2.5 2.5S13.38 11 12 11s-2.5-1.12-2.5-2.5S10.62 6 12 6zm0 12c-2.5 0-4.71-1.28-6-3.22 0-2 4-3.08 6-3.08s6 1.08 6 3.08c-1.29 1.94-3.5 3.22-6 3.22z" />
                      </svg>
                      <a href="#/">
                        Asked by <span>{item.posted_by}</span>
                      </a>
                    </div>
                    <div className={Style.answer}>
                      <p>{item.answers[0]?.answer || "No answer available"}</p>
                    </div>
                    <div className={Style.button}>
                      <button>
                        <img
                          src="https://www.answers.com/icons/createIcon.svg"
                          alt="Answer"
                        />
                        Answer
                      </button>
                    </div>
                  </div>
                  <div className={Style.rightSection}>
                    <div
                      className={Style.Icon}
                      onClick={() => handleDrop(index)}
                    >
                      <i className="fa-solid fa-ellipsis"></i>
                      <div
                        className={`${Style.drop} ${
                          show === index ? Style.show : ""
                        }`}
                        ref={dropdownRef}
                      >
                        <a href="/login">
                          <i className="fa-regular fa-flag"></i>
                          Report
                        </a>
                        <a
                          href="#/"
                          onClick={(e) => {
                            e.preventDefault();
                            handleShare(item);
                          }}
                        >
                          <i className="fa-regular fa-share-from-square"></i>
                          Share
                        </a>
                        <a href="#/" onClick={handlePrintPreview}>
                          <i className="fa-solid fa-print"></i>
                          Print
                        </a>
                      </div>
                    </div>
                    <div className={Style.stats}>
                      <span>
                        👍{" "}
                        {item.likes_dislikes?.filter((ld: any) => ld.liked_by)
                          .length || 0}{" "}
                        Likes
                      </span>
                      <span>
                        👎{" "}
                        {item.likes_dislikes?.filter(
                          (ld: any) => ld.disliked_by
                        ).length || 0}{" "}
                        Dislikes
                      </span>
                      <span>👁️ {item.views || 0} Views</span>
                    </div>

                    <div className={Style.timeAgo}>
                      ⏰{" "}
                      {item.posted_at
                        ? new Date(item.posted_at).toLocaleString()
                        : item.posted_time
                        ? new Date(item.posted_time).toLocaleString()
                        : "Just now"}
                    </div>
                    <div className={Style.bookmark}>
                      <button className={Style.bookmarkBtn}>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17 3H7C5.9 3 5 3.9 5 5V21L12 18L19 21V5C19 3.9 18.1 3 17 3ZM17 19L12 16.82L7 19V5H17V19Z"
                            fill="#0678B1"
                          />
                        </svg>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={Style.FullData}>
          <div className={Style.fullDataContent}>
            <div className={Style.LeftAside}>
              <a href="/question/subject">Subjects</a>
              {Array.from(new Set(data.map((item: any) => item.subject))).map(
                (subject, index) => (
                  <a href={`/${subject.toLowerCase().replaceAll(" ", "-")}`}>
                    {subject}
                  </a>
                )
              )}
            </div>
            <div className={Style.rightAside}>
              <h2>Trending Questions</h2>
              {data
                .sort((a: any, b: any) => b.votes - a.votes)
                .slice(0, 20)
                .map((question: any, index: number) => (
                  <div key={index} className={Style.trendingQuestion}>
                    <a
                      href={`/question/${question.category
                        .toLowerCase()
                        .replaceAll(" ", "-")}/${question.question
                        .toLowerCase()
                        .replaceAll(" ", "-")}`}
                    >
                      {question.question}
                    </a>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionHome;
