import React, { useEffect, useState } from "react";
import Style from "../style/productDivide.module.scss";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { fetchCategory } from "../redux/Slice";

const ProductDivide = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.detail);
  const [category, setCategory] = useState(false);
  const [show, setShow] = useState(false);
  useEffect(() => {
    dispatch(fetchCategory());
  }, [dispatch]);
  return (
    <div className={Style.divide}>
      <div className={Style.data}>
        <a href="/map">Map View</a>
        <a href="/all-product">All</a>
        <span onClick={() => setCategory(!category)}>
          Category{" "}
          <i
            className={`fa-solid fa-angle-down ${category ? Style.active : ""}`}
          ></i>{" "}
          <div
            className={`${Style.dropCategory} ${category ? Style.active : ""}`}
          >
            {data.categoryData?.categories &&
              data.categoryData.categories.map((item) => {
                return (
                  <a
                    href={`/listing/category/${item.name
                      .toLowerCase()
                      .replaceAll(" ", "-")}`}
                  >
                    {item.name}
                  </a>
                );
              })}
          </div>
        </span>
        <span>
          Sub Category <i className="fa-solid fa-angle-down "></i>
        </span>
      </div>
      <div className={Style.sort}>
        Sort:{" "}
        <a
          href="#/"
          onClick={(e: any) => {
            e.preventDefault();
            setShow(!show);
          }}
        >
          Default{" "}
          <i className={`fa-solid fa-angle-down ${show ? Style.show : ""}`}></i>
        </a>
        <div className={`${Style.drop} ${show ? Style.show : ""}`}>
          <a href="#/">Default</a>
          <a href="#/">Verified</a>
          <a href="#/">Responsible</a>
          <a href="#/">Popular</a>
          <a href="#/">Trending</a>
          <a href="#/">Rating</a>
          <a href="#/">Distance</a>
          <a href="#/">Open Now</a>
          <a href="#/">Name (A-Z)</a>
        </div>
      </div>
    </div>
  );
};

export default ProductDivide;
