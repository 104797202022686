import { createBrowserRouter } from "react-router-dom";
import HomePage from "./page/HomePage";
import AboutPage from "./page/AboutPage";
import Register from "./Form/Register";
import Login from "./Form/Login";
import ProductListing from "./ProductListing/ProductListing";
import DashRoute from "./Dashboard/DashRoute";
import Sitemap from "./component/SiteMap";
import FreeListing from "./component/FreeListing";
import Advertise from "./component/Advertise";
import Contact from "./component/Contact";
import Policy from "./component/Policy";
import ErrorPage from "./Errorpage/ErrorPage";
import MarketingSolutions from "./component/MarketingSolution";
import CategoryData from "./component/CategoryData";
import Blog from "./component/Blog";
import BlogLanding from "./component/BlogLanding";
import Article from "./component/Article";
import Question from "./component/Question";
import LatestListing from "./component/LatestListing";
import StateCity from "./component/StateCity";
import ProductLanding from "./ProductListing/ProductLanding";
import FAQ from "./component/Faq";
import QuestionHome from "./Question/QuestionHome";
import Subject from "./Question/Subject";
import MainLayout from "./Question/MainLayout";
import CategoryPage from "./Question/SubjectPage";
import CategoryLanding from "./Question/CategoryLanding";
import FilterProduct from "./ProductListing/FilterProduct";
import QuestionLanding from "./Question/QuestionLanding";
import UnAnswered from "./Question/UnAnswered";
import LeaderBoard from "./Question/LeaderBoard";
import RealState from "./CategoryPage/RealState/RealState";
import Relators from "./CategoryPage/RealState/Relators";
import RealStateFilter from "./CategoryPage/RealState/RealStateFilter";
import RealStateLanding from "./CategoryPage/RealState/RealStateLanding";
import RelatorLanding from "./CategoryPage/RealState/RelatorLanding";
import Event from "./CategoryPage/Event/Event";
import EventLanding from "./CategoryPage/Event/EventLanding";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <HomePage />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/about-us",
    element: (
      <>
        <AboutPage />
      </>
    ),
  },
  {
    path: "/register",
    element: (
      <>
        <Register />
      </>
    ),
  },
  {
    path: "/login",
    element: (
      <>
        <Login />
      </>
    ),
  },
  {
    path: "/all-product",
    element: <ProductListing />,
  },
  {
    path: "/listing",
    element: <ProductListing />,
  },
  {
    path: "/event",
    element: <Event />,
  },
  {
    path: "/eventlanding",
    element: <EventLanding />,
  },
  {
    path: "/listing/:userCategory",
    element: (
      <>
        <RealState />
      </>
    ),
  },
  {
    path: "/listing/category/:userCategory",
    element: <FilterProduct />,
  },
  {
    path: "/listing/city/:userCity",
    element: <FilterProduct />,
  },
  {
    path: "/listing/:userCategory/:userCity",
    element: <FilterProduct />,
  },
  {
    path: "/listing/page/:userTitle",
    element: [<ProductLanding />, <Article />, <Question />],
  },
  {
    path: "/dashboard",
    element: (
      <>
        <DashRoute />
      </>
    ),
  },
  {
    path: "/dashboard/:subpage",
    element: (
      <>
        <DashRoute />
      </>
    ),
  },

  {
    path: "/sitemap",
    element: (
      <>
        <Sitemap />
      </>
    ),
  },
  {
    path: "/free-listing",
    element: (
      <>
        <FreeListing />
      </>
    ),
  },
  {
    path: "/advertise",
    element: (
      <>
        <Advertise />
      </>
    ),
  },
  {
    path: "/contact",
    element: (
      <>
        <Contact />
      </>
    ),
  },
  {
    path: "/privacy-policy",
    element: (
      <>
        <Policy />
      </>
    ),
  },
  {
    path: "/marketing-solutions",
    element: (
      <>
        <MarketingSolutions />
      </>
    ),
  },
  {
    path: "/category",
    element: (
      <>
        [
        <CategoryData />,<LatestListing />,<Article />,<Question />]
      </>
    ),
  },
  {
    path: "/blog",
    element: (
      <>
        <Blog />
      </>
    ),
  },
  {
    path: "/blog/title/:userTitle",
    element: (
      <>
        <BlogLanding />
      </>
    ),
  },
  {
    path: "/blog/category/:category",
    element: (
      <>
        <Blog />
      </>
    ),
  },
  {
    path: "/all-cities",
    element: (
      <>
        [<StateCity />,<LatestListing />,<Article />,<Question />]
      </>
    ),
  },
  {
    path: "/faq",
    element: (
      <>
        <FAQ />
      </>
    ),
  },
  {
    path: "/question",
    element: (
      <>
        <QuestionHome />
      </>
    ),
  },
  {
    path: "/question/subject",
    element: (
      <>
        <MainLayout>
          <Subject />
        </MainLayout>
      </>
    ),
  },
  {
    path: "/question/subject/:subject",
    element: (
      <>
        <MainLayout>
          <CategoryPage />
        </MainLayout>
      </>
    ),
  },
  {
    path: "/question/category/:category",
    element: (
      <>
        <MainLayout>
          <CategoryLanding />
        </MainLayout>
      </>
    ),
  },
  {
    path: "/question/:category/:question",
    element: (
      <>
        <MainLayout>
          <QuestionLanding />
        </MainLayout>
      </>
    ),
  },
  {
    path: "/question/unanswered",
    element: (
      <>
        <MainLayout>
          <UnAnswered />
        </MainLayout>
      </>
    ),
  },
  {
    path: "/leaderboard",
    element: (
      <>
        <MainLayout>
          <LeaderBoard />
        </MainLayout>
      </>
    ),
  },

  {
    path: "/realstatefilter",
    element: (
      <>
        <RealStateFilter />
      </>
    ),
  },
  {
    path: "/relator",
    element: (
      <>
        <Relators />
      </>
    ),
  },
  {
    path: "/realstatelanding",
    element: (
      <>
        <RealStateLanding />
      </>
    ),
  },
  {
    path: "/relatorlanding",
    element: (
      <>
        <RelatorLanding />
      </>
    ),
  },
]);

export default router;
