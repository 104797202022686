import React, { useState } from "react";
import Style from "../../style/realstatefilterdata.module.scss";
import RelatorContactForm from "./Form/RelatorContactForm";

export interface IType {
  itemList: any;
}

const RealStateFilterData: React.FC<IType> = ({ itemList }) => {
  const [showForm, setShowForm] = useState(false);
  const handleAgentForm = () => {
    setShowForm(true);
  };
  const handleRelatorForm = () => {
    setShowForm(false);
  };
  return (
    <div className={Style.container}>
      <>
        {showForm && (
          <RelatorContactForm
            itemList={itemList}
            handleRelatorForm={handleRelatorForm}
            showExtraField={true}
          />
        )}
      </>
      <div className={Style.detail}>
        <span>
          <img src={itemList.img} alt="" />
          <h2>For Sale</h2>
        </span>
        <div className={Style.Content}>
          <div className={Style.city}>
            <h2>{itemList.name}</h2>
          </div>
          <div className={Style.location}>
            <h2>
              <i className="fa-solid fa-location-dot"></i>
              <a href="#/">{itemList.city + ","}</a>
              {itemList.pin}
            </h2>
          </div>
          <div className={Style.data}>
            <ul>
              <li>{itemList.price}</li>
              <li>{itemList.type}</li>
              <li>{itemList.area}</li>
              <li>{itemList.rate}</li>
            </ul>
          </div>
          <div className={Style.fullDetail}>
            <ul>
              <li>
                Bedrooms<span>{itemList.room}</span>
              </li>
              <li>
                Baths<span>{itemList.bath}</span>
              </li>
              <li>
                SaleType<span>{itemList.saleType}</span>
              </li>
              <li>
                Year Built<span>{itemList.built}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={Style.text}>
        <p>{itemList.text}</p>
      </div>
      {itemList.amenities.length > 0 && (
        <div className={Style.amenties}>
          <ul>
            {itemList.amenities.map((item: any) => {
              return (
                <li>
                  <i className={item.icon}></i>
                  <span>{item.name}</span>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      <div className={Style.agentDetail}>
        <div className={Style.AgentData}>
          <span>
            <img src={itemList.agentImg} alt="" />
          </span>
          <div className={Style.AgentName}>
            <h2>
              {itemList.agentName}
              <strong>(agent)</strong>
            </h2>
            <h3>{itemList.properties} properties</h3>
            {itemList.phone ? (
              <h4>
                <a href="#/">
                  <i className="fa-solid fa-phone"></i>
                  {itemList.phone}
                </a>
                (<strong>{itemList.pin}</strong>)
              </h4>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={Style.contactButton}>
          <button onClick={handleAgentForm}>Contact Agent</button>
        </div>
      </div>
    </div>
  );
};

export default RealStateFilterData;
