import React from "react";
import Style from "../style/leaderboard.module.scss";

const LeaderBoard = () => {
  return (
    <div className={Style.container}>
      <div className={Style.tab}>
        <ul>
          <li>This Week</li>
          <li>All Time</li>
          <li>My Stats</li>
        </ul>
      </div>
      <div className={Style.titleSection}>
        <div className={Style.title}>
          <h2>Answer questions to earn points!</h2>
          <p>Register now to start answering</p>
        </div>
        <div className={Style.register}>
          <a href="/register">
            <img
              src="https://math.answers.com/icons/coinIcon.svg"
              alt="Coin"
              loading="lazy"
            />
            <h2>Sign Up</h2>
          </a>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoard;
